import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Redeemer } from '../../_model/redeemer';
import { Coupon } from '../../_model/coupon';
import { AlertifyService } from '../../_services/alertify.service';
import { CouponService } from '../../_services/coupon.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {

  constructor(private couponServices: CouponService,
    private alert: AlertifyService,
    private fb: FormBuilder) { }


  coupons: Coupon[] = [];
  redeemers: Redeemer[] = [];
  newCoupon = this.fb.group({
    code: [undefined, [Validators.required]],
    notUsableBeforeTS: [undefined],
    expirationTS: [undefined, [Validators.required]],
    discountPercentage: [undefined, [Validators.required]]
  });
  showLoadingMsg = false;
  showEmptyRedeemers = false;


  ngOnInit() {
    this.couponServices.getCoupons().subscribe(
      result => {
        this.coupons = result;
      },
      error => {
        console.error(error);
        this.alert.error(error);
      }
    )
  }

  public updateSingleCoupon(data: any) {
    const couponToUpdate = data.data as Coupon;
    this.couponServices.updateCoupon(couponToUpdate).subscribe(
      result => this.alert.success('Coupon updated'),
      error => {
        console.error(error);
        this.alert.error(error);
      }
    )
  }

  public createCoupon() {
    this.couponServices.createNewCoupon(this.newCoupon.value as Coupon).subscribe(
      _ => {
        this.alert.success('Coupon created');
      },
      error => {
        console.error(error);
        this.alert.error(error);
      }
    )
  }

  public getRedeemers(data: any) {
    this.showLoadingMsg = true;
    this.showEmptyRedeemers = false;
    this.redeemers = [];
    const couponId = (data.data as Coupon).id;
    this.couponServices.getRedeemerByCouponId(couponId).subscribe(
      result => {
        this.redeemers = result;
        console.log(result);
        this.showLoadingMsg = false;
        if (this.redeemers.length == 0) this.showEmptyRedeemers = true;
      },
      error => {
        this.alert.error(error);
        console.error(error);
        this.showLoadingMsg = false;
      }
    )
  }

}
