import { Component, OnInit } from '@angular/core';
import { BookingService } from './../../../_services/booking.service';
import { Router } from '@angular/router';
import { PublishStatusEnum } from '../../../../../../Model/_models/general.interface';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.css']
})

export class ClientsListComponent implements OnInit {
  clients: any = [];
  constructor(
    private bookingService: BookingService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.fetchOrganizationList();
  }

  fetchOrganizationList() {
    this.bookingService.getOrganizationList().subscribe((res) => {
      this.clients = res;
    });
  }

  editBusinessClient(e) {
    localStorage.setItem('client_data', JSON.stringify(e.data));
    this.router.navigate([`clients/edit/${e.data.id}`]);
  }

  navigateToClientAddingList() {
    localStorage.removeItem('client_data');
    this.router.navigate(['clients/add']);
  }

  getStatusName(rowData) {
    return PublishStatusEnum[rowData.status];
  }
}
