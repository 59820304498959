import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityTime, PricingDetails, Venue, VenueGroup } from '../../../../Model/_models/Venue';
import { PaginatedResult } from '../../../../Model/_models/Pagination';
import { ImageService } from './image.service';
import { FileItem } from 'ng2-file-upload';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { saveAs } from 'file-saver';
import {
  map
} from 'rxjs/operators';
import { Review } from '../../../../Model/_models/review.model';
import { Photo } from '../../../../Model/_models/Photo';

@Injectable()
export class VenueService {
  baseUrl = environment.apiUrl;

  constructor(
    private authHttp: HttpClient,
    private imageService: ImageService,
    private http: HttpClient
  ) { }



  public uploadKosherCertificate(venueId: number, file: FileItem): Observable<Photo> {
    const url = `${environment.apiUrl}venues/UploadKosherCertificateForVenue/${venueId}`;
    const x: Observable<any> = Observable.create((observer: any) => {
      file.url = url;
      file.withCredentials = false;
      file.upload();
      file.onSuccess = (response) => {
        observer.next(JSON.parse(response));
        observer.complete();
      };
      file.onError = (response) => {
        observer.error(response);
      };
    });

    return x;
  }

  public uploadInsuranceForVenue(venueId: number, file: FileItem): Observable<Photo> {
    console.log(file);
    const url = `${environment.apiUrl}venues/uploadInsuranceForVenue/${venueId}`;
    const x: Observable<any> = Observable.create((observer: any) => {
      file.url = url;
      file.withCredentials = false;
      file.upload();
      file.onSuccess = (response) => {
        observer.next(JSON.parse(response));
        observer.complete();
      };
      file.onError = (response) => {
        observer.error(response);
      };
    });

    return x;
  }

  GoogleGeoApiLocationByLang() {
  }

  public uploadMeunFile(fileToUpload: File, packageID) {
    const _formData = new FormData();
    _formData.append('file', fileToUpload, fileToUpload.name);
    return this.authHttp.post(this.baseUrl + `pricing/pricepackage/${packageID}/menu`, _formData);
  }

  public removeFile(packageID) {
    return this.authHttp.delete(this.baseUrl + `pricing/pricepackage/${packageID}/menu`);
  }

  downloadFile(menu_url, filename) {
    const options = new RequestOptions({ responseType: ResponseContentType.Blob });
    // this.http.get(menu_url, options).subscribe(res => {
    this.http.get(menu_url, { responseType: 'blob' }).subscribe(res => {
      this.saveFile(res, filename);
    });
  }

  public uploadPricePackagePhoto(fileToUpload: File, packageID) {
    const _formData = new FormData();
    _formData.append('file', fileToUpload, fileToUpload.name);
    return this.authHttp.post(this.baseUrl + `pricing/pricepackage/${packageID}/mainPhoto`, _formData);
  }

  public removePricePackagePhoto(packageID) {
    return this.authHttp.delete(this.baseUrl + `pricing/pricepackage/${packageID}/mainPhoto`);
  }

  saveFile(blobContent: Blob, fileName: string) {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  public updateReview(review: Review): Observable<Review> {
    return this.authHttp.post<Review>(this.baseUrl + 'venues/UpdateReview', review);
  }

  getVenues(page?, itemsPerPage?, venueParams?: any) {
    const paginatedResult: PaginatedResult<Venue[]> = new PaginatedResult<Venue[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    console.log('venueParams', venueParams);

    if (venueParams != null) {
      if (venueParams.minAge) params = params.append('minAge', venueParams.minAge);
      if (venueParams.maxAge) params = params.append('maxAge', venueParams.maxAge);
      if (venueParams.gender) params = params.append('gender', venueParams.gender);
      if (venueParams.orderBy) params = params.append('orderBy', venueParams.orderBy);
      if (venueParams.name) params = params.append('venuename', venueParams.name);
      if (venueParams.id) params = params.append('id', venueParams.id);
      if (venueParams.status) params = params.append('status', venueParams.status);
      if (venueParams.locale) params = params.append('locale', venueParams.locale);
      if (venueParams.lat) params = params.append('lat', venueParams.lat);
      if (venueParams.lon) params = params.append('lon', venueParams.lon);
      if (venueParams.distanceLimit) params = params.append('distanceLimit', venueParams.distanceLimit);
    }

    console.log(venueParams);

    return this.authHttp
      .get<Venue[]>(this.baseUrl + 'venues/search', { observe: 'response', params }).pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }

          return paginatedResult;
        }));
  }

  getVenueGroups(): Observable<VenueGroup[]> {
    return this.authHttp.get<VenueGroup[]>(this.baseUrl + 'venues/GetVenueGroups');
  }

  getVenue(id): Observable<Venue> {
    return this.authHttp.get<Venue>(this.baseUrl + 'venues/admin/' + id);
  }

  uploadVenueImage(id: number, file: FileItem): Observable<any> {
    return this.imageService.uploadFileItemImage(this.baseUrl + 'venues/' + id + '/photos', file);
  }

  removeVenueImage(venueId: number, photoId: number): Observable<any> {
    return this.authHttp.delete<Venue>(this.baseUrl + 'venues/' + venueId + '/photos/' + photoId);
  }

  publishVenue(venueId: number): Observable<any> {
    return this.authHttp.post<Venue>(this.baseUrl + 'venues/' + venueId + '/publish', {});
  }

  unpublishVenue(venueId: number): Observable<any> {
    return this.authHttp.post<Venue>(this.baseUrl + 'venues/' + venueId + '/unpublish', {});
  }

  setMainPhoto(venueId: number, imageId: number) {
    return this.authHttp.post(
      this.baseUrl + 'venues/' + venueId + '/photos/' + imageId + '/setMain',
      {}
    );
  }

  setActivityTimes(venueId: number, data: ActivityTime[]): Observable<any> {
    return this.authHttp.post(this.baseUrl + 'venues/' + venueId + '/activitytimes', data);
  }

  updateVenue(venueId: number, data: Venue): Observable<Venue> {
    console.log('updateVenue', data);
    return this.authHttp.put<Venue>(this.baseUrl + 'venues/' + venueId, data);
  }

  createVenue(data: Venue): Observable<Venue> {
    return this.authHttp.post<Venue>(this.baseUrl + 'venues', data);
  }

  updatePricing(venueId: number, data: PricingDetails) {
    return this.authHttp.post<Venue>(this.baseUrl + 'pricing/' + venueId, data);
  }

  updatePricingBySlot(id, body) {
    return this.authHttp.post(this.baseUrl + `pricing/${id}/timeslotpricing`, body);
  }

  deleteVenue(venueId: number) {
    return this.authHttp.delete<Venue>(this.baseUrl + 'venues/' + venueId);
  }

  archiveVenue(venueId: number) {
    return this.authHttp.post<Venue>(this.baseUrl + 'venues/' + venueId + '/archive', {});
  }

  /*
    updateUser(id: number, user: User) {
      return this.authHttp.put(this.baseUrl + 'users/' + id, user);
    }updateVenue

    setMainPhoto(userId: number, id: number) {
      return this.authHttp.post(
        this.baseUrl + 'users/' + userId + '/photos/' + id + '/setMain',
        {}
      );
    }

    deletePhoto(userId: number, id: number) {
      return this.authHttp.delete(
        this.baseUrl + 'users/' + userId + '/photos/' + id
      );
    }
    */
  getCountryRegions(countryCode: string): Observable<any[]> {
    countryCode = countryCode.toLowerCase();
    return this.authHttp.get<any[]>(this.baseUrl + 'regions/country/' + countryCode);
  }

  addPricePackageForVenue(id, body) {
    return this.authHttp.post(this.baseUrl + `pricing/${id}/pricepackage`, body);
  }

  addTiers(id, body) {
    return this.authHttp.post(this.baseUrl + `pricing/pricepackage/${id}/tiers`, body);
  }

  publishPackage(id) {
    return this.authHttp.post(this.baseUrl + `pricing/pricepackage/${id}/publish`, {});
  }

  unPublishPackage(id) {
    return this.authHttp.post(this.baseUrl + `pricing/pricepackage/${id}/unpublish`, {});
  }
}
