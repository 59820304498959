import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingService } from '../../../_services/booking.service';
import { BookingStatusEnum, IRefreshmentBooking } from '../../../../../../Model/_models/Booking';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import * as moment from 'moment';
import { CurrencyEnum } from '../../../../../../Model/models/Booking';
import { startCase } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-refreshment-bookings-list',
  templateUrl: './refreshment-bookings-list.component.html',
  styleUrls: ['./refreshment-bookings-list.component.css']
})
export class RefreshmentBookingsListComponent implements OnInit {

  constructor(private bookingServices: BookingService,
    private router: Router,
    private route: ActivatedRoute) {
    this.getBookingPriceString = this.getBookingPriceString.bind(this);
  }

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  bookings: IRefreshmentBooking[] = [];

  ngOnInit() {
    this.bookingServices.getRefreshmentBookings().subscribe({
      next: result => {
        console.log(result);
        this.bookings = result.result;
      },
      error: error => {
        console.error(error);
      }
    })
  }


  onGridInit() {
    this.loadState();
  }

  onContentReady(e: any) {
    this.route.queryParams.subscribe((params) => {
      if (params.name) {
        console.log('name filter: ' + params.name);
        this.filterByBookingUserName(params.name);
      }
    });
  }

  private filterByBookingUserName(name: string) {
    if (this.dataGrid.dataSource) {
      this.dataGrid.instance.filter([
        ['firstName', 'contains', name]
      ]);
    }
  }

  loadState() {
    const state = JSON.parse(localStorage.getItem('bookingGridState'));
    if (state)
      this.dataGrid.instance.state(state);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: this.resetState.bind(this)
      }
    });
  }

  resetState() {
    const state = {
      'searchText': '',
      'pageIndex': 0,
      'filterValue': null
    };
    localStorage.removeItem('bookingGridState');
    this.dataGrid.instance.state(state);
  }

  prepareContextMenu(e) {
    if (e.row.rowType === 'data') {
      e.items = [{
        text: 'View',
        onItemClick: function () {
          this.alertify.success('view booking clicked');
        }.bind(this)
      }];
      if (e.row.data.status === BookingStatusEnum.PendingSupplierApproval ||
        e.row.data.status === BookingStatusEnum.SupplierConfirmed) {
        let menuText = 'Confirm Booking';
        if (e.row.data.status === BookingStatusEnum.SupplierConfirmed) {
          menuText = 'Resend Confirmation Email';
        }
        e.items.push({
          text: menuText,
          onItemClick: function () {
            const result =
              confirm(`Are you sure you want to CONFIRM booking id ${e.row.data.id} by ${this.getBookingUserDetails(e.row.data)}?`,
                'Confirm Booking');
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.confirmBooking(e.row.data);
              }
            });
          }.bind(this)
        });
      }
      if (e.row.data.status === BookingStatusEnum.PendingPayment ||
        e.row.data.status === BookingStatusEnum.UserCancelled ||
        e.row.data.status === BookingStatusEnum.AdminCancelled) {
        const menuText = 'Archive Booking';
        e.items.push({
          text: menuText,
          onItemClick: function () {
            const result =
              confirm(`Are you sure you want to ARCHIVE booking id ${e.row.data.id} by ${this.getBookingUserDetails(e.row.data)}?`,
                'Archive Booking');
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.archiveBooking(e.row.data);
              }
            });
          }.bind(this)
        });
      }
    }
  }

  private saveState() {
    const state = this.dataGrid.instance.state();
    // Saves the state in the local storage
    localStorage.setItem('bookingGridState', JSON.stringify(state));
  }

  navigateToEditBooking(bookId: number) {
    this.saveState();
    this.router.navigate([`/refreshment-bookings/edit/${bookId}`]);
  }

  getBookingUserDetails(rowData: any): string {
    return `${rowData.bookingUser.firstName} ${rowData.bookingUser.lastName ? rowData.bookingUser.lastName : ''}`;
  }

  getBookingAge(rowData: any): string {
    return moment.utc(rowData.createdAt).local().fromNow(true);
  }

  getBookingDatesString(rowData: IRefreshmentBooking): string {
    let result = '';
    rowData.orderedInventoryItems.forEach(a => {
      result += moment(a.deliveryDateTime).format('DD/MM/YY, hh:MM') + '<br/>';
    });
    return result;
  }

  getBookingPriceString(rowData: any): string {
    return `${this.formatterFunc(rowData.finalPrice)} ${this.currencyEnumToName(rowData.currency)}`;
  }

  getBookingStatusEnumName(rowData) {
    return startCase(BookingStatusEnum[rowData.status]);
  }

  getBookingLocation(rowData: IRefreshmentBooking): string {
    const floor = rowData.floor ? rowData.floor : '-';
    const entrance = rowData.entranceNumber ? rowData.entranceNumber : '-';
    return `${rowData.address} <br/> floor: ${floor} <br/> entrance: ${entrance}`;
  }

  getBookingOrgDetails(rowData: any): string {
    if (rowData.relatedOrgBooking)
      return rowData.relatedOrgBooking.relatedOrgUser.relatedOrg.name;
    else if (rowData.companyName)
      return rowData.companyName;
    else
      return '-';
  }

  getBookingPOCode(rowData: any): string {
    if (rowData.relatedOrgBooking) {
      return rowData.relatedOrgBooking.poCode ? rowData.relatedOrgBooking.poCode : '-';
    } else {
      return '-';
    }
  }

  formatterFunc(value: any) {

    if (value !== null)
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return 'no price';
  }

  currencyEnumToName(currency: number): string {
    return CurrencyEnum[currency];
  }

}
