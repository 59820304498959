import { ViewChild, Component, OnInit, ElementRef } from '@angular/core';
import { AlertifyService } from '../../_services/alertify.service';
import { TreeModel, NodeSelectedEvent, NodeRemovedEvent,
    NodeCreatedEvent, Ng2TreeSettings } from 'ng2-tree';
import { RegionService } from './../../_services/region.service';
import { CountryRegion } from '../../../../../Model/_models/Region';

@Component({
    selector: 'app-region',
    templateUrl: './region.component.html',
    styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {

    public tree: TreeModel;
    public treeSettings: Ng2TreeSettings = {
        rootIsVisible: true,
    };
    @ViewChild('treeComponent', {static: false}) treeComponent;
    selectedNode: TreeModel = null;
    model: any = {
        country: 'IL',
        searchVal: ''
    };
    tempArray: any[] = [];

    constructor(
        private regionService: RegionService,
        private alertify: AlertifyService) { }

    ngOnInit() {
        this.loadCountryRegions();
    }

    handleSelected(event: NodeSelectedEvent) {
        this.selectedNode = null;

        if (event.node.id !== 'GB' && event.node.id !== 'IL') {
            this.selectedNode = event.node.toTreeModel();
            const treeNode = this.getTreeNodeInstance(event.node.id);
            if (treeNode != null && treeNode.isCollapsed()) {
                treeNode.expand();
            }
        } else {
            const rootNode = this.treeComponent.getController();
            rootNode.expand();
        }
    }

    handleCreated(event: NodeCreatedEvent) {
        const node = event.node.toTreeModel();
        let parentRegionId: number | string = null;

        if (node.value) {
            if (event.node.parent) {
                const parentTree = event.node.parent.toTreeModel();
                if (parentTree.id && parentTree.id !== 'GB' && parentTree.id !== 'IL'
                    && parentTree.id !== 'NL' && parentTree.id !== 'BE' && parentTree.id !== 'DE' 
                    && parentTree.id !== 'US' && parentTree.id !== 'CN') {
                    parentRegionId = parentTree.id;
                }
            }

            this.regionService.createNewRegion({
                countryCode: this.model.country,
                regionName: node.value.toString(),
                parentRegionId: parentRegionId
            }).subscribe(response => {
                const treeNode = this.getTreeNodeInstance(node.id);
                treeNode.changeNodeId(response.id);
                this.selectedNode = null;
            }, err => {
                this.alertify.error(err);
            });
        }
    }

    handleRemoved(event: NodeRemovedEvent) {
        const node = event.node.toTreeModel();
        if (node.id && typeof node.id === 'number') {
            this.regionService.deleteRegion(node.id).subscribe(() => {
                this.selectedNode = null;
            }, err => {
                this.alertify.error(err);
            });
        }
    }

    loadCountryRegions() {
        this.regionService.getCountryRegions(this.model.country).subscribe(response => {
            this.tree = {
                value: this.model.country,
                id: this.model.country,
                settings: {
                    static: true
                }
            };

            this.prepareTreeData(response, this.tree);
        }, err => {
            this.alertify.error(err);
        });
    }

    prepareTreeData(regions: CountryRegion[], parentNode: TreeModel) {
        const children = [];
        for (const child of regions) {

            let include = false;
            if (this.model.searchVal.length > 0) {
                include = child.regionName.toLowerCase().indexOf(this.model.searchVal.toLowerCase()) > -1;
            } else {
                include = true;
            }

            if (include === true) {
                children.push({ id: child.id, value: child.regionName });
                this.prepareTreeData(child.childRegions, children[children.length - 1]);
            }
        }

        parentNode.children = children;
    }

    onCountryChange() {
        this.loadCountryRegions();
    }

    performSearch() {
        this.loadCountryRegions();
    }

    onCollapseClick() {
        this.performCollapsed(this.tree);

        for (const parentNode of this.tempArray) {
            let instance = null;
            if (parentNode !== 'GB' && parentNode !== 'IL' && parentNode !== 'NL'
                && parentNode.id !== 'BE' && parentNode.id !== 'DE'
                && parentNode.id !== 'US' && parentNode.id !== 'CN') {
                instance = this.getTreeNodeInstance(parentNode);
            } else {
                instance = this.treeComponent.getController();
            }

            if (instance != null) {
                instance.collapse();
            }
        }
    }

    onExpandClick() {
        this.performExpand(this.tree);
    }

    onCreateClick() {

        const newNode: TreeModel = {
            id: null,
            value: '',
            children: []
        };

        if (this.selectedNode) {
            const treeNode = this.getTreeNodeInstance(this.selectedNode.id);
            if (treeNode != null) {
                treeNode.addChild(newNode);
                return;
            }
        }

        this.treeComponent.tree.createNode(true, newNode);
    }

    onDeleteClick() {
        if (this.selectedNode == null) {
            this.alertify.error('Select region to delete');
            return;
        }

        const treeNode = this.getTreeNodeInstance(this.selectedNode.id);
        if (treeNode) {
            treeNode.remove();
        }
    }

    onVenuesClick() {
        alert('not implemented yet');
    }

    onProvidersClick() {
        alert('not implemented yet');
    }

    performExpand(treeNode: TreeModel) {
        if (treeNode.id) {
            let instance = null;
            if (treeNode.id !== 'GB' && treeNode.id !== 'IL' && treeNode.id !== 'NL'
                && treeNode.id !== 'BE' && treeNode.id !== 'DE'
                && treeNode.id !== 'US' && treeNode.id !== 'CN') {
                instance = this.getTreeNodeInstance(treeNode.id);
            } else {
                instance = this.treeComponent.getController();
            }

            if (instance != null) {
                instance.expand();
            }

            if (treeNode.children !== undefined && treeNode.children.length > 0) {
                for (const child of treeNode.children) {
                    setTimeout(() => {
                        this.performExpand(child);
                    }, 50);
                }
            }
        }
    }

    performCollapsed(treeNode: TreeModel) {
        if (treeNode.id) {
            if (treeNode.children !== undefined && treeNode.children.length > 0) {
                this.tempArray.push(treeNode.id);
                for (const child of treeNode.children) {
                    this.performCollapsed(child);
                }
            }
        }
    }

    getTreeNodeInstance(id: string | number) {
        return this.treeComponent.getControllerByNodeId(id);
    }

    preventDraggable() {
        const dragElements = document.querySelectorAll('div[draggable]');
        for (let i = 0; i < dragElements.length; i++) {
            dragElements[i].addEventListener('dragstart', function (e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
        }
    }
}
