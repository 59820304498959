import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { ServiceProviderService } from '../../../_services/service-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceProviderType } from '../../../../../../Model/_models/ServiceProvider';
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-service-providers-list',
  templateUrl: './service-providers-list.component.html',
  styleUrls: ['./service-providers-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceProvidersListComponent implements OnInit {

  serviceProviders: any[];
  displayedColumns = ['id', 'name', 'statusValue', 'venueName', 'actions'];
  dataSource: any;
  currentPage = 1;
  pageLimit = 1;
  totalItems: number;
  serviceProviderParams: any = {
    name: '',
    status: '',
  };
  order = 'id';
  loading = false;

  // sorting
  key: String = 'id'; // set default
  reverse: Boolean = true;
  sortedCollection: any[] = [];

  constructor(
    private serviceProviderService: ServiceProviderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderPipe: OrderPipe
  ) {

  }

  ngOnInit() {
    this.fetchServiceProvider();
  }

  /**
   *  Get service provider list
   */
  fetchServiceProvider() {
    this.loading = true;
    this.serviceProviderService.getServiceProviders(this.currentPage, this.pageLimit, this.serviceProviderParams).subscribe((res) => {
      res.result.map((service) => {
        service.statusValue = (service.status === 1) ? 'Draft' : 'Published';
        service.typeValue = (service.relatedVenueId === null || service.relatedVenueId === '') ? 'Regular Provider' : 'Self Provider';
        // Convert related Venues array into string
        if (service.relatedVenues !== null) {
          if (service.relatedVenues.length > 0) {
            // tslint:disable-next-line:max-line-length
            service.venueName = (service.relatedVenues[0].id + ' | ' + service.relatedVenues[0].realName + ' | ' + service.relatedVenues[0].name).substring(0, 60);
            if (service.relatedVenues.length > 1) {
              service.venueName += ` and ${ (service.relatedVenues.length - 1) } more`;
            }
          }
        } else {
          service.venueName = '';
        }
        return service;
      });
      this.sortedCollection = this.orderPipe.transform(res.result);
      this.loading = false;
    },
      (err) => {
        this.loading = false;
      });
  }

  editServiceProvider(row) {
    this.router.navigate([`service-providers/edit/${row.id}`]);
  }

  /**
   *  Search Service Provider based on user input
   */
  searchTerms() {
    this.currentPage = 1;
    this.fetchServiceProvider();
  }

  /**
   *  Sort table
   * @param value
   */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

}


