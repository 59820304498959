
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Venue } from '../../../../Model/_models/Venue';
import { Injectable } from '@angular/core';
import { VenueService } from '../_services/venue.service';
import { AlertifyService } from '../_services/alertify.service';



@Injectable()
export class VenueListResolver implements Resolve<Venue[]> {
  constructor(
    private venueService: VenueService,
    private router: Router,
    private alertify: AlertifyService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Venue[]> {
    return this.venueService.getVenues().pipe(catchError(error => {
      this.alertify.error('Unexpected error');
      this.router.navigate(['/home']);
      return observableOf(null);
    }));
  }
}
