import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Errorhelper } from '../../../_helpers/error-helper';
// tslint:disable-next-line:max-line-length
import {
  ServiceProvider, ServiceProviderStatusEnum,
  CountryCodeEnum, ServiceTypeEnum, ServiceType,
  ServiceSubType, ServiceTypeSubEnum, PricingTypeEnum,
  PricingType, InventoryFlagList, InventoryStatusEnum, ServiceInventoryFlags, ServiceInventoryPackageTypeList
} from '../../../../../../Model/_models/ServiceProvider';
import { ServiceProviderService } from '../../../_services/service-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify.service';
import { CurrencyEnum, PublishStatusEnum } from '../../../../../../Model/_models/general.interface';
import { FileUploader } from 'ng2-file-upload';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-inventory-edit',
  templateUrl: './inventory-edit.component.html',
  styleUrls: ['./inventory-edit.component.css']
})
export class InventoryEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  CountryCodeEnum = CountryCodeEnum;
  serviceProvider: any = {};
  venueList = [];
  serviceProviderService: any;
  selectedVenue: any;
  serviceProviderId: any;
  isAdd = true;
  logoUrl = '';
  serviceProviderStatusEnum = ServiceProviderStatusEnum;
  selectedVenues = [];
  CurrencyEnum = CurrencyEnum;
  inventory: any = {};
  serviceType = ServiceType;
  serviceTypeEnum = ServiceTypeEnum;
  serviceSubType = ServiceSubType;
  serviceTypeSubEnum = ServiceTypeSubEnum;
  pricingTypeEnum = PricingTypeEnum;
  pricingType = PricingType;
  inventoryFlagList = InventoryFlagList;
  inventoryStatusEnum = InventoryStatusEnum;
  inventoryPackageTypeList = ServiceInventoryPackageTypeList;
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  @Input() inventoryObject: any;
  @Input() country: any;
  @Output() backToServiceProviderEvent = new EventEmitter<string>();
  public uploader: FileUploader = new FileUploader({
    authTokenHeader: 'Authorization',
    authToken: 'Bearer ' + this.jwtHelper.tokenGetter(),
  });



  constructor(serviceProviderService: ServiceProviderService,
    private activeRoute: ActivatedRoute,
    private alertify: AlertifyService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private errorhelper: Errorhelper) {
    this.serviceProviderService = serviceProviderService;
    const routeParams = this.activeRoute.snapshot.params;
    // Check if routes has params. if route has id means it for update
    if (routeParams.id !== undefined) {
      this.serviceProviderId = routeParams.id;
      // this.inventory.serviceProviderId = this.serviceProviderId;
      this.isAdd = false;
    } else {
      this.serviceProvider.countryCode = CountryCodeEnum.IL;
    }

  }

  ngOnInit() {
    if (Object.keys(this.inventoryObject).length > 0) {
      this.inventory = this.inventoryObject;
      this.isAdd = false;
    } else {
      this.inventory = {};
      this.inventory.flags = [];
      this.inventory.serviceProviderId = this.serviceProviderId;
      this.isAdd = true;
    }
    if (this.isAdd) {
      if (this.country === CountryCodeEnum.GB) {
        this.inventory.currency = this.CurrencyEnum.GBP;
      } else if (this.country === CountryCodeEnum.IL) {
        this.inventory.currency = this.CurrencyEnum.ILS;
      }
    }
    if (!this.isAdd) {
      this.getServiceProviderByIdEdit(this.serviceProviderId, this.inventory.id);
    }
    console.log(this.inventory);
    console.log(this.inventory.inventoryCateringPrice);
  }

  updateInventoryOptions(event) {
    if (this.inventory.flags === undefined) {
      this.inventory.flags = [];
    }
    if (event.target.checked) {
      if (this.inventory.flags.indexOf(Number(event.target.value)) < 0) {
        this.inventory.flags.push(Number(event.target.value));
        this.addInventoryCateringItem(event.target.value);
      }
    } else {
      if (this.inventory.flags.indexOf(Number(event.target.value)) > -1) {
        this.inventory.flags.splice(this.inventory.flags.indexOf(Number(event.target.value)), 1);
        this.removeInventoryCateringItem(event.target.value);
      }
    }
  }

  public getInventoryFlagTitle(id: number) {
    return this.inventoryFlagList.find(x => x.id == id).Name;
  }

  /**
   * Save Inventory form
   */
  onSubmitForm() {
    this.blockUI.start('');
    console.log(this.inventory);
    this.serviceProviderService.setInventoryToServiceProvider(this.inventory).subscribe((res) => {
      this.blockUI.stop();
      if (this.isAdd) {
        // this.getServiceProviderByIdEdit(this.serviceProviderId, this.inventory.id);
        this.getServiceProviderByIdAdd(this.serviceProviderId);
        this.alertify.success('Successfully added');
      } else {
        this.getServiceProviderByIdEdit(this.serviceProviderId, this.inventory.id);
        this.alertify.success('Successfully updated');
      }

    },
      (error) => {
        this.blockUI.stop();
        // this.alertify.error('Error while adding entity');
        this.errorhelper.showServerErrors(error);
      });
  }

  /**
   * Publish inventory
   */
  publishInventory() {
    this.serviceProviderService.publishInventory(this.serviceProviderId, this.inventory.id).subscribe(() => {
      this.inventory.status = this.inventoryStatusEnum.Published;
      this.alertify.success('Published successfully');

    }, (err: Error | any) => {
      this.errorhelper.showServerErrors(err);
    });
  }

  /**
   * Unpublish inventory
   */
  unpublishInventory() {
    this.serviceProviderService.unpublishInventory(this.serviceProviderId, this.inventory.id).subscribe(() => {
      this.inventory.status = this.inventoryStatusEnum.Draft;
      this.alertify.success('Unpublished successfully');

    }, (err: Error | any) => {
      this.errorhelper.showServerErrors(err);
    });
  }

  /**
   * Delete inventory
   */
  deleteInventory() {
    this.backToServiceProviderEvent.emit(this.inventory);
    this.serviceProviderService.deleteInventory(this.serviceProviderId, this.inventory).subscribe(() => {
      this.alertify.success('Deleted successfully');
      this.backToServiceProviderEvent.emit(this.inventory);

    }, (err: Error | any) => {
      this.errorhelper.showServerErrors(err);
    });
  }

  /**
  * Upload logo when user select
  * @param file
  */
  onFileSelected(file) {
    console.log('file upload');
  }

  onServiceTypeChange(event) {
    this.inventory.serviceSubType = '';
  }

  /**
  * HTTP call to get service provider by id
  * @param id
  */
  getServiceProviderByIdEdit(id, inventoryId) {
    this.serviceProviderService.getInventory(id, inventoryId).subscribe((res) => {
      this.inventory = res;
    });
  }

  getServiceProviderByIdAdd(id) {
    this.serviceProviderService.getServiceProvider(id).subscribe((response) => {
      console.log(typeof response.inventory);
      this.inventory = response.inventory[(response.inventory.length - 1)];
      console.log(this.inventory);
      this.getServiceProviderByIdEdit(this.serviceProviderId, this.inventory.id);
      this.isAdd = false;
      this.blockUI.stop();
    }, (error) => {
      this.blockUI.stop();
    });
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  private addInventoryCateringItem(flag: ServiceInventoryFlags) {
    if (flag == ServiceInventoryFlags.Kosher) return;
    if (!this.inventory.inventoryCateringPrice) this.inventory.inventoryCateringPrice = [];
    if (this.inventory.inventoryCateringPrice.findIndex(x => x.flags == flag) < 0) {
      // add new catering item
      const cateringItem: ICateringPrice = {
        serviceProviderId: this.serviceProviderId,
        pricingType: PricingTypeEnum.PerPerson,
        price: undefined,
        currency: this.inventory.currency,
        status: this.inventory.status,
        flags: flag
      };
      this.inventory.inventoryCateringPrice.push(cateringItem);
    }
    console.log(this.inventory.inventoryCateringPrice);
  }

  private removeInventoryCateringItem(flag: ServiceInventoryFlags) {
    if (!this.inventory.inventoryCateringPrice) return;
    const idx = this.inventory.inventoryCateringPrice.findIndex(x => x.flags == flag);
    if (idx > -1)
      this.inventory.inventoryCateringPrice.splice(idx, 1);
  }
}



interface ICateringPrice {
  serviceProviderId: number,
  pricingType: PricingTypeEnum,
  price?: number,
  currency: CurrencyEnum,
  status: PublishStatusEnum,
  minOrder?: number,
  flags: ServiceInventoryFlags
}
