import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LanguagesEnum } from '../../../../../../Model/_models/general.interface';
import { environment } from '../../../../environments/environment';
import {
  AmenityItemEnum,
  Venue,
  VenueCategoryEnum,
  VenueCateringOptions,
  VenueSeatingOptionEnum,
  PriceCalculatorTypes,
  EVENT_TYPE
} from '../../../../../../Model/_models/Venue';
import { VenueEditForm } from './venue-edit.interface';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class VenueEditService {

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
  ) { }

  getSeatingDetails() {
    return this.fb.group({
      seatingOption: [''],
      capacity: [''],
      checkbox: Boolean
    });
  }


  get venueFormGroup() {
    return {
      details: this.fb.group({
        extraServicesText: '',
        roomSizeSqaureMeters: '',
        comments: '',
        adminNotes: '',
        status: '',
        cateringDetails: [''],
        cateringOptions: [VenueCateringOptions.None],
        name: ['', Validators.required],
        realName: [''],
        popularityIndex: [''],
        about: [''],
        email: [''],
        contactPersonName: [''],
        phoneNumber: [''],
        emailForCustomers: [''],
        phoneNumberForcustomers: [''],
        contactNameForCustomers: [''],
        locale: [LanguagesEnum.EN_UK],
        categories: this.fb.array(this.venueCategoriesList.map(i => false)),
        events: this.fb.array(this.venueEventsList.map(i => false)),
        ameneties: this.fb.array(this.amenetiesList.map(i => false)),
        seatingOptionsList: this.fb.array(this.seatingOptions.map(i => false)),
        seatingDetails: this.fb.array([]),
        venueGroup: undefined,
        newVenueGroup: undefined,
        externalTermsUrl: undefined
      }),
      address: this.fb.group({
        city: [''],
        cityEN: [''],
        lat: [''],
        lon: [''],
        fulladdress: [''],
        street: [''],
        streetEN: [''],
        streetNumber: [''],
        country: [''],
        countryFullNameEN: [''],
        countryFullName: [''],
        directions: [''],
        directionsToDisplay: [''],
        countryRegionId: [''],
        countryRegionName: [''],
        navigationLink: [''],
        zip: [''],
        neighborhoodEN: [''],
        neighborhood: [''],
      }),
      pricing: this.fb.group({
        id: null,
        currency: [''],
        refundPolicyOption: '',
        refundPolicyOptionText: '',
        termsAndConditions: '',
        pricePerHour_WithoutVAT: [''],
        minBookingHours: [''],
        extraDayPrice: [''],
        halfDayPrice: [''],
        fullDayPrice: [''],
        priceCalculatorType: [PriceCalculatorTypes.ChargeExtraHours]
      }),
      activityTimes: this.fb.array([])
    };
  }
  prepareVenueEditDTO(data: VenueEditForm, detailsFormArray: any): Venue {
    console.log('prepareVenueEditDTO: ', data);
    const seatingOptionSelectedList = detailsFormArray.map(({ seatingOption }) => seatingOption);
    console.log('seatingOptionSelectedList :', seatingOptionSelectedList);
    const venueData: Venue = {
      extraServicesText: data.details.extraServicesText,
      roomSizeSqaureMeters: data.details.roomSizeSqaureMeters,
      comments: data.details.comments,
      adminNotes: data.details.adminNotes,
      status: data.details.status,
      cateringDetails: data.details.cateringDetails,
      cateringOptions: data.details.cateringOptions,
      amenitiesList: this.convertToValue(data.details.ameneties, 'amenetiesList'),
      categoriesList: this.convertToValue(data.details.categories, 'venueCategoriesList'),
      eventsList: this.convertToValue(data.details.events, 'venueEventsList'),
      // seatingOptionsList: this.convertToValue(data.details.seatingOptionsList, 'seatingOptions'),
      seatingOptionsList: seatingOptionSelectedList,
      seatingDetails: detailsFormArray,
      name: data.details.name,
      email: data.details.email,
      about: data.details.about,
      phoneNumber: data.details.phoneNumber,
      contactPersonName: data.details.contactPersonName,
      locale: data.details.locale,
      fullAddress: data.address.fulladdress,
      directions: data.address.directions,
      directionsToDisplay: data.address.directionsToDisplay,
      navigationLink: data.address.navigationLink,
      lat: data.address.lat,
      lon: data.address.lon,
      country: data.address.country,
      countryFullName: data.address.countryFullName,
      countryFullNameEN: data.address.countryFullNameEN,
      zip: data.address.zip,
      street: data.address.street,
      streetEN: data.address.streetEN,
      streetNumber: data.address.streetNumber,
      city: data.address.city,
      cityEN: data.address.cityEN,
      neighborhoodEN: data.address.neighborhoodEN,
      neighborhood: data.address.neighborhood,
      countryRegionId: data.address.countryRegionId,
      realName: data.details.realName,
      popularityIndex: data.details.popularityIndex,
      contactNameForCustomers: data.details.contactNameForCustomers,
      contactPhoneForCustomers: data.details.phoneNumberForcustomers,
      contactEmailForCustomers: data.details.emailForCustomers,
      reviews: data.reviews,
      ratingAvg: 0,
      locationRatingAvg: 0,
      meetingPurposeRatingAvg: 0,
      serviceRatingAvg: 0,
      venueAvgRating: data.venueAvgRating,
      venueGroup: data.details.venueGroup == null ? null : {id: data.details.venueGroup, groupName: undefined},
      newVenueGroup: data.details.newVenueGroup,
      externalTermsUrl: data.details.externalTermsUrl
    };

    return venueData;
  }

  convertToValue(data, store: 'venueCategoriesList' | 'amenetiesList' | 'seatingOptions' | 'venueEventsList') {
    return data.map((x, i) => x && this[store][i].id).filter(x => {
      return x !== false;
    });
  }

  get amenetiesList() {
    return [
      {
        id: AmenityItemEnum.Accessibility, // 1
        name: 'Accessibility'
      },
      {
        id: AmenityItemEnum.AirConditioning, // 2
        name: 'Air Cond. / Heating'
      },
      {
        id: AmenityItemEnum.MeetingTable, // 4
        name: 'Meeting Table'
      },
      {
        id: AmenityItemEnum.Chairs, // 8
        name: 'Chairs'
      },
      {
        id: AmenityItemEnum.HotDrinks, // 16
        name: 'Hot Drinks'
      },
      {
        id: AmenityItemEnum.ColdWater, // 32
        name: 'Cold Water'
      },
      {
        id: AmenityItemEnum.Cookies, // 64
        name: 'Cookies'
      },
      {
        id: AmenityItemEnum.FlipChart, // 4194304
        name: 'Flip Chart'
      },
      {
        id: AmenityItemEnum.Kitchen, // 128
        name: 'Kitchen'
      },
      {
        id: AmenityItemEnum.OutdoorSeatings, // 256
        name: 'Outdoor Seats'
      },
      {
        id: AmenityItemEnum.FreeParking, // 512
        name: 'Free Parking'
      },
      {
        id: AmenityItemEnum.PaidParking, // 2097152
        name: 'Paid Parking'
      },
      {
        id: AmenityItemEnum.Projector, // 1024
        name: 'Projector'
      },
      {
        id: AmenityItemEnum.Screen, // 2048
        name: 'Screen'
      },
      {
        id: AmenityItemEnum.Shade, // 4096
        name: 'Shade'
      },
      {
        id: AmenityItemEnum.SmokingArea, // 8192
        name: 'Smoking Area'
      },
      {
        id: AmenityItemEnum.AVEquipments, // 16384
        name: 'AV Equipments'
      },
      {
        id: AmenityItemEnum.Toilet, // 32768
        name: 'Toilet'
      },
      {
        id: AmenityItemEnum.TV, // 65536
        name: 'TV'
      },
      {
        id: AmenityItemEnum.VCSystem, // 131072
        name: 'VC System'
      },
      {
        id: AmenityItemEnum.WhiteBoard, // 262144
        name: 'White Board'
      },
      {
        id: AmenityItemEnum.Wifi, // 524288
        name: 'Wifi'
      },
      {
        id: AmenityItemEnum.Windows, // 1048576
        name: 'Windows'
      },
      {
        id: AmenityItemEnum.Daylight, // 8388608
        name: 'Daylight'
      },
      {
        id: AmenityItemEnum.ClickShare, // 16777216
        name: 'Clickshare'
      },
      {
        id: AmenityItemEnum.NoDaylight, // 33554432
        name: 'No Daylight'
      }
    ];
  }

  get seatingOptions() {
    return [{
      id: VenueSeatingOptionEnum.BoardRoom,
      name: 'Board Meeting',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.Theatre,
      name: 'Theater',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.Circle,
      name: 'Circle',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.UShaped,
      name: 'U-Shaped',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.Classroom,
      name: 'Classroom',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.SofaSeating,
      name: 'Sofa Seating',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.Cabaret,
      name: 'Cabaret',
      capacity: null
    }, {
      id: VenueSeatingOptionEnum.Standing,
      name: 'Standing',
      capacity: null
    }];
  }

  get venueEventsList() {
    return [
      {
        id: EVENT_TYPE.Offsite,
        name: 'Offsite'
      },
      {
        id: EVENT_TYPE.BoardMeeting,
        name: 'Board Meeting'
      },
      {
        id: EVENT_TYPE.Conference,
        name: 'Conference'
      },
      {
        id: EVENT_TYPE.TrainingDay,
        name: 'Training Day'
      },
      {
        id: EVENT_TYPE.Workshop,
        name: 'Workshop'
      },
      {
        id: EVENT_TYPE.Meal,
        name: 'Meal'
      },
      {
        id: EVENT_TYPE.TeamOffsite,
        name: 'Team Offsite'
      },
      {
        id: EVENT_TYPE.Toast,
        name: 'Toast'
      },
      {
        id: EVENT_TYPE.CompanyEvent,
        name: 'Company Event'
      },
      {
        id: EVENT_TYPE.Birthday,
        name: 'Birthday'
      },
      {
        id: EVENT_TYPE.FamilyCelebration,
        name: 'Family Celebration'
      },
      {
        id: EVENT_TYPE.PrivateEvent,
        name: 'Private Event'
      },
      {
        id: EVENT_TYPE.DeskPerDay,
        name: 'Desk Per Day'
      },
      {
        id: EVENT_TYPE.FunDays,
        name: 'Fun days'
      },
    ];
  }

  get venueCategoriesList() {
    return [
      {
        id: VenueCategoryEnum.FormalBoardroom,
        name: 'Formal Boardroom'
      },
      {
        id: VenueCategoryEnum.ModernMeetingRoom,
        name: 'Modern Meeting Room'
      },
      {
        id: VenueCategoryEnum.CreativeSpace,
        name: 'Creative Space'
      },
      {
        id: VenueCategoryEnum.HotelMeetingRoom,
        name: 'Hotel Meeting Room'
      },
      {
        id: VenueCategoryEnum.RestaurantPrivateRoom,
        name: 'Restaurant Private Room'
      },
      {
        id: VenueCategoryEnum.LoftGallerySalon,
        name: 'Loft / Gallery / Salon'
      },
      {
        id: VenueCategoryEnum.EventSpace,
        name: 'Event Space'
      },
      {
        id: VenueCategoryEnum.Classroom,
        name: 'Classroom'
      },
      {
        id: VenueCategoryEnum.HighEnd,
        name: 'High End'
      },
      {
        id: VenueCategoryEnum.MeetingSpaceOvernight,
        name: 'Meeting Space+Overnight'
      }
    ];
  }


}
