import { User } from './User';
import { Venue } from './Venue';

export class Review {
    id: number;
    bookingId: number;
    userId: number;
    venueId: number;
    reviewedBookingTimeSlotId: number;
    reviewDate: Date;
    serviceRating: number;
    locationRating: number;
    meetingPurposeRating: number;
    questionnaire: Questionnaire[];
    comments: string;
    reviewStatus: ReviewStatus;
    reviewUsername: string;

    // virtaul
    ReviewingUser: User;
    ReviewedVenue: Venue;
    ReviewedBooking: any;

    // on client only
    rtl: boolean;
}

export enum ReviewStatus {
    New,
    Approved,
    Declined
}

export class Questionnaire {
    question: string;
    ratingCategory: RATING_CATEGORY;
    answers: Answer[];
    rating: number;
  }
export class Answer {
    answer: string;
    isSelected: boolean;
  }
export enum RATING_CATEGORY {
    MEETING_PURPSE,
    SERVICE,
    LOCATION,
    NO_CATEGORY
  }