export interface POCode {
    id: number;
    RelatedOrgId: number;
    relatedBookingId: number;
    POCode: string;
    Budget: number;
    status: PoStatus;
    isUsableForBooking: boolean;
}

export enum PoStatus {
    New = 1,
    Used = 2,
    Cancelled = 3,
}
