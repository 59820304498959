import { Routes } from '@angular/router';
import { VenuesListComponent } from './_components/venues/venues-list/venues-list.component';
import { ClientsListComponent } from './_components/business-clients/clients-list/clients-list.component';
import { ClientsEditComponent } from './_components/business-clients/clients-edit/clients-edit.component';
import { BookingsListComponent } from './_components/bookings-list/bookings-list.component';
import {UsersListComponent} from './_components/users/users-list/users-list.component';
import { HomeComponent } from './_components/home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { VenueDetailComponent } from './_components/venues/venue-detail/venue-detail.component';
import { VenueEditComponent } from './_components/venues/venue-edit/venue-edit.component';
import { RegionComponent } from './_components/region/region.component';
import { ServiceProvidersListComponent } from './_components/service-providers/service-providers-listing/service-providers-list.component';
import { ServiceProviderEditComponent } from './_components/service-providers/service-provider-edit/service-provider-edit.component';
import { EditUserComponent } from './_components/users/edit-user/edit-user.component';
import { EditBookingsComponent } from './_components/bookings-list/edit-bookings/edit-bookings.component';
import { SeoComponent } from './_components/seo/seo.component';
import { CreateSeoComponent } from './_components/seo/createSeo/createSeo.component';
import { TaxConfigComponent } from './_components/tax-config/tax-config.component';
import { CouponsComponent } from './_components/coupons/coupons.component';
import { RefreshmentBookingsListComponent } from './_components/refreshment-booking/refreshment-bookings-list/refreshment-bookings-list.component';
import { RefreshmentBookingsEditComponent } from './_components/refreshment-booking/refreshment-bookings-edit/refreshment-bookings-edit.component';
import { PaymentPagesComponent } from './_components/payment-pages/payment-pages.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'venues', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'venues',
        component: VenuesListComponent
      },
      { path: 'venues/edit', component: VenueEditComponent },
      { path: 'venues/edit/:id', component: VenueEditComponent },
      { path: 'clients', component: ClientsListComponent },
      { path: 'clients/add', component: ClientsEditComponent },
      { path: 'clients/edit/:id', component: ClientsEditComponent },
      // add router link using full name , from edit user component
      { path: 'bookings', component: BookingsListComponent },
      { path: 'bookings/:fullname', component: BookingsListComponent },
      { path: 'bookings/edit/:id', component: EditBookingsComponent },
      { path: 'regions', component: RegionComponent },
      { path: 'service-providers', component: ServiceProvidersListComponent },
      { path: 'service-providers/edit', component: ServiceProviderEditComponent },
      { path: 'service-providers/edit/:id', component: ServiceProviderEditComponent },
      { path: 'users', component: UsersListComponent },
      { path: 'seo', component: SeoComponent },
      { path: 'seo/manageseo', component: CreateSeoComponent},
      { path: 'seo/manageseo/:id', component: CreateSeoComponent},
      { path: 'users/edit/:id', component: EditUserComponent },
      { path: 'tax', component: TaxConfigComponent},
      { path: 'coupons', component: CouponsComponent},
      { path: 'refreshment-bookings', component: RefreshmentBookingsListComponent },
      { path: 'refreshment-bookings/edit/:id', component: RefreshmentBookingsEditComponent },
      { path: 'payment-page', component: PaymentPagesComponent},
    ]
  },
  { path: '**', redirectTo: 'venues', pathMatch: 'full' }
];
