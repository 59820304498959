
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceProvider } from '../../../../Model/_models/ServiceProvider';
import { PaginatedResult } from '../../../../Model/_models/Pagination';
import { ImageService } from './image.service';
import { FileItem } from 'ng2-file-upload';
import { Observable } from 'rxjs/Observable';
import { Photo } from '../../../../Model/_models/Photo';

@Injectable()
export class ServiceProviderService {
  baseUrl = environment.apiUrl;

  constructor(
    private authHttp: HttpClient,
    private imageService: ImageService
  ) { }

  getServiceProviders(page?, itemsPerPage?, serviceParams?: any) {
    const paginatedResult: PaginatedResult<any[]> = new PaginatedResult<any[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (serviceParams != null) {
      if (serviceParams.name) params = params.append('name', serviceParams.name);
      if (serviceParams.status) params = params.append('status', serviceParams.status);
    }

    return this.authHttp
      .get<ServiceProvider[]>(this.baseUrl + 'ServiceProviders', { observe: 'response', params }).pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(
            response.headers.get('Pagination')
          );
        }

        return paginatedResult;
      }));
  }

  /**
   * Call Http to get searched venues
   * @param searchTerm
   */
  searchVenue(searchTerm, countryCode) {
    return this.authHttp.get<any[]>(this.baseUrl + `venues/search?searchterm=${searchTerm}&countrycode=${countryCode}`);
  }

  /**
   * Save Service provider
   * @param serviceProvider
   */
  saveServiceProvider(serviceProvider) {
    return this.authHttp.post<any[]>(this.baseUrl + 'ServiceProviders', serviceProvider);
  }

  /**
   * Get Service provider by Id
   * @param serviceProvider
   */
  getServiceProvider(id) {
    return this.authHttp.get<any[]>(this.baseUrl + `ServiceProviders/${id}`);
  }

  unpublishServiceProvider(id) {
    return this.authHttp.post<any[]>(this.baseUrl + `ServiceProviders/${id}/unpublish`, {});
  }

  publishServiceProvider(id) {
    return this.authHttp.post<any[]>(this.baseUrl + `ServiceProviders/${id}/publish`, {});
  }

  /**
  * Update Service provider
  * @param serviceProvider
  */
  updateServiceProvider(serviceProvider) {
    return this.authHttp.put<any[]>(this.baseUrl + `ServiceProviders/${serviceProvider.id}`, serviceProvider);
  }

  uploadServiceProviderImage(id: number, file: FileItem): Observable<any> {
    return this.imageService.uploadFileItemImage(this.baseUrl + 'ServiceProviders/' + id + '/logo', file);
  }


  setVenueToServiceProvider(venues): Observable<any> {
    return this.authHttp.post(this.baseUrl + `ServiceProviders/${venues.ServiceProviderId}/venues`, venues);
  }


  getCountryRegion(countryCode) {
    return this.authHttp.get(this.baseUrl + `regions/country/${countryCode}`);
  }

  setRegionToServiceProvider(regions, serviceProviderId): Observable<any> {
    return this.authHttp.post(this.baseUrl + `ServiceProviders/${serviceProviderId}/regions`, regions);
  }

  setInventoryToServiceProvider(inventory) {
    return this.authHttp.post(this.baseUrl + `ServiceProviders/${inventory.serviceProviderId}/inventory`, inventory);
  }

  unpublishInventory(serviceProviderId, inventoryId) {
    return this.authHttp.post<any[]>(this.baseUrl + `ServiceProviders/${serviceProviderId}/inventory/${inventoryId}/unpublish`, {});
  }

  publishInventory(serviceProviderId, inventoryId) {
    return this.authHttp.post<any[]>(this.baseUrl + `ServiceProviders/${serviceProviderId}/inventory/${inventoryId}/publish`, {});
  }

  deleteInventory(serviceProviderId, inventory) {
    // tslint:disable-next-line:max-line-length
    return this.authHttp.delete<any[]>(this.baseUrl + `ServiceProviders/${serviceProviderId}/inventory/${inventory.id}`);
  }

  getInventory(serviceProviderId, inventory) {
    // tslint:disable-next-line:max-line-length
    return this.authHttp.get<any[]>(this.baseUrl + `ServiceProviders/${serviceProviderId}/inventory/${inventory}`);
  }

  public uploadKosherCertificate(serviceProviderId: number, file: FileItem): Observable<Photo> {
    console.log(file);
    const url = `${this.baseUrl}ServiceProviders/UploadKosherCertificate/${serviceProviderId}`;
    const x: Observable<any> = Observable.create((observer: any) => {
      file.url = url;
      file.withCredentials = false;
      file.upload();
      file.onSuccess = (response) => {
        observer.next(JSON.parse(response));
        observer.complete();
      };
      file.onError = (response) => {
        observer.error(response);
      };
    });

    return x;
  }

  public uploadPhoto(serviceProviderId: number, file: FileItem): Observable<Photo> {
    console.log(file);
    const url = `${this.baseUrl}ServiceProviders/UploadSPPhoto/${serviceProviderId}`;
    const x: Observable<any> = Observable.create((observer: any) => {
      file.url = url;
      file.withCredentials = false;
      file.upload();
      file.onSuccess = (response) => {
        observer.next(JSON.parse(response));
        observer.complete();
      };
      file.onError = (response) => {
        observer.error(response);
      };
    });

    return x;
  }

  public removeInventoryItemImages(serviceProviderId: number, imagesToRemove: any[]) {
    const url = `${this.baseUrl}ServiceProviders/DeleteMediaAsset/${serviceProviderId}`;
    return this.authHttp.post(url, imagesToRemove);
  }

}
