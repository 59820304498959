export enum CurrencyEnum {
    ILS = 0,
    USD = 1,
    EUR = 3,
    GBP = 2,
}

export const CurrencyList = [
    { id: CurrencyEnum.ILS, currency: 'ILS' },
    { id: CurrencyEnum.USD, currency: 'USD' },
    { id: CurrencyEnum.EUR, currency: 'EUR' },
    { id: CurrencyEnum.GBP, currency: 'GBP' },
]

export function GetCurrencyById(id: CurrencyEnum) {
    const item = CurrencyList.find(x => x.id == id);
    if (item) return item.currency;
    else return CurrencyList[0].currency;
}

export enum BookingStatus {
    PendingPayment = 0,
    PendingSupplierApproval = 1,
    VenueConfirmed = 2,
    Finalized = 3,
    PaymentFailed = 4,
    UserCancelled = 5,
    AdminCancelled = 6,
    VenueCancelled = 7,
}
