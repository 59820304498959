import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertifyService } from '../../_services/alertify.service';
import { CurrencyList, CurrencyEnum } from '../../../../../Model/models/Booking';
import { Booking } from '../../../../../Model/_models/Booking';
import { BookingService } from '../../_services/booking.service';

@Component({
  selector: 'app-payment-pages',
  templateUrl: './payment-pages.component.html',
  styleUrls: ['./payment-pages.component.css']
})
export class PaymentPagesComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private bookingService: BookingService,
    private alertify: AlertifyService,) { }

  paymentPageForm = this.fb.group({
    title: [undefined],
    relatedBookingId: [undefined],
    totalPrice: [undefined, [Validators.required]],
    totalIncludingVAT: [undefined, [Validators.required]],
    customerEmail: ['', [Validators.required, Validators.email]],
    commentsToCustomer: [undefined],
    commentsToAdmin: [undefined],
    currency: [CurrencyEnum.ILS]
  });

  get customerEmail() { return this.paymentPageForm.get('customerEmail') };

  itemsArray = this.fb.array([
    this.fb.group({
      itemName: [undefined, [Validators.required]],
      itemDescription: [undefined, [Validators.required]],
      itemPrice: [undefined, [Validators.required]],
      itemQty: [undefined, Validators.required],
      totalPricePerItem: [{value: undefined, disabled: true}, Validators.required]
    })
  ]);

  showNewPaymentPage = false;
  booking?: Booking;
  bookingCurrency = CurrencyEnum;
  currencyList = CurrencyList;
  showCurrencyMenu = false;
  showNoBookingError = false;
  selectedCurrencyCode = this.currencyList[0].currency;

  paymentPages: IPaymentPageForList[] = [];
  editPage = false;

  ngOnInit(): void {
    this.onItemsArrayChanges();
    this.bookingService.listPaymentPages().subscribe({
      next: result => {
        this.paymentPages = result;
        console.log(this.paymentPages);
      },
      error: error => {
        this.alertify.error(error);
        console.error(error);
      }
    })
  }

  //#region DX related methods
  onContentReady(e: any) { }
  onGridInit() { }
  onToolbarPreparing(e) {
    // e.toolbarOptions.items.unshift({
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     icon: 'refresh',
    //     onClick: this.resetState.bind(this)
    //   }
    // });
  }
  prepareContextMenu(e) { }

  getBookingStatusEnumName(rowData) {
    return PaymentPageStatus[rowData.status];
  }

  editPaymentPage(id: any) {
    this.editPage = true;
    const pageToUpdate = this.paymentPages.find(x => x.id == id);
    // patch page form
    Object.keys(this.paymentPageForm.controls).map(x => {
      const control = this.paymentPageForm.get(x);
      control.setValue(pageToUpdate[x]);
    })

    // reset itemsArray
    this.itemsArray = this.fb.array([]);
    pageToUpdate.items.map(item => {
      const fg = this.fb.group({
        itemName: [item.itemName, [Validators.required]],
        itemDescription: [item.itemDescription, [Validators.required]],
        itemPrice: [item.itemPrice, [Validators.required]],
        itemQty: [item.itemQty, Validators.required],
        totalPricePerItem: [{value: item.totalPricePerItem, disabled: true}, Validators.required]
      });
      this.itemsArray.push(fg);
    })
    
    this.showNewPaymentPage = true;
  }

  //#endregion


  public onCurrencySelect(id: CurrencyEnum) {
    const item = this.currencyList.find(x => x.id == id);
    this.paymentPageForm.get('currency').setValue(id);
    this.selectedCurrencyCode = item.currency;
    this.showCurrencyMenu = false;
    this.calculateTotalPrice();
  }


  public onAddItem() {
    const fg = this.fb.group({
      itemName: [undefined, [Validators.required]],
      itemDescription: [undefined, [Validators.required]],
      itemPrice: [undefined, [Validators.required]],
      itemQty: [undefined, Validators.required],
      totalPricePerItem: [{value: undefined, disabled: true}, Validators.required]
    });

    this.itemsArray.push(fg);
    this.onItemsArrayChanges();
  }

  public onRemoveItem(idx: number) {
    this.itemsArray.removeAt(idx);
    this.calculateTotalPrice();
  }

  public onSubmit() {
    Object.keys(this.paymentPageForm.controls).map(x => {
      const control = this.paymentPageForm.get(x);
      if (!control.valid) {
        control.markAsTouched({ onlySelf: true });
      }
    })

    Object.keys(this.itemsArray.controls).map(x => {
      const control = this.itemsArray.get(x) as FormGroup;
      Object.keys(control.controls).map(xx => {
        const c = control.get(xx);
        if (!c.valid)
          c.markAsTouched({ onlySelf: true });
      })
    })

    if (this.paymentPageForm.invalid || this.itemsArray.invalid) {
      this.alertify.error('form is invalid');
    }
    else {
      this.submitNewPaymentPage();
    }
  }

  public getBookingDetails() {
    const bookingId = this.paymentPageForm.get('relatedBookingId').value;
    if (bookingId) {
      this.showNoBookingError = false;
      this.booking = undefined;
      this.bookingService.getBookingById(bookingId).subscribe({
        next: result => {
          if (result) {
            this.booking = result;

          }
          else {
            this.showNoBookingError = true;
          }
        },
        error: error => {
          console.log(error);
        }
      })
    }
  }

  public setCustomerEmail() {
    this.paymentPageForm.get('customerEmail').setValue(this.booking.email.trim());
  }

  public getCustomerDetails() {
    const customerEmail = this.paymentPageForm.get('customerEmail').value;
    if (customerEmail)
      console.log('getting customer details for email ', customerEmail);
  }

  private onItemsArrayChanges() {
    this.itemsArray.controls.map(control => {
      const itemPriceCtrl = control.get('itemPrice');
      const itemQtyCtrl = control.get('itemQty');
      const totalPricePerItem = control.get('totalPricePerItem');

      itemPriceCtrl.valueChanges.subscribe(v => {
        totalPricePerItem.setValue((v ? v : 0) * (itemQtyCtrl.value ? itemQtyCtrl.value : 0));
        this.calculateTotalPrice();
      })

      itemQtyCtrl.valueChanges.subscribe(v => {
        totalPricePerItem.setValue((v ? v : 0) * (itemPriceCtrl.value ? itemPriceCtrl.value : 0));
        this.calculateTotalPrice();
      })
    })
  }

  private calculateTotalPrice() {
    const totalPrice = this.paymentPageForm.get('totalPrice');
    const totalIncludingVAT = this.paymentPageForm.get('totalIncludingVAT');
    let totals = 0;
    this.itemsArray.controls.map(controls => {
      const totalPricePerItem = controls.get('totalPricePerItem');
      totals += totalPricePerItem.value ? totalPricePerItem.value : 0;
    })
    totalPrice.setValue(totals);
    totalIncludingVAT.setValue(totals);
    if (this.paymentPageForm.get('currency').value == CurrencyEnum.ILS)
      totalIncludingVAT.setValue(totals * 1.17);
  }

  private submitNewPaymentPage() {
    // create IPaymentItem list
    const items: IPaymentItem[] = [];
    this.itemsArray.controls.map((formGroup: FormGroup) => {
      const item: IPaymentItem = {
        itemName: formGroup.get('itemName').value,
        itemDescription: formGroup.get('itemDescription').value,
        itemPrice: formGroup.get('itemPrice').value,
        itemQty: formGroup.get('itemQty').value,
        totalPricePerItem: formGroup.get('totalPricePerItem').value,
      }
      items.push(item);
    });

    // create IPaymentPage object
    const paymentPage: IPaymentPage = {
      title: this.paymentPageForm.get('title').value,
      relatedBookingId: this.paymentPageForm.get('relatedBookingId').value,
      totalPrice: this.paymentPageForm.get('totalPrice').value,
      totalIncludingVAT: this.paymentPageForm.get('totalIncludingVAT').value,
      customerEmail: this.paymentPageForm.get('customerEmail').value,
      commentsToCustomer: this.paymentPageForm.get('commentsToCustomer').value,
      commentsToAdmin: this.paymentPageForm.get('commentsToAdmin').value,
      currency: this.paymentPageForm.get('currency').value,
      items: items
    }
    console.log(paymentPage);

    this.bookingService.createPaymentPage(paymentPage).subscribe({
      next: result => {
        this.paymentPages.push(result);
        console.log(this.paymentPages);
        this.showNewPaymentPage = false;
      },
      error: error => {
        console.error(error);
        this.alertify.error(error);
      }
    })
  }
}

export interface IPaymentPageForList extends IPaymentPage {
  link: string,
  status: PaymentPageStatus,
  id: number,
  CreatedAtUtc: Date
}

export interface IPaymentPage {
  title: string,
  relatedBookingId: number,
  totalPrice: number,
  totalIncludingVAT: number,
  customerEmail: string,
  commentsToCustomer: string,
  commentsToAdmin: string,
  currency: CurrencyEnum,
  items: IPaymentItem[] 
}

export interface IPaymentItem {
  itemName: string,
  itemDescription: string,
  itemPrice: number,
  itemQty: number,
  totalPricePerItem: number,
}

export enum PaymentPageStatus
{
    Open,
    Closed,
    Cancelled
}
