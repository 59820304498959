
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';



import { Observable ,  BehaviorSubject } from 'rxjs';
import { User } from '../../../../Model/_models/User';
import { AuthUser } from '../../../../Model/_models/authUser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  baseUrl = environment.apiUrl;
  userToken: any;
  decodedToken: any;
  currentUser: User;

  constructor(
    private http: HttpClient,
    private jwtHelperService: JwtHelperService
  ) {}

  login(model: any) {
    console.log('starting authservice.login');

    return this.http
      .post<AuthUser>(this.baseUrl + 'auth/login_v2', model, {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
      }).pipe(
      map(user => {
        if (user) {
          localStorage.setItem('token', user.tokenString);
          localStorage.setItem('user', JSON.stringify(user.user));
          this.decodedToken = this.jwtHelperService.decodeToken(
            user.tokenString
          );
          this.currentUser = user.user;
          this.userToken = user.tokenString;

          console.log(this.currentUser);
        }
      }));
  }

  register(user: User) {
    return this.http.post(this.baseUrl + 'auth/register', user, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  loggedIn() {
    const token = this.jwtHelperService.tokenGetter();

    if (!token) {
      return false;
    }
      return !this.jwtHelperService.isTokenExpired(token);
  }

  logout() {
    this.userToken = null;
    this.currentUser = null;
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
}
