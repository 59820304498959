import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { TreeNode } from './tree-node';
import { CurrencyEnum } from '../../../../../../Model/_models/general.interface';

@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

    @Input() treeData: TreeNode[];
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onNodeClicked = new EventEmitter();
    CurrencyEnum = CurrencyEnum;
    ngOnInit() {
    }
    /**
     * Toggle node
     * @param node
     */
    toggleChild(node) {
        node.showChildren = !node.showChildren;
    }

    /**
    * Emit selected node
    * @param node
    */

    onRegionSelected(node) {
        this.onNodeClicked.emit(node);
    }
    /**
     * Emit selected node
     * @param node
     */
    onRegionSelect(node) {
        this.onNodeClicked.emit(node);
    }

    getDeliveryCost(cost) {
        const c =  String(cost);
        return  c + ' ';
    }
}
