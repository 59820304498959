import {
  BookingInventory, BookingDelivery, BookingPriceTypeEnum,
  BookingStatusEnum, OrgBookingStatusEnum, BookingTimeSlot
} from './../../../../../../Model/_models/Booking';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingService } from '../../../_services/booking.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrencyEnum } from '../../../../../../Model/_models/general.interface';
import * as moment from 'moment';
import { startCase, sortBy } from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';
import { User } from '../../../../../../Model/_models/User';
import { AlertifyService } from '../../../_services/alertify.service';
import { confirm } from 'devextreme/ui/dialog';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { environment } from '../../../../environments/environment';
import { INgxMyDpOptions, IMyDayLabels } from 'ngx-mydatepicker';
import { InventoryFlagList, ServiceInventoryFlags } from '../../../../../../Model/_models/ServiceProvider';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-edit-bookings',
  templateUrl: './edit-bookings.component.html',
  styleUrls: ['./edit-bookings.component.css']
})
export class EditBookingsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @BlockUI() blockUI: NgBlockUI;
  loading = false;
  bookingId: Number;
  booking: any;
  bookedVenue: any;
  Venueid: number;
  bookingInventories: BookingInventory[];
  bookingDeliveries: BookingDelivery[];
  relatedOrgBooking: any;
  bookingTimeSlots: any;
  bookingUser: User;
  bookingStatus: any;
  OrgStatus: any;
  EditPoCodeVisible = false;
  poValue = '';
  finalPrice = '';
  created: string;
  bookings = [];
  bookingStatusEnum = BookingStatusEnum;
  serverUrl: string = `https://${environment.meetinkzWebSiteHost}`;

  currentDate = moment().add(-1, 'days');
  disableDates = [];
  dateOptions: INgxMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    disableUntil: { year: this.currentDate.year(), month: this.currentDate.month() + 1, day: this.currentDate.date() },
    disableDates: this.disableDates,
    showTodayBtn: false,
    markCurrentYear: false,
    allowSelectionOnlyInCurrentMonth: false
  };
  startDate: any;
  cateringItems: ICateringPrice[] = [];
  inventoryFlagList = InventoryFlagList;


  constructor(private bookingService: BookingService,
    private router: Router,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
  ) {
    this.bookingInventoryPriceFormatter = this.bookingInventoryPriceFormatter.bind(this);
    this.getBookingPriceString = this.getBookingPriceString.bind(this);
    this.getBookingUSDPriceString = this.getBookingUSDPriceString.bind(this);
    this.getBookingStatusEnumName = this.getBookingStatusEnumName.bind(this);
    this.getOrgBookingStatusEnumName = this.getOrgBookingStatusEnumName.bind(this);
    this.bookingRentFormatter = this.bookingRentFormatter.bind(this);
    this.bookingDeliveryPriceFormatter = this.bookingDeliveryPriceFormatter.bind(this);
    this.bookingRentTypeFormatter = this.bookingRentTypeFormatter.bind(this);
    this.bookingDeliveryTimeFormatter = this.bookingDeliveryTimeFormatter.bind(this);
    this.bookingEndTimeFormatter = this.bookingEndTimeFormatter.bind(this);
    this.bookingStartTimeFormatter = this.bookingStartTimeFormatter.bind(this);
    this.cateringItemPriceFormatter = this.cateringItemPriceFormatter.bind(this);

  }

  ngOnInit() {
    if (!environment.production)
      this.serverUrl = `http://${environment.meetinkzWebSiteHost}`;

    this.blockUI.start('Loading...'); // Start blocking
    this.loading = true;
    this.bookingId = this.route.snapshot.params.id;

    this.bookingService.getBookingById(this.bookingId).subscribe((response) => {
      this.booking = response;
      this.created = this.getBookingCreatedAt(this.booking);
      this.bookedVenue = this.booking.bookedVenue;
      this.bookingInventories = this.booking.bookingInventories;
      this.bookingDeliveries = this.booking.bookingDeliveries;
      this.bookingTimeSlots = this.booking.bookingTimeSlots;

      console.log(this.booking);

      // prepare any catering specific requirements items  (e.g. Vegan, Vegetarian etc.)
      this.prepareInventoryCatering();

      // add price packages to inventory
      this.normalizePricePackageToInventory();

      // for time slots of type package, we need to unify the structure of the related package and delivery to
      // be the same as the inventories/deliveries
      // for (let index = 0; index < this.booking.bookingTimeSlots.length; index++) {
      //   const bts = this.booking.bookingTimeSlots[index];
      //   if (bts.priceBreakDown) {
      //     console.log('bts has breakdown :', bts);
      //     const newInventory: BookingInventory = {
      //       id: null,
      //       providerName: bts.relatedPricePackage.providerName,
      //       price: bts.price - bts.priceBreakDown.venueRentPrice - bts.priceBreakDown.deliveryCost,
      //       amount: bts.guestNumber,
      //       deliveryTime: bts.start,
      //       inventoryName: bts.relatedPricePackage.name
      //     };
      //     this.bookingInventories.push(newInventory);

      //     if (bts.priceBreakDown.deliveryCost && bts.priceBreakDown.deliveryCost > 0) {
      //       const newDelivery: BookingDelivery = {
      //         id: null,
      //         providerName: bts.relatedPricePackage.providerName,
      //         deliveryCost: bts.priceBreakDown.deliveryCost,
      //         deliveryTime: bts.start
      //       };
      //       this.bookingDeliveries.push(newDelivery);
      //     }
      //   }
      // }

      // resort arrays by dates
      sortBy(this.bookingInventories, i => i.deliveryTime);
      sortBy(this.bookingDeliveries, i => i.deliveryTime);

      this.relatedOrgBooking = this.booking.relatedOrgBooking;
      this.Venueid = this.bookedVenue.id;
      this.bookingUser = this.booking.bookingUser;
      if (this.booking.relatedOrgBooking) {
        this.bookingStatus = this.getBookingStatusEnumName().replace(/([A-Z])/g, ' $1').trim();
      }
      if (this.booking.status !== undefined) {
        this.OrgStatus = this.getOrgBookingStatusEnumName(this.booking.status).replace(/([A-Z])/g, ' $1').trim();
      }
      if (this.booking.relatedOrgBooking && this.booking.relatedOrgBooking.poCode) {
        this.poValue = this.booking.relatedOrgBooking.poCode;
      }
      this.finalPrice = this.getBookingPriceString();
    }, () => {
      alert('Error while getting data');
    }, () => {
      this.loading = false;
      this.blockUI.stop();
    });
  }

  public sendRequestForReviewByAdmin() {
    this.bookingService.sendRequestForReviewByAdmin(this.booking.id).subscribe(
      booking => {
        this.booking = booking;
        console.log('sendRequestForReviewByAdmin finished');
      },
      error => {
        this.alertify.error(error);
        console.error(error);
      }
    )
  }

  getBookingUserDetails(rowData: any): string {
    return `${rowData.bookingUser.firstName} ${rowData.bookingUser.lastName ? rowData.bookingUser.lastName : ''}`;
  }

  bookingEndTimeFormatter(rowData: any): string {
    return this.getHoursStringFromDate(new Date(rowData.utcEnd));

    // const timeWithoutTimezone = rowData.end.slice(0, -9);
    // return timeWithoutTimezone.split('T')[1];
  }

  bookingStartTimeFormatter(rowData: any): string {
    return this.getHoursStringFromDate(new Date(rowData.utcStart));

    // const timeWithoutTimezone = rowData.start.slice(0, -9);
    // return timeWithoutTimezone.split('T')[1];
  }

  bookingRentFormatter(rowData: any): string {
    if (rowData.priceBreakDown && rowData.priceBreakDown.venueRentPrice) {
      return `${rowData.priceBreakDown.venueRentPrice.toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${this.currencyEnumToName(this.booking.currency)}`;
    }
    return `${rowData.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${this.currencyEnumToName(this.booking.currency)}`;
  }

  bookingRentTypeFormatter(rowData: any): string {
    return BookingPriceTypeEnum[rowData.priceType].replace(/([A-Z])/g, ' $1').trim();
  }

  bookingDeliveryTimeFormatter(rowData: any): string {
    return this.getHoursStringFromDate(new Date(rowData.deliveryTime));

    // return `${date.getDate()}/${date.getMonth()+1}, ${hoursStr}:${minutesStr}`;
    // return moment(rowData.deliveryTime, 'YYYY-M-D H:mm').format('DD/MM HH:mm');
  }

  cateringItemPriceFormatter(rowData: any): string {
    return `${rowData.totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${this.currencyEnumToName(this.booking.currency)}`;
  }

  bookingDeliveryPriceFormatter(rowData: any): string {
    return `${rowData.deliveryCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${this.currencyEnumToName(this.booking.currency)}`;
  }

  bookingInventoryPriceFormatter(rowData: any): string {
    return `${rowData.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${this.currencyEnumToName(this.booking.currency)}`;
  }

  isMainVenue(rowData: any) {
    if (this.booking && this.booking.bookedVenue && this.booking.bookedVenue.id != rowData.bookedVenueId)
      console.log('different venue was booked!');
    
    return rowData.bookedVenueId;
  }

  navigateToEditVenue(venueId: number) {
    this.router.navigate([`/venues/edit/${venueId}`]);
  }

  navigateToEditUser(id: number) {
    this.router.navigate([`/users/edit/${id}`]);
  }

  formatterFunc(value: any) {

    if (value !== null)
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return 'no price';
  }

  getBookingStatusEnumName(): string {
    if (this.booking.relatedOrgBooking) {
      return startCase(OrgBookingStatusEnum[this.booking.relatedOrgBooking.status]);
    } else {
      return '-';
    }
  }

  getOrgBookingStatusEnumName(statusCode: number): string {
    return BookingStatusEnum[statusCode];
  }

  hoursNumberToString(date: string): string {
    return moment(date).format('HH:mm');
  }

  currencyEnumToName(currency: number): string {
    return CurrencyEnum[currency];
  }

  getBookingPriceString(): string {
    return `${this.formatterFunc(this.booking.finalPrice)} ${this.currencyEnumToName(this.booking.currency)}`;
  }

  getBookingUSDPriceString(rowData: any): string {
    return `${this.formatterFunc(rowData.finalPriceInUnifiedCurrency)}`;
  }

  bookingStartDateFormatter(rowData: any): string {
    return moment.utc(new Date(rowData.start)).format('ddd, Do MMM, YYYY');
  }

  getBookingCreatedAt(book: any): string {
    return moment.utc(book.createdAt).local().toDate().toLocaleString();
  }


  onConfirmClick() {
    const result =
      confirm(`Are you sure you want to CONFIRM booking id ${this.booking.id} by ${this.getBookingUserDetails(this.booking)}?`,
        'Confirm Booking');
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.bookingStatus = this.getBookingStatusEnumName();
        this.confirmBooking();
      }
    });
  }

  confirmBooking() {
    this.bookingService.confirmBooking(this.booking.id).subscribe((response) => {
      // update booking in current booking list
      if (this.booking.relatedOrgBooking) {
        this.booking.relatedOrgBooking.status = response.status;
        this.bookingStatus = this.getBookingStatusEnumName();
        this.alertify.success('Booking has been confirmed.');
      }
    }, () => {
      this.alertify.error('Booking confirmation has failed!');
    }, () => {
    });
  }

  onArchiveClick() {
    const result =
      confirm(`Are you sure you want to ARCHIVE booking id ${this.booking.id} by ${this.getBookingUserDetails(this.booking)}?`,
        'Archive Booking');
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.archiveBooking();
      }
    });
  }

  archiveBooking() {
    this.bookingService.archiveBooking(this.booking.id).subscribe(() => {
      this.router.navigate(['/bookings/']);
      this.alertify.success('Booking archived successfully');
    }, () => {
      this.alertify.error('Error while archiving');
    });
  }

  onResendClick() {
    this.confirmBooking();
  }

  Save() {
    if (this.poValue !== '') {
      const body: any = {};
      body.POCode = this.poValue;
      if (this.booking.bookingUser && this.booking.bookingUser.relatedOrg) {
        this.bookingService.EditPOCode(this.booking.bookingUser.relatedOrg.id, this.booking.relatedOrgBooking.id, body).subscribe((res) => {
          this.alertify.success('POCode updated');
        });
      }
    }
  }

  public updateSingleBookingTimeSlot(data: any) {
    // update booking time slot with selected data
    const updatedBookingTimeSlot = this.updateBookingTimeSlot(data.data);

    if (!updatedBookingTimeSlot) {
      this.alertify.error("Date / Time is not valid");
      return;
    }

    // update server
    this.bookingService.updateBookingTimeSlot(updatedBookingTimeSlot).subscribe(
      _ => this.alertify.success("Dates Updated"),
      error => {
        this.alertify.error("Failed to update Booking Dates");
        console.error(error);
      }
    );
  }

  private updateBookingTimeSlot(bookingTimeSlotToUpdate: BookingTimeSlot): BookingTimeSlot {
    const startDate = new Date(bookingTimeSlotToUpdate.start);

    const startTime: any[] = bookingTimeSlotToUpdate.startTime.toString().split(":");
    const endTime: any[] = bookingTimeSlotToUpdate.endTime.toString().split(":");

    // validate start and end time
    if (startTime[0] > 23 || endTime[0] > 23)
      return;
    if ((startTime.length > 1 && startTime[1] > 59) || (endTime.length > 1 && endTime[1] > 59))
      return;

    let newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime[0], 0);
    let newStartTime = parseFloat(startTime[0]);
    if (startTime.length > 1) {
      newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime[0], startTime[1]);
      newStartTime += startTime[1] / 60;
    }

    let newEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), endTime[0], 0);
    let newEndTime = parseFloat(endTime[0]);
    if (endTime.length > 1) {
      newEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), endTime[0], endTime[1]);
      newEndTime += endTime[1] / 60;
    }

    // validate start date is before end date
    if (newStartDate.getTime() >= newEndDate.getTime())
      return;

    // update selectedTimeSlot
    const selectedTimeSlot: BookingTimeSlot = this.bookingTimeSlots.filter(x => x.id == bookingTimeSlotToUpdate.id);
    selectedTimeSlot[0].start = moment(newStartDate).format();
    selectedTimeSlot[0].end = moment(newEndDate).format();
    selectedTimeSlot[0].startTime = newStartTime;
    selectedTimeSlot[0].endTime = newEndTime;

    console.log(selectedTimeSlot[0]);

    return selectedTimeSlot[0];
  }

  private getHoursStringFromDate(date: Date): string {
    const startDate = new Date(date);
    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();

    let hoursStr = `${hours}`;
    if (hours < 10) hoursStr = `0${hours}`;

    let minutesStr = `${minutes}`;
    if (minutes < 10) minutesStr = `0${minutes}`;

    return `${hoursStr}:${minutesStr}`;
  }

  private prepareInventoryCatering() {
    this.cateringItems = [];
    this.bookingInventories.forEach(inv => {
      if (inv.selectedQtyGlutenFree && inv.selectedQtyGlutenFree > 0) {
        const item = this.createCateringPriceItem(ServiceInventoryFlags.GlutenFree, inv.selectedQtyGlutenFree, inv.relatedInventory);
        this.cateringItems.push(item);
      }

      if (inv.selectedQtyVegan && inv.selectedQtyVegan > 0) {
        const item = this.createCateringPriceItem(ServiceInventoryFlags.Vegan, inv.selectedQtyVegan, inv.relatedInventory);
        this.cateringItems.push(item);
      }

      if (inv.selectedQtyVegetarian && inv.selectedQtyVegetarian > 0) {
        const item = this.createCateringPriceItem(ServiceInventoryFlags.Vegetarian, inv.selectedQtyVegetarian, inv.relatedInventory);
        this.cateringItems.push(item);
      }

    })
  }

  private createCateringPriceItem(type: ServiceInventoryFlags, qty: number, serviceProviderInventory: any): ICateringPrice {
    const cateringItem = serviceProviderInventory.inventoryCateringPrice.find(x => x.flags == type);
    const item: ICateringPrice = {
      cateringType: type,
      cateringName: this.inventoryFlagList.find(x => x.id == type).Name,
      qty: qty,
      price: cateringItem.price,
      totalPrice: qty * cateringItem.price
    }
    return item;
  }

  private normalizePricePackageToInventory() {
    this.booking.bookingInventories.forEach(inv => {
      if (inv.relatedPricePackageId && inv.relatedPricePackageId > 0) {
        inv.providerName = inv.relatedPricePackage.providerName;
        inv.price = inv.relatedPricePackage.pricePerPerson;
        inv.inventoryName = inv.relatedPricePackage.name;
        if (inv.amount == 0) inv.amount = inv.selectedMenuQty;
      }
    })
  }
}

interface ICateringPrice {
  cateringType: ServiceInventoryFlags,
  cateringName: string,
  qty: number,
  price: number,
  totalPrice: Number
}
