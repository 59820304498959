
export enum LanguagesEnum {
  EN_UK = 'en-UK',
  HE_IL = 'he-IL'
}

export enum CurrencyEnum {
  ILS = 0,
  USD = 1,
  GBP = 2,
  EUR = 3
}

export enum CountryCodeEnum {
  IL = 'IL',
  GB = 'GB',
  NL = 'NL',
  BE = 'BE',
  DE = 'DE',
  US = 'US',
  CN = 'CN',
}

export enum PublishStatusEnum {
  Draft = 1,
  Published = 2,
  Archived = 3
}

export enum OrgAuthRoles {
  Admin = 1,
  BudgetOwner = 2,
  EventPlanner = 4
}

export enum OrgUserStatus {
  Invited = 1,
  Onboard = 2,
  Removed = 3
}

// this enum is for the time slots types
export enum VenueTimeSlotType {
  Morning = 3,
  Afternoon = 4,
  Evening = 5,
  FullDay = 6,
}

// this enum is for package types:
export enum PackageType {
  FullDay = 1,
  HalfDay = 2
}

// this enum is for the "my hire options" drop down:
export enum BookingDurationTypeEnum {
  Hourly = 1,
  Package = 2,
  MorningTimeSlot = 3,
  AfternoonTimeSlot = 4,
  EveningTimeSlot = 5,
  FullDayTimeSlot = 6,
  EatandMeet = 7
}

