import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-config',
  templateUrl: './tax-config.component.html',
  styleUrls: ['./tax-config.component.css']
})
export class TaxConfigComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  logEvent( event) {

  }

}
