import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityTime, PricingDetails, Venue, VenueGroup } from '../../../../Model/_models/Venue';
import { PaginatedResult } from '../../../../Model/_models/Pagination';
import {
  map
} from 'rxjs/operators';
import { Coupon } from '../_model/coupon';
import { Redeemer } from '../_model/redeemer';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  baseUrl = environment.apiUrl;

  constructor(
    private authHttp: HttpClient,
    private http: HttpClient
  ) { }


  public updateCoupon(coupon: Coupon): Observable<Coupon> {
    return this.authHttp.post<Coupon>(this.baseUrl + 'coupon/UpdateCoupon', coupon);
  }

  public createNewCoupon(coupon: Coupon): Observable<Coupon> {
    return this.authHttp.post<Coupon>(this.baseUrl + 'coupon/CreateCoupon', coupon);
  }

  public getCoupons(): Observable<Coupon[]> {
    return this.authHttp.get<Coupon[]>(this.baseUrl + 'coupon/GetCoupons');
  }

  public getRedeemerByCouponId(couponId: number): Observable<Redeemer[]> {
    return this.authHttp.get<Redeemer[]>(`${this.baseUrl}coupon/GetRedeemerByCouponId?couponId=${couponId}`);
  }

  


} 
