
import {map} from 'rxjs/operators';
import { Booking, BookingTimeSlot, IRefreshmentBooking } from '../../../../Model/_models/Booking';
import { PaginatedResult } from '../../../../Model/_models/Pagination';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { POCode } from '../../../../Model/_models/POCode';
import { Observable } from 'rxjs';
import { IPaymentPage, IPaymentPageForList } from '../_components/payment-pages/payment-pages.component';

@Injectable()
export class BookingService {
    baseUrl = environment.apiUrl;

    constructor(private authHttp: HttpClient) { }

    public createPaymentPage(paymentPage: IPaymentPage) {
        return this.authHttp.post<IPaymentPageForList>(this.baseUrl + 'booking/CreatePaymentPage', paymentPage);
    }

    public listPaymentPages() {
        return this.authHttp.get<IPaymentPageForList[]>(this.baseUrl + 'payment/ListPaymentPages');
    }

    
    getBookingById(id: any) {
        return this.authHttp.get<Booking>(this.baseUrl + 'booking/' + id);
    }

    sendRequestForReviewByAdmin(bookingId: number): Observable<Booking> {
        let params = new HttpParams();
        params = params.append('bookingId', bookingId.toString())
        return this.authHttp.get<Booking>(this.baseUrl + 'booking/SendRequestForReviewByAdmin', { params: params });
    }

    getPoCode(id: any) {
        return this.authHttp.get<POCode>(this.baseUrl + 'OrgPo/' + id);
    }

    getBookings(page?, itemsPerPage?) {
        const paginatedResult: PaginatedResult<Booking[]> = new PaginatedResult<Booking[]>();
        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        return this.authHttp
            .get<Booking[]>(this.baseUrl + 'booking/adminbookings', { observe: 'response', params }).pipe(
            map(response => {
                paginatedResult.result = response.body;
                paginatedResult.result = response.body.map(b => {
                    b.bookedVenue_RealName = `${b.bookedVenue.realName}`;
                    b.bookedVenue_Id = b.bookedVenue.id;
                    return b;
                });
                if (response.headers.get('Pagination') != null) {
                    paginatedResult.pagination = JSON.parse(
                        response.headers.get('Pagination')
                    );
                }

                return paginatedResult;
            }));
    }

    getRefreshmentBookings(page?, itemsPerPage?) {
        const paginatedResult: PaginatedResult<IRefreshmentBooking[]> = new PaginatedResult<IRefreshmentBooking[]>();
        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        return this.authHttp
            .get<IRefreshmentBooking[]>(this.baseUrl + 'booking/adminRefreshmentBookings', { observe: 'response', params }).pipe(
            map(response => {
                paginatedResult.result = response.body;
                if (response.headers.get('Pagination') != null) {
                    paginatedResult.pagination = JSON.parse(
                        response.headers.get('Pagination')
                    );
                }

                return paginatedResult;
            }));
    }

    getRefreshmentBookingById(id: number): Observable<IRefreshmentBooking> {
        return this.authHttp.get<IRefreshmentBooking>(`${this.baseUrl}booking/GetRefreshmentBookingById?id=${id}`);
    }

    public getBookingByDate(start: Date, end?: Date)
    {
        const endDateIsoString = end ? end.toISOString() : undefined;
        return this.authHttp.get<any>(`${this.baseUrl}booking/GetBookingByDate?start=${start.toISOString()}&end=${endDateIsoString}`);
    }

    public confirmRefreshmentBooking(bookingId: number, serviceProviderId: number): Observable<IRefreshmentBooking> {
        return this.authHttp
          .post<IRefreshmentBooking>(`${this.baseUrl}booking/${bookingId}/confirmBookingByServiceProvider/${serviceProviderId}`, { observe: 'response' })
          .pipe(map(response => {
            return response;
          }));
      }

    confirmBooking(bookingId: number) {
        return this.authHttp
            .post<Booking>(this.baseUrl + `booking/${bookingId}/confirm`, { observe: 'response' }).pipe(
            map(response => {
                return response;
            }));
    }

    archiveBooking(bookingId: number) {
        return this.authHttp
            .post<Booking>(this.baseUrl + `booking/${bookingId}/archive`, { observe: 'response' }).pipe(
            map(response => {
                return response;
            }));
    }

    getOrganizationList() {
        return this.authHttp.get(this.baseUrl + `organization/list`);
    }

    createNewBusinessClient(body) {
        return this.authHttp.post(this.baseUrl + `organization`, body);
    }

    getAllUsers(orgId) {
        return this.authHttp.get(this.baseUrl + `Orgusers/${orgId}/users`);
    }

    setOrganizationLogo(orgId, logoFile: File) {
        const formData: FormData = new FormData();
        formData.append('file', logoFile);
        return this.authHttp.post(this.baseUrl + `organization/${orgId}/logo`, formData);
    }

    inviteUser(orgId, userDetails) {
        const body = {
            OrganizationId: orgId ? orgId : null,
            FirstName: userDetails.firstName ? userDetails.firstName : null,
            LastName: userDetails.lastName ? userDetails.lastName : null,
            Email: userDetails.email ? userDetails.email : null,
            Role: userDetails.role ? userDetails.role : null,
            BookingAuthorizerId: userDetails.bookingAuthorizerId ? userDetails.bookingAuthorizerId : null
        };
        return this.authHttp.post(this.baseUrl + `Orgusers/${orgId}/InviteUserToOrg`, body);
    }

    getOrgPoList(orgId: Number) {
        return this.authHttp.get(this.baseUrl + `OrgPO/${orgId}/pos`);
    }

    newpoCode(orgId, userDetails) {
        if (!orgId || !userDetails.POCode || !userDetails.Budget) {
            throw new Error('Invalid input for create new ref code');
        }
        const body = {
            POCode: userDetails.POCode,
            Budget: userDetails.Budget,
            RelatedOrgId: orgId
        };
        return this.authHttp.post(this.baseUrl + `OrgPO/${orgId}/PO`, body);
    }

    updatePOCode(orgId, userDetails) {
        if (!orgId || !userDetails.Id || !userDetails.Budget) {
            throw new Error('Invalid input for update  ref code budget');
        }
        const body = {
            Id: userDetails.Id,
            Budget: userDetails.Budget
        };
        return this.authHttp.patch(this.baseUrl + `OrgPO/${orgId}/PO`, body);
    }

    removePOCode(orgId, POCodeId) {
        if (!orgId || !POCodeId) {
            throw new Error('Invalid input for Removing ref code record');
        }
        return this.authHttp.delete(this.baseUrl + `OrgPO/${orgId}/PO/` + POCodeId);
    }

    EditPOCode(orgId, bookingId, userDetails) {
        if (!orgId || !bookingId || !userDetails.POCode) {
            throw new Error('Invalid input for Removing ref code record');
        }
        const body = {
            POCode: userDetails.POCode,
        };
        return this.authHttp.post(this.baseUrl + `OrgBookings/${orgId}/booking/` + bookingId + '/updatepo', body);
    }
    updateUserData(orgId, orgUserId, body) {
        return this.authHttp.post(this.baseUrl + `Orgusers/${orgId}/update/${orgUserId}`, body);
    }

    removeOrgUser(orgId, orgUserId) {
        const body = {};
        return this.authHttp.post(this.baseUrl + `Orgusers/${orgId}/remove/${orgUserId}`, body);
    }

    publishClient(id) {
        return this.authHttp.post(this.baseUrl + `organization/${id}/publish`, null);
    }

    UnPublishClient(id) {
        return this.authHttp.post(this.baseUrl + `organization/${id}/unpublish`, null);
    }

    setMainAccount(id, orguserid) {
        return this.authHttp.post(this.baseUrl + `Orgusers/${id}/setmain/${orguserid}`, null);
    }

    public updateBookingTimeSlot(bookingTimeSlot: BookingTimeSlot): Observable<any> {
        return this.authHttp.post(this.baseUrl + 'booking/UpdateBookingTimeSlot', bookingTimeSlot);
    }
}
