import { zip } from 'rxjs/observable/zip';
/// <reference types="@types/googlemaps" />
import { FileSizePipe } from 'ngx-filesize';
import { PublishStatusEnum } from '../../../../../../Model/_models/general.interface';
import { Errorhelper } from '../../../_helpers/error-helper';
import { environment } from '../../../../environments/environment';
import { Component, ElementRef, NgZone, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Photo } from '../../../../../../Model/_models/Photo';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityTime, PricingDetails, Venue, VenueGroup } from '../../../../../../Model/_models/Venue';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CurrencyEnum, LanguagesEnum, CountryCodeEnum, VenueTimeSlotType } from '../../../../../../Model/_models/general.interface';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { FileUploaderComponent } from '../../file-uploader/file-uploader.component';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { VenueService } from '../../../_services/venue.service';
import { LocationService, ParsedAddress } from '../../../_services/location.service';
import { VenueEditForm } from './venue-edit.interface';
import { VenueEditService } from './venue-edit.service';
import { TreeModel, NodeSelectedEvent, Ng2TreeSettings } from 'ng2-tree';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DxFormComponent, DxDataGridComponent } from 'devextreme-angular';
import { Hours } from './hours';
import { PriceByPackageComponent } from '../../../shared/price-by-package/price-by-package.component';
import { Review, ReviewStatus } from '../../../../../../Model/_models/review.model';

@Component({
  selector: 'app-venue-edit',
  templateUrl: './venue-edit.component.html',
  styleUrls: ['./venue-edit.component.css'],
  providers: [VenueEditService, FileSizePipe]
})

export class VenueEditComponent implements OnInit {
  @ViewChild(DxFormComponent, { static: false }) myForm: DxFormComponent;
  @ViewChild('gridContainer', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('venueServiceProviderGridContainer', { static: false }) venueServiceProviderGridContainer: DxDataGridComponent;
  @ViewChild(PriceByPackageComponent, { static: false }) private saveTiers: PriceByPackageComponent;
  venue: Venue;
  form: FormGroup;
  host = environment.meetinkzWebSiteHost;
  LanguagesEnum = LanguagesEnum;
  CurrencyEnum = CurrencyEnum;
  VenueStatusEnum = PublishStatusEnum;
  CountryCodeEnum = CountryCodeEnum;
  mapSettings = {
    zoom: 14,
    latitude: 32.0818702,
    longitude: 34.7805490,
  };
  searchControl: FormControl;
  venueId: number;
  editMode = false;
  loading = false;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  @ViewChild('agmMap', { static: false }) agmMap: AgmMap;
  @ViewChild('fileUploader', { static: false }) fileUploader: FileUploaderComponent;
  amenetiesList = this.venueEditService.amenetiesList;
  categories = this.venueEditService.venueCategoriesList;
  seatingOptions = this.venueEditService.seatingOptions;
  events = this.venueEditService.venueEventsList;
  venueGroup: VenueGroup[] = [];
  // navigationSubscription;

  // venue tree
  public tree: TreeModel;
  public treeSettings: Ng2TreeSettings = {
    rootIsVisible: true,
  };
  @ViewChild('treeComponent', { static: false }) treeComponent;
  selectedNode: TreeModel = null;
  model: any = {
    country: 'IL',
    searchVal: ''
  };
  tempArray: any[] = [];
  selectedRegion: any = {};
  selectedRegionName = '';
  regionModalRef: BsModalRef;
  selectedRegionId = undefined;
  pricePerHourForm: any = {};
  pricePerHourFormDefaultValues: any = {};
  pricePerSlotFormDefaultValues: any[] = [];
  pricePerPackage: any = [];
  priceByHourFormDirty: Boolean = false;
  generalPriceFormDirty: Boolean = false;
  priceBySlotFormDirty: Boolean = false;
  timeSlotPricing: any[] = [];
  currency = [];
  venueTimeSlotType = VenueTimeSlotType;
  hours = Hours;
  changeLanguageflag = false;
  isFormDataChanged = false;

  // file upload
  public kosherCertificateUploader: FileUploader = new FileUploader({
    isHTML5: true,
    maxFileSize: 10 * 1024 * 1024,
    queueLimit: 1,
  });

  public venueInsuranceUploader: FileUploader = new FileUploader({
    isHTML5: true,
    maxFileSize: 10 * 1024 * 1024,
    queueLimit: 1,
  });
  selectedKosherCertificateFile: any;
  selectedVenueInsuranceFile: any;
  showUploadSpinnerForKosherCertificate = false;
  showUploadSpinnerForInsuranceFile = false;

  constructor(
    private sanitizer: DomSanitizer,
    private venueEditService: VenueEditService,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService,
    private venueService: VenueService,
    private mapsAPILoader: MapsAPILoader,
    private alertify: AlertifyService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private router: Router,
    private errorhelper: Errorhelper,
    private modalService: BsModalService,
    private fileSize: FileSizePipe
  ) {
    this.currency = [{ value: CurrencyEnum.ILS, name: 'ILS' }, { value: CurrencyEnum.USD, name: 'USD' },
    { value: CurrencyEnum.GBP, name: 'GBP' }, { value: CurrencyEnum.EUR, name: 'EUR' }];
    // this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //   // If it is a NavigationEnd event re-initalise the component
    //   if (e instanceof NavigationEnd) {
    //     this.loadVenue();
    //   }
    // });

    this.venueId = this.activatedRoute.snapshot.params.id;
    this.editMode = !!this.venueId;
    this.searchControl = new FormControl();
    this.form = this.fb.group(this.venueEditService.venueFormGroup);
    this.seatingOptions.forEach((element) => {
      const form = new FormGroup({
        seatingOption: new FormControl(element.id),
        capacity: new FormControl(null),
        checkbox: new FormControl(false),
      });
      this.detailsFormArray.push(form);
    });
    console.log(this.form);
  }

  get formData() { return this.form.get('activityTimes'); }



  initMap() {
    this.mapsAPILoader.load().then(() => {
      this.agmMap.triggerResize();
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return this.alertify.error('Error while fetching google place id');
          }

          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          this.mapSettings.latitude = lat;
          this.mapSettings.longitude = lng;
          this.agmMap.triggerResize();

          const parsedAddress = this.locationService.parseGooglePlaceAddress(place.address_components);
          this.googleAddressChanged(parsedAddress, { lat, lng });
          this.setCityAndCountryByLang(lat, lng, this.venue.locale.substring(0, 2));
        });
      });
    });
  }

  googleAddressChanged(address: ParsedAddress, { lat, lng }) {
    console.log('googleAddressChanged: ', address);
    let fullAddress = '';
    if (address.street) {
      fullAddress = address.street;
      if (address.streetNumber) {
        fullAddress += ' ' + address.streetNumber;
      }
    } else if (address.neighborhood) {
      fullAddress = address.neighborhood;
    }
    if (address.city) {
      if (fullAddress.length > 0)
        fullAddress += ', ';
      fullAddress += address.city;
    }

    this.form.get('address').patchValue({
      lat: lat,
      lon: lng,
      // fulladdress: address.name || `${address.street} ${address.streetNum || ''}, ${address.city} `,
      cityEN: address.city,
      neighborhoodEN: address.neighborhoodEN,
      zip: address.zip,
      streetEN: address.street,
      country: address.countryShort,
      countryFullNameEN: address.country,
      // countryRegionId: ''
    });
    // this.selectedRegionName = '';

    this.form.get('address').markAsDirty();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadVenue();
      this.getVenueGroups();
      this.initMap();
    });
    this.kosherCertificateUploader.onAfterAddingFile = fileItem => {
      this.selectedKosherCertificateFile = fileItem._file;
      console.log(this.selectedKosherCertificateFile);
    };

    this.venueInsuranceUploader.onAfterAddingFile = fileItem => {
      this.selectedVenueInsuranceFile = fileItem._file;
    };
  }

  public removeKosherCertificate() {
    this.kosherCertificateUploader.queue = [];
    this.selectedKosherCertificateFile = undefined;
  }

  public removeVenueInsurance() {
    this.venueInsuranceUploader.queue = [];
    this.selectedVenueInsuranceFile = undefined;
  }

  public uploadKosherCertificateFile() {
    if (this.kosherCertificateUploader.queue.length > 0) {
      this.showUploadSpinnerForKosherCertificate = true;
      this.venueService.uploadKosherCertificate(this.venueId, this.kosherCertificateUploader.queue[0]).subscribe(
        response => {
          this.venue.kosherCertificateUrl = response.url;
          this.showUploadSpinnerForKosherCertificate = false;
        },
        error => {
          console.log('uploadInsuranceForVenue file upload error: ' + error);
          this.showUploadSpinnerForKosherCertificate = false;
          this.alertify.error(error);
        }
      );
    }
  }

  public uploadInsuranceFile() {
    if (this.venueInsuranceUploader.queue.length > 0) {
      this.showUploadSpinnerForInsuranceFile = true;
      this.venueService.uploadInsuranceForVenue(this.venueId, this.venueInsuranceUploader.queue[0]).subscribe(
        response => {
          this.venue.insuranceFileUrl = response.url;
          this.showUploadSpinnerForInsuranceFile = false;
        },
        error => {
          console.log('uploadInsuranceForVenue file upload error: ' + error);
          this.showUploadSpinnerForInsuranceFile = false;
          this.alertify.error(error);
        }
      );
    }
  }

  /**
   * dataGrid events, used to disable search on selectbox
   * @param e
   * @param eventName
   */
  dataGridEvents(e, eventName) {
    if (eventName === 'onEditorPreparing') {
      if (e.parentType === 'dataRow' && e.dataField === 'startTime' || e.dataField === 'endTime') {
        e.editorOptions.searchEnabled = false;
      }
    }
  }

  /**
   * save pricing tab changes
   * @param byHourOrBySlotOrGeneral
   */
  savePricingByHourOrGeneralOrPriceBySlot(byHourOrBySlotOrGeneral) {
    if (byHourOrBySlotOrGeneral === 'pricingBySlot') {
      setTimeout(() => {
        let items = [];
        const dataFromGrid: any = this.dataGrid.instance.getDataSource();
        if (this.venue.timeSlotsPricings.length > 0) {
          items = dataFromGrid._items;
        } else {
          items = dataFromGrid._items;
          items.map((res) => {
            if (res.uid) {
              delete res.uid;
            }
          });
        }
        this.venueService.updatePricingBySlot(this.venue.id, items).subscribe((res: any[]) => {
          this.priceBySlotFormDirty = false;
          this.timeSlotPricing = res;
          this.pricePerSlotFormDefaultValues = this.timeSlotPricing.map((timeSlot) => ({ ...timeSlot }));
          this.alertify.success('Venue time slots saved successfully.');
        }, (err) => {
          this.errorhelper.showServerErrors(err);
          if (this.venue.timeSlotsPricings.length === 0) {
            let num = 0;
            items.map((res) => {
              res.uid = num++;
            });
          }
        });
      }, 100);
    }
    if (byHourOrBySlotOrGeneral !== 'pricingBySlot') {
      const data = this.myForm.instance.option('formData');
      const body: PricingDetails = {
        pricePerHour_WithoutVAT: data.pricePerHour_WithoutVAT,
        currency: data.currency,
        minBookingHours: data.minBookingHours,
        commissionPercentage: data.commissionPercentage,
        halfDayPrice: data.halfDayPrice,
        fullDayPrice: data.fullDayPrice,
        extraDayPrice: data.extraDayPrice,
        refundPolicyOption: data.refundPolicyOption,
        refundPolicyOptionText: data.refundPolicyOptionText,
        refundPolicy: data.refundPolicy || null,
        termsAndConditions: data.termsAndConditions,
        priceCalculatorType: data.priceCalculatorType,
        discountLateStartTime: data.after === true ? data.discountLateStartTime : null,
        discountedLateHourRate: data.after === true ? data.discountedLateHourRate : null
      };
      if (!this.editMode) {
        body.id = 0;
      }
      this.venueService.updatePricing(this.venue.id, body).subscribe((res: any) => {
        this.pricePerHourForm = this.setPricePerHourFormValues(res);
        if (byHourOrBySlotOrGeneral === 'pricingByHour') {
          this.priceByHourFormDirty = false;
          this.alertify.success('hourly pricing saved successfully');
        }
        if (byHourOrBySlotOrGeneral === 'general') {
          this.generalPriceFormDirty = false;
          this.alertify.success('policy saved successfully');
        }
      }, (err) => {
        this.errorhelper.showServerErrors(err);
      });
    }
  }

  /**
   * discard price tab changes manually
   * @param byHourOrBySlotOrGeneral
   */
  discardPricingByHourOrGeneralOrPriceBySlot(byHourOrBySlotOrGeneral) {
    if (byHourOrBySlotOrGeneral === 'pricingBySlot') {
      this.priceBySlotFormDirty = false;
      this.timeSlotPricing = this.pricePerSlotFormDefaultValues.map(timeSlot => ({ ...timeSlot }));
      this.dataGrid.instance.refresh();
    }
    if (byHourOrBySlotOrGeneral === 'pricingByHour' || byHourOrBySlotOrGeneral === 'general') {
      setTimeout(() => {
        if (byHourOrBySlotOrGeneral === 'pricingByHour') {
          this.priceByHourFormDirty = false;
        }
        if (byHourOrBySlotOrGeneral === 'general') {
          this.generalPriceFormDirty = false;
        }
      }, 100);
      this.pricePerHourForm = { ...this.pricePerHourFormDefaultValues };
    }
  }

  /**
 * commission percentage form dirty manually
 * @param e
 * @param dataField
 */
  onCommissionPercentageChange(e, dataField) {
    if (e.previousValue !== undefined) {
      if (!e.previousValue) {
        this.pricePerHourForm[dataField] = e.value;
      }
      if (e.previousValue || e.previousValue === 0 || e.previousValue === null) {
        this.pricePerHourForm[dataField] = e.value;
      }
      this.generalPriceFormDirty = true;
    }
  }

  /**
   * price by hour form dirty manually
   * @param e
   * @param dataField
   */
  onPriceByHourValueChanged(e, dataField) {
    if (e.previousValue !== undefined) {
      if (dataField === 'after' && !e.previousValue) {
        this.pricePerHourForm[dataField] = e.value;
      }
      if (e.previousValue || e.previousValue === 0 || e.previousValue === null) {
        this.pricePerHourForm[dataField] = e.value;
      }
      if ((dataField !== 'after' && e.previousValue !== null)) {
        this.priceByHourFormDirty = true;
      }
    }
  }

  /**
   * price by slot form dirty manually
   * @param e
   * @param dataField
   */
  onPriceBySlotValueChanged(e, dataField, data) {
    this.priceBySlotFormDirty = true;
    let index: number;
    if (this.venue.timeSlotsPricings.length > 0) {
      index = this.timeSlotPricing.findIndex((res) => res.id === data.data.id);
    } else {
      index = this.timeSlotPricing.findIndex((res) => res.uid === data.data.uid);
    }
    this.timeSlotPricing[index][dataField] = e.value;
  }

  /**
   * set price per hour values every time user save or discards changes
   * @param pricingDetails
   */
  setPricePerHourFormValues(pricingDetails) {
    const pricePerHourFormValues: any = {};
    pricePerHourFormValues.id = pricingDetails.id;
    pricePerHourFormValues.pricePerHour_WithoutVAT = pricingDetails.pricePerHour_WithoutVAT;
    pricePerHourFormValues.currency = pricingDetails.currency;
    pricePerHourFormValues.commissionPercentage = pricingDetails.commissionPercentage;
    pricePerHourFormValues.minBookingHours = pricingDetails.minBookingHours;
    pricePerHourFormValues.halfDayPrice = pricingDetails.halfDayPrice;
    pricePerHourFormValues.fullDayPrice = pricingDetails.fullDayPrice;
    pricePerHourFormValues.extraDayPrice = pricingDetails.extraDayPrice;
    pricePerHourFormValues.refundPolicyOption = pricingDetails.refundPolicyOption;
    pricePerHourFormValues.refundPolicyOptionText = pricingDetails.refundPolicyOptionText;
    pricePerHourFormValues.refundPolicy = pricingDetails.refundPolicy || null;
    pricePerHourFormValues.termsAndConditions = pricingDetails.termsAndConditions;
    pricePerHourFormValues.priceCalculatorType = pricingDetails.priceCalculatorType;
    pricePerHourFormValues.discountLateStartTime = pricingDetails.discountLateStartTime;
    pricePerHourFormValues.discountedLateHourRate = pricingDetails.discountedLateHourRate || 0;
    if (pricePerHourFormValues.discountLateStartTime !== null) {
      pricePerHourFormValues.after = true;
    } else {
      pricePerHourFormValues.after = false;
    }
    pricePerHourFormValues.commissionPercentage = pricingDetails.commissionPercentage;
    this.pricePerHourFormDefaultValues = { ...pricePerHourFormValues };
    return pricePerHourFormValues;
  }

  venueServiceProviderDataGrid(eventName, e) {
    if (eventName === 'onToolbarPreparing') {
      const toolbarItems = e.toolbarOptions.items;
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'plus',
          stylingMode: 'contained',
          type: 'success',
          text: 'Add New Service Provider', onClick: () => {
            this.router.navigate(['/service-providers/edit'],
              {
                queryParams: {
                  realName: this.venue.realName + ' - Services',
                  countryCode: this.venue.country,
                  name: this.venue.name,
                  email: this.venue.email,
                  phone: this.venue.phoneNumber
                }
              }); // Approved Status = 2
          },
          elementAttr: {
            class: 'add-new-btn'
          },
        },
        location: 'after'
      });
      e.toolbarOptions.items.splice(0, 1);
    }
  }

  navigateToServiceProvider(id) {
    this.router.navigate([`service-providers/edit/${id}`]);
  }

  private getVenueGroups() {
    this.venueService.getVenueGroups().subscribe(
      group => {
        this.venueGroup = group;
      }
    )
  }

  public removeVenueFromGroup() {
    this.form.get('details').get('venueGroup').setValue(null);
    this.form.get('details').markAsDirty();
    this.form.markAsDirty();
  }

  loadVenue() {
    console.log('loading venue');

    if (this.venueId && this.editMode) {
      console.log('getting venue data from server');
      this.venueService.getVenue(this.venueId).subscribe((response) => {
        console.log('venue :', response);
        console.log(response.kosherCertificateUrl);
        console.log(response.insuranceFileUrl);

        // set selected VenueGroup
        if (response.venueGroup)
          this.form.get('details').get('venueGroup').setValue(response.venueGroup.id);

        this.venue = response;
        this.loadReviews();
        if (this.venue.country) {
          this.venueService.getCountryRegions(this.venue.country).subscribe(res => {
            this.tree = {
              value: this.venue.country,
              settings: {
                static: true
              }
            };
            this.prepareTreeData(res, this.tree);
            this.selectedRegionId = this.venue.countryRegionId;
            this.selectRegion(this.tree.children, this.selectedRegionId);
          }, () => {
            this.alertify.error('Unexpected error loading country regions for venue country: ' + this.venue.country);
          });
        }
        this.loadVenueForEdit(this.venue);
      }, () => {
        this.alertify.error('Unexpected error loading venue id: ' + this.venueId);
      });
    } else {
      if (this.activatedRoute.snapshot.queryParams.venueData) {
        try {
          const parsedData = JSON.parse(this.activatedRoute.snapshot.queryParams.venueData);
          // if (parsedData['details']['venueGroup']) {
          //   const vGroupId = parsedData['details']['venueGroup'];
          //   const selectedVenueGroup = this.venueGroup.find(x => x.id == vGroupId);
          //   this.form.get('details').get('venueGroup').setValue(selectedVenueGroup);
          // }

          console.log('parsedData: ', parsedData);
          this.form.patchValue(parsedData);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  //#region Review & Rating section
  public reviewInNewStatus = false;
  public reviewUpdateLoading = false;
  public currentReview = -1;
  public reviewStatusValues = [
    { value: "New", id: ReviewStatus.New },
    { value: "Approved", id: ReviewStatus.Approved },
    { value: "Declined", id: ReviewStatus.Declined },
  ];
  private loadReviews() {
    console.log('venue.reviews: ', this.venue.reviews);
    this.reviewInNewStatus = false;
    this.venue.reviews.forEach(review => {
      if (this.contains_heb(review.comments))
        review.rtl = true;
      if (review.reviewStatus == ReviewStatus.New)
        this.reviewInNewStatus = true;
    });
  }
  updateReviewStatus(review: Review, i: number) {
    this.reviewInNewStatus = false;
    this.currentReview = i;
    this.reviewUpdateLoading = true;
    this.venueService.updateReview(review).subscribe(
      _ => {
        this.reviewUpdateLoading = false;
        this.venue.reviews.forEach(review => {
          if (review.reviewStatus == ReviewStatus.New)
            this.reviewInNewStatus = true;
        })
      },
      error => {
        this.reviewUpdateLoading = false;
        console.error(error);
        this.alertify.error(error.error);
      }
    );
  }
  private contains_heb(str) {
    return (/[\u0590-\u05FF]/).test(str);
  }
  //#endregion Review & Rating section

  /** call google geo api for location name */
  setCityAndCountryByLang(lat, lng, locallang?) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat}
    ,${lng}&key=${environment.GOOGLE_API_KEY}&libraries=places&language=${locallang}`);
    const that = this;
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const result = JSON.parse(xhr.responseText);
        const resultComponent = result.results[0];
        console.log(resultComponent);
        const parsedadress = that.locationService.parseGooglePlaceAddress(resultComponent.address_components);
        console.log(parsedadress);
        that.form.get('address').patchValue({
          fulladdress: resultComponent.formatted_address,
          city: parsedadress.city,
          neighborhood: parsedadress.neighborhood,
          countryFullName: parsedadress.country,
          country: parsedadress.countryShort,
          street: parsedadress.streetEN,
          streetNumber: parsedadress.streetNumber,
          zip: parsedadress.zip
        });
        that.form.get('address').markAsDirty();
      }
    };
    xhr.send(null);
  }

  /**slect event listener , value = language  */
  languageSelect(value) {
    window.localStorage.setItem('gml', value);
    this.changeLanguageflag = true;
  }

  get getActivityTimesArray() {
    return this.form.get('activityTimes') as FormArray;
  }

  getActivityTime(activity?: ActivityTime) {
    return this.fb.group({
      day: activity ? Number(activity.day) : '',
      openTime: activity ? Number(activity.openTime) : 10,
      closeTime: activity ? Number(activity.closeTime) : 18
    });
  }

  get dayTimes() {
    const times = [];
    for (let i = 0; i <= 24; i++) {
      times.push(i);
      if (i < 24) // there is no hour 24:30...
        times.push(i + .5);
    }

    return times;
  }

  removeActivityTime(index: number) {
    this.getActivityTimesArray.removeAt(index);
    this.getActivityTimesArray.markAsDirty();
  }

  addActivityTime() {
    const prev = this.getActivityTimesArray.at(this.getActivityTimesArray.length - 1);
    if (prev) {
      let newDay = 0;
      if (Number(prev.get('day').value) < 6) {
        newDay = Number(prev.get('day').value) + 1;
      }

      this.getActivityTimesArray.push(this.getActivityTime({
        day: newDay,
        openTime: prev.get('openTime').value,
        closeTime: prev.get('closeTime').value,
      }));
    } else {
      this.getActivityTimesArray.push(this.getActivityTime({
        day: 1,
        openTime: 8,
        closeTime: 20,
      }));
    }
    this.form.get('activityTimes').markAsDirty();
  }

  get detailsFormArray(): FormArray {
    if (this.form) {
      return (<FormArray>this.form.get('details').get('seatingDetails'));
    }

  }

  loadVenueForEdit(venue: Venue) {
    this.form.get('details').patchValue({
      extraServicesText: venue.extraServicesText,
      roomSizeSqaureMeters: venue.roomSizeSqaureMeters,
      comments: venue.comments,
      status: venue.status,
      cateringDetails: venue.cateringDetails,
      cateringOptions: venue.cateringOptions,
      name: venue.name,
      email: venue.email,
      about: venue.about,
      realName: venue.realName,
      popularityIndex: venue.popularityIndex,
      phoneNumber: venue.phoneNumber,
      locale: venue.locale,
      contactPersonName: venue.contactPersonName,
      contactNameForCustomers: venue.contactNameForCustomers,
      emailForCustomers: venue.contactEmailForCustomers,
      phoneNumberForcustomers: venue.contactPhoneForCustomers,
      adminNotes: venue.adminNotes,
      externalTermsUrl: venue.externalTermsUrl
    });
    // if ( !venue.cityEN ||  !venue.countryFullNameEN || !venue.street || !venue.streetEN || !venue.streetNumber) {
    // this.setCityAndCountryByLang(venue.lat , venue.lon);
    // this.submit(this.form.value);
    // }
    this.form.get('address').patchValue({
      fulladdress: venue.fullAddress,
      lat: venue.lat,
      lon: venue.lon,
      city: venue.city,
      cityEN: venue.cityEN ? venue.cityEN : '',
      neighborhood: venue.neighborhood ? venue.neighborhood : '',
      neighborhoodEN: venue.neighborhoodEN ? venue.neighborhoodEN : '',
      streetEN: venue.streetEN ? venue.streetEN : '',
      street: venue.street ? venue.street : '',
      streetNumber: venue.streetNumber ? venue.streetNumber : '',
      zip: venue.zip ? venue.zip : '',
      country: venue.country,
      countryFullName: venue.countryFullName,
      countryFullNameEN: venue.countryFullNameEN ? venue.countryFullNameEN : '',
      directions: venue.directions,
      directionsToDisplay: venue.directionsToDisplay,
      countryRegionId: venue.countryRegionId,
      navigationLink: venue.navigationLink
    });

    if (venue.pricingDetails) {
      this.pricePerHourForm = this.setPricePerHourFormValues(venue.pricingDetails);
    } else {
      const pricePerHourFormValues: any = {};
      pricePerHourFormValues.id = 0;
      pricePerHourFormValues.pricePerHour_WithoutVAT = 0;
      pricePerHourFormValues.currency = 0;
      pricePerHourFormValues.commissionPercentage = 0;
      pricePerHourFormValues.minBookingHours = 1;
      pricePerHourFormValues.halfDayPrice = 0;
      pricePerHourFormValues.fullDayPrice = 0;
      pricePerHourFormValues.extraDayPrice = 0;
      pricePerHourFormValues.refundPolicyOption = 1;
      pricePerHourFormValues.refundPolicyOptionText = undefined;
      pricePerHourFormValues.refundPolicy = undefined;
      pricePerHourFormValues.termsAndConditions = undefined;
      pricePerHourFormValues.priceCalculatorType = 0;
      pricePerHourFormValues.discountLateStartTime = 16;
      pricePerHourFormValues.discountedLateHourRate = 0;
      pricePerHourFormValues.after = false;
      this.pricePerHourForm = pricePerHourFormValues;
      this.pricePerHourForm.commissionPercentage = 0;
      this.pricePerHourFormDefaultValues = { ...pricePerHourFormValues };
    }

    if (venue.timeSlotsPricings.length > 0) {
      this.timeSlotPricing = venue.timeSlotsPricings;
      this.pricePerSlotFormDefaultValues = this.timeSlotPricing.map((timeSlot) => ({ ...timeSlot }));
    } else {
      const defaultTimeSlotValues = [
        { uid: 1, id: null, relatedVenueId: Number(this.venueId), type: 3, startTime: 8, endTime: 12, isEnabled: false, price: 0 },
        { uid: 2, id: null, relatedVenueId: Number(this.venueId), type: 4, startTime: 12.5, endTime: 17, isEnabled: false, price: 0 },
        { uid: 3, id: null, relatedVenueId: Number(this.venueId), type: 5, startTime: 17.5, endTime: 20, isEnabled: false, price: 0 },
        { uid: 4, id: null, relatedVenueId: Number(this.venueId), type: 6, startTime: 8, endTime: 17, isEnabled: false, price: 0 }
      ];
      this.timeSlotPricing = defaultTimeSlotValues;
      this.pricePerSlotFormDefaultValues = this.timeSlotPricing.map((timeSlot) => ({ ...timeSlot }));
    }

    if (venue.lon) {
      this.mapSettings.longitude = venue.lon;
      this.mapSettings.latitude = venue.lat;
    }

    if (venue.activityTimes) {
      // make sure activity times array is empty
      // for (let index = 0; index < this.getActivityTimesArray.length; index++) {
      //   this.removeActivityTime(index);
      // }
      this.clearFormArray(this.getActivityTimesArray);
      for (const activity of venue.activityTimes) {
        this.getActivityTimesArray.push(this.getActivityTime(activity));
      }
    }


    if (venue.amenitiesList) {
      venue.amenitiesList.forEach((i) => {
        const found = this.amenetiesList.find((cat) => cat.id === i);

        if (found) {
          const foundIndex = this.amenetiesList.findIndex(cat => found.id === cat.id);
          (this.form.get('details').get('ameneties') as FormArray).at(foundIndex).setValue(true);
        }
      });
    }

    if (venue.categoriesList) {
      venue.categoriesList.forEach((i) => {
        const found = this.categories.find((cat) => cat.id === i);

        if (found) {
          const foundIndex = this.categories.findIndex(cat => found.id === cat.id);
          (this.form.get('details').get('categories') as FormArray).at(foundIndex).setValue(true);
        }
      });
    }

    if (venue.eventsList) {
      venue.eventsList.forEach((i) => {
        const found = this.events.find((cat) => cat.id === i);

        if (found) {
          const foundIndex = this.events.findIndex(cat => found.id === cat.id);
          (this.form.get('details').get('events') as FormArray).at(foundIndex).setValue(true);
        }
      });
    }

    if (venue.seatingDetails) {
      venue.seatingDetails.forEach((i) => {
        const found = this.seatingOptions.find((cat) => cat.id === i.seatingOption); // seatingOption = id.
        if (found) {
          const foundIndex = this.seatingOptions.findIndex(cat => found.id === cat.id);
          this.detailsFormArray.controls[foundIndex].patchValue({
            seatingOption: i.seatingOption,
            capacity: i.capacity
          });
        }
      });
    }
    this.detailsFormArray.controls.forEach(element => {
      if (element.get('capacity').value === null) {
        element.get('capacity').disable();
      }
    });
  }

  clearQuantityIfNecessary(i, formIndex) {
    if (!this.editMode) {
      const checkValue = this.detailsFormArray.controls[formIndex].get('checkbox').value;
      if (!checkValue) {
        this.detailsFormArray.controls[formIndex].get('checkbox').setValue(true);
      } else {
        this.detailsFormArray.controls[formIndex].get('checkbox').setValue(false);
      }
    }

    if (this.venue && this.venue.seatingDetails) {
      // this.detailsFormArray.controls[formIndex].get('capacity').disable();
      const index = this.venue.seatingDetails.findIndex((res) => res.seatingOption === i);
      if (index >= 0) {
        this.venue.seatingDetails.splice(index, 1);
        this.detailsFormArray.controls[formIndex].get('capacity').disable();
      } else {
        this.detailsFormArray.controls[formIndex].get('capacity').enable();
        this.venue.seatingDetails.push({ seatingOption: i, capacity: 0 });
      }
      this.detailsFormArray.markAsDirty();
    }
  }

  capacityValueChanged(seatingOption, capacity) {
    if (this.venue && this.venue.seatingDetails) {
      const index = this.venue.seatingDetails.findIndex((res) => res.seatingOption === seatingOption);
      this.venue.seatingDetails[index].capacity = capacity;
    }
  }

  checkboxEnable(seatingOption) {
    const getData = seatingOption.controls.seatingOption.value;
    if (this.venue && this.venue.seatingDetails) {
      const index = this.venue.seatingDetails.findIndex((res) => res.seatingOption === getData);
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  async submit(data: VenueEditForm) {
    console.log(data);
    const getData = (this.detailsFormArray as FormArray).getRawValue();
    const filteredData = getData.filter((res) => res.checkbox);
    filteredData.map((res) => {
      delete res.checkbox;
    });
    const seatingDetails = !this.editMode ? filteredData : this.venue.seatingDetails;
    const venueData = this.venueEditService.prepareVenueEditDTO(data, seatingDetails);
    console.log('venue data on submit: ', venueData);
    const error = this.validateVenueOnSave(data);
    if (error) return this.alertify.error(error);

    this.loading = true;
    this.blockUI.start('Saving...'); // Start blocking
    if (this.editMode) {
      this.saveTiers.saveAllTiers();
    }
    if (this.priceByHourFormDirty) {
      this.savePricingByHourOrGeneralOrPriceBySlot('pricingByHour');
    }
    if (this.priceBySlotFormDirty) {
      this.savePricingByHourOrGeneralOrPriceBySlot('pricingBySlot');
    }
    if (this.generalPriceFormDirty) {
      this.savePricingByHourOrGeneralOrPriceBySlot('general');
    }
    try {
      if (this.form.get('details').dirty || this.form.get('address').dirty) {
        if (this.editMode) {
          console.log('venue data on submit: ', venueData);
          await this.venueService.updateVenue(this.venueId, venueData).toPromise();
        } else {
          this.venue = await this.venueService.createVenue(venueData).toPromise();
        }
      }

      if (this.form.get('activityTimes').dirty) {
        await this.updateActivityTimes(data.activityTimes);
      }

      if (this.form.get('pricing').dirty) {
        await this.updatePricing(data.pricing);
      }

      if (this.fileUploader.uploader.queue) {
        await this.uploadImages(this.fileUploader.uploader.queue)
          .catch(err => {
            throw err;
          });
      }

      this.form.markAsPristine();
      this.venue = await this.venueService.getVenue(this.venue.id).toPromise();
      if (!this.priceByHourFormDirty && !this.priceBySlotFormDirty && !this.generalPriceFormDirty) {
        this.savePricingByHourOrGeneralOrPriceBySlot('');
      }
      this.alertify.success('Successfully updated');
      this.router.navigate(['/venues/edit/' + this.venue.id]);
    } catch (err) {
      console.log('err :', err);
      if (err)
        this.alertify.error(err);
      else
        this.alertify.error('Error while updating entity');
    } finally {
      this.blockUI.stop();
      this.loading = false;
      if (this.changeLanguageflag) {
        //  window.location.reload();
      }
    }
  }

  validateVenueOnSave(data: VenueEditForm): string {
    console.log('validate save');
    if (!data.details.name) return 'Venue name is required';
    if (!data.details.realName) return 'Real name is required';

    return;
  }

  validateVenueOnPublish(venue: Venue): string {
    console.log(venue);
    if (!venue.activityTimes.length) return 'Activity times are not set.';
    if (!venue.categories) return 'Categories are required';
    if (!venue.events) return 'Events are required';
    if (!venue.amenitiesList || venue.amenitiesList.length === 0) return 'Amenities are required';
    if (!venue.phoneNumber) return 'Phone number is required';
    if (!venue.email) return 'Email is required';
    if (!venue.directionsToDisplay) return 'Directions To Display must be specified';
    if (!venue.directionsToDisplay) return 'Navgation link must be specified';
    const photoQueueLength = this.fileUploader.uploader.queue.length;
    if (!this.venue && photoQueueLength < 3) {
      return 'Please provide at least 3 photos';
    }

    if (this.venue && (this.venue.photos.length + photoQueueLength) < 3) {
      return 'Please provide at least 3 images';
    }

    return;
  }

  async updatePricing(data: PricingDetails) {
    if (!data.id) delete data.id;

    await this.venueService.updatePricing(this.venue.id, data).toPromise();
  }

  async updateActivityTimes(data: ActivityTime[]) {
    await this.venueService.setActivityTimes(this.venue.id, data).toPromise();
  }

  async uploadImages(files: FileItem[]) {
    if (!files || !files.length) return;

    for (const file of files) {
      console.log('file :', file);
      if (file.file.size > 10000000)
        throw new Error(`Image '${file.file.name.substr(0, 25)}' is too large - ${this.fileSize.transform(file.file.size)}`);
    }

    const imageObservers = [];
    for (const file of files) {
      imageObservers.push(this.venueService.uploadVenueImage(this.venue.id, file));
    }

    const response = await zip(...imageObservers).toPromise();
    this.venue.photos.push(...response as any);
    this.fileUploader.uploader.clearQueue();

    const foundMainImage = this.venue.photos.find((i) => {
      return i.isMain;
    });

    if (foundMainImage) {
      this.venue.mainPhotoUrl = foundMainImage.url;
    }
  }

  makeDefaultImage(id: number) {
    this.venueService.setMainPhoto(this.venue.id, id).subscribe(() => {
      this.venue.photos.forEach((i) => {
        i.isMain = false;

        if (id === i.id) {
          this.venue.mainPhotoUrl = i.url;
          i.isMain = true;
        }
      });
    });
  }

  async unpublishVenue() {
    if (this.form.dirty) {
      await this.submit(this.form.value);
    }
    this.venueService.unpublishVenue(this.venue.id).subscribe(() => {
      this.venue.status = PublishStatusEnum.Draft;
      this.alertify.success('Un-published successfully');
      // this.router.navigate(['/venues/edit/' + this.venue.id]);
      this.loadVenueForEdit(this.venue);
    }, (error: Error | any) => {
      console.log(error);
      this.alertify.error('Error while un-publishing');
    });
  }

  async publishVenue() {
    if (this.form.dirty) {
      await this.submit(this.form.value);
    }
    const error = this.validateVenueOnPublish(this.venue);
    if (error) return this.alertify.error(error);
    this.venueService.publishVenue(this.venue.id).subscribe(() => {
      this.venue.status = PublishStatusEnum.Published;
      this.alertify.success('Published successfully');
      // this.router.navigate(['/venues/edit/' + this.venue.id]);
      this.loadVenueForEdit(this.venue);
    }, (err: Error | any) => {
      console.log('publish Venue error: ', err);
      this.errorhelper.showServerErrors(err);
      // this.alertify.error('Error while publishing');
    });
  }

  async deleteVenue() {
    this.venueService.deleteVenue(this.venue.id).subscribe(() => {
      this.router.navigate(['/venues/']);
      this.alertify.success('Venue deleted successfully');
    }, () => {
      this.alertify.error('Error while deleting');
    });
  }

  async archiveVenue() {
    this.venueService.archiveVenue(this.venue.id).subscribe(() => {
      this.router.navigate(['/venues/']);
      this.alertify.success('Venue archived successfully');
    }, () => {
      this.alertify.error('Error while archiving');
    });
  }

  onTabSelected() {
    this.agmMap.triggerResize();
    console.log(this.agmMap);
  }

  removeImage(image: Photo) {
    this.blockUI.start('Loading...'); // Start blocking

    this.venueService.removeVenueImage(this.venue.id, image.id).subscribe(() => {
      const index = this.venue.photos.indexOf(image);
      this.venue.photos.splice(index, 1);
      this.blockUI.stop(); // Stop blocking
    }, () => {
      this.alertify.error('Error while removing image');
      this.blockUI.stop(); // Stop blocking
    });
  }

  secureWordpressUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  copyVenue() {
    const data = JSON.stringify(this.form.value);
    const sanitizedData = JSON.parse(data);
    if (sanitizedData.pricing) {
      delete sanitizedData.pricing.id;
      sanitizedData.pricing.pricePerHour_WithoutVAT = null;
      sanitizedData.pricing.halfDayPrice = null;
      sanitizedData.pricing.fullDayPrice = null;
      sanitizedData.pricing.extraDayPrice = null;
    }
    if (sanitizedData.details) {
      sanitizedData.details.name = '';
    }


    this.router.navigate(['/venues/edit'], {
      queryParams: {
        venueData: JSON.stringify(sanitizedData)
      }
    });
  }

  /**
   * Download Image from URL
   * @param url
   */
  downloadImage(url) {

    this.getBase64Image(url, function (base64image) {

      const byteCharacters = atob(base64image);
      const index = url.lastIndexOf('/') + 1;
      const filename = url.substr(index);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { 'type': 'image/jpeg' });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.setAttribute('visibility', 'hidden');
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });

  }

  /**
   *  Convert Image Url into Base64
   * @param imgUrl
   * @param callback
   * @returns Base64 image string
   */
  public getBase64Image(imgUrl, callback) {

    const img = new Image();

    img.onload = function () {

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL('image/png');
      dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');

      callback(dataURL); // the base64 string

    };

    // set attributes and src
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = imgUrl;

  }

  handleSelected(event: NodeSelectedEvent) {
    this.selectedNode = null;
    if (event.node.node.value !== 'gb' && event.node.node.value !== 'il') {
      this.selectedRegion = event.node.node;
      console.log(event.node);
    }
  }

  setRegion() {
    this.regionModalRef.hide();

    this.selectedRegionName = this.selectedRegion.value;
    this.form.get('address').patchValue({
      countryRegionId: this.selectedRegion.id,
    });
    this.form.get('address').markAsDirty();

  }

  resetRegion() {
    this.regionModalRef.hide();
    this.form.get('address').patchValue({
      countryRegionId: '',
    });
    this.form.get('address').markAsDirty();
    this.selectedRegion = {};
    this.selectedRegionName = '';
  }

  prepareTreeData(regions: any[], parentNode: TreeModel) {
    const children = [];
    for (const child of regions) {

      let include = false;
      if (this.model.searchVal.length > 0) {
        include = child.regionName.toLowerCase().indexOf(this.model.searchVal.toLowerCase()) > -1;
      } else {
        include = true;
      }

      if (include === true) {
        children.push({ id: child.id, value: child.regionName });
        this.prepareTreeData(child.childRegions, children[children.length - 1]);
      }
    }

    parentNode.children = children;
  }

  onCollapseClick() {
    this.performCollapsed(this.tree);

    for (const parentNode of this.tempArray) {
      let instance = null;
      if (parentNode !== 'GB' && parentNode !== 'IL') {
        instance = this.getTreeNodeInstance(parentNode);
      } else {
        instance = this.treeComponent.getController();
      }

      if (instance != null) {
        instance.collapse();
      }
    }
  }

  onExpandClick() {
    this.performExpand(this.tree);
  }

  /**
  * Set Selected = true on which region user clicked else Set selected = false
  * @param childRegions
  * @param node
  */
  selectRegion(childRegions, id) {
    if (childRegions !== undefined && id !== undefined) {
      childRegions.forEach(n => {
        if (n.children.length > 0) {
          this.selectRegion(n.children, id);
        }
        n.selected = false;
        if (id === n.id) {
          console.log('selected node', n);
          this.selectedRegionName = n.value;
        }
      });
    }

  }

  onCreateClick() {

    const newNode: TreeModel = {
      id: null,
      value: '',
      children: []
    };

    if (this.selectedNode) {
      const treeNode = this.getTreeNodeInstance(this.selectedNode.id);
      if (treeNode != null) {
        treeNode.addChild(newNode);
        return;
      }
    }

    this.treeComponent.tree.createNode(true, newNode);
  }

  performExpand(treeNode: TreeModel) {
    if (treeNode.id) {
      let instance = null;
      if (treeNode.id !== 'GB' && treeNode.id !== 'IL') {
        instance = this.getTreeNodeInstance(treeNode.id);
      } else {
        instance = this.treeComponent.getController();
      }

      if (instance != null) {
        instance.expand();
      }

      if (treeNode.children !== undefined && treeNode.children.length > 0) {
        for (const child of treeNode.children) {
          setTimeout(() => {
            this.performExpand(child);
          }, 50);
        }
      }
    }
  }

  performCollapsed(treeNode: TreeModel) {
    if (treeNode.id) {
      if (treeNode.children !== undefined && treeNode.children.length > 0) {
        this.tempArray.push(treeNode.id);
        for (const child of treeNode.children) {
          this.performCollapsed(child);
        }
      }
    }
  }

  getTreeNodeInstance(id: string | number) {
    return this.treeComponent.getControllerByNodeId(id);
  }

  preventDraggable() {
    const dragElements = document.querySelectorAll('div[draggable]');
    for (let i = 0; i < dragElements.length; i++) {
      dragElements[i].addEventListener('dragstart', function (e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      });
    }
  }

  openModal(template: TemplateRef<any>) {
    console.log(template);
    console.log('log', this.form.get('address').value.country);
    if (this.form.get('address').value.country !== '') {
      this.venueService.getCountryRegions(this.form.get('address').value.country).subscribe(res => {
        console.log('data loaded', res);

        this.tree = {
          value: this.form.get('address').value.country,
          id: 10,
          settings: {
            static: true
          }
        };
        this.prepareTreeData(res, this.tree);
        this.regionModalRef = this.modalService.show(template);

      }, () => {

      });
    }
  }
}
