import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SeoService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  /**get seo data */
  getSeoData() {
    return this.http.get(this.baseUrl + 'LocationSEO/list');
  }
  /**Delete seo data */
  deleteSeoData(dataId) {
    return this.http.delete(this.baseUrl + 'LocationSEO/delete/' + dataId);
  }
  /**upsert seo data */
  upsertSeoData(method, seoDetials) {
    if (method === 'Add') {
      const body = {
        country: seoDetials.country,
        city: seoDetials.city,
        neighberhood: seoDetials.neighberhood,
        seoContent: seoDetials.seoContent
      };
      return this.http.post(this.baseUrl + 'LocationSEO/upsert', body);
    } else {
      const body = {
        id: seoDetials.id,
        country: seoDetials.country,
        city: seoDetials.city,
        neighberhood: seoDetials.neighberhood,
        seoContent: seoDetials.seoContent
      };
      return this.http.post(this.baseUrl + 'LocationSEO/upsert', body);
    }
  }

}
