import { Photo } from './Photo';
import { CurrencyEnum, LanguagesEnum, PublishStatusEnum } from './general.interface';
import { Review } from './review.model';

export interface ActivityTime {
  day: number;
  openTime: number;
  closeTime: number;
}

export interface SeatingDetails {
  seatingOption: VenueSeatingOptionEnum;
  capacity: number;
}

export interface TimeSlotPricing {
  endTime: number;
  id: number;
  isEnabled: boolean;
  price: number;
  relatedVenueId: number;
  startTime: number;
  type: number;
}

export interface PricingDetails {
  id?: number;
  refundPolicyOption: VenueRefundPolicyOptionEnum;
  refundPolicyOptionText: string;
  currency: CurrencyEnum;
  extraDayPrice: number;
  fullDayPrice: number;
  halfDayPrice: number;
  minBookingHours: number;
  commissionPercentage: number;
  pricePerHour_WithoutVAT: number;
  termsAndConditions: string;
  priceCalculatorType: PriceCalculatorTypes;
  rowVersion?: number;
  refundPolicy?: string;
  discountLateStartTime?: number;
  discountedLateHourRate?: number;
}

export enum AmenityItemEnum {
  None = 0,
  Accessibility = 1,
  AirConditioning = 2,
  MeetingTable = 4,
  Chairs = 8,
  HotDrinks = 16,
  ColdWater = 32,
  Cookies = 64,
  Kitchen = 128,
  OutdoorSeatings = 256,
  FreeParking = 512,
  Projector = 1024,
  Screen = 2048,
  Shade = 4096,
  SmokingArea = 8192,
  AVEquipments = 16384,
  Toilet = 32768,
  TV = 65536,
  VCSystem = 131072,
  WhiteBoard = 262144,
  Wifi = 524288,
  Windows = 1048576,
  PaidParking = 2097152,
  FlipChart = 4194304,
  Daylight = 8388608,
  ClickShare = 16777216,
  NoDaylight = 33554432,
}

export enum EVENT_TYPE {
  All = 0,
  Offsite = 1,
  BoardMeeting = 2,
  Conference = 4,
  TrainingDay = 8,
  Workshop = 16,
  Meal = 32,
  TeamOffsite = 64,
  Toast = 128,
  CompanyEvent = 256,
  Birthday = 512,
  FamilyCelebration = 1024,
  PrivateEvent = 2048,
  DeskPerDay = 4096,
  FunDays = 8192
}

export enum VenueCategoryEnum {
  All = 0,
  InspirationalMeetings = 1,
  FormalMeetings = 2,
  ConferencesAndEvents = 4,
  MeetAndEat = 8,
  FormalBoardroom = 16,
  ModernMeetingRoom = 32,
  CreativeSpace = 64,
  HotelMeetingRoom = 128,
  RestaurantPrivateRoom = 256,
  LoftGallerySalon = 512,
  EventSpace = 1024,
  Classroom = 2048,
  HighEnd = 4096,
  MeetingSpaceOvernight = 8192
}

export function toValues(sum) {
  const values: number[] = [];
  for (let type in VenueCategoryEnum) {
    if (typeof (VenueCategoryEnum[type]) === 'number') {
      let numType = Number(VenueCategoryEnum[type]);
      const bit = numType & sum;
      if (bit > 0) {
        values.push(numType);
      }
      numType ^= bit;
    }
  }
  return values;
}

export enum VenueSeatingOptionEnum {
  Other = 0,
  BoardRoom = 1,
  Theatre = 2,
  Circle = 4,
  UShaped = 8,
  Classroom = 16,
  SofaSeating = 32,
  Cabaret = 64,
  Standing = 128
}

export enum VenueCateringOptions {
  Internal = 0,
  External = 1,
  Both = 2,
  None = 3
}

export enum VenueRefundPolicyOptionEnum {
  UpTo_3Days_FullRefund = 0,
  UpTo_7Days_FullRefund = 1,
  UpTo_7Days_HalfRefund = 2,
  Other = 3
}
export enum PriceCalculatorTypes {
  ChargeExtraHours = 0,
  NoChargeForExtraHours = 1
}

export interface Venue {
  // description
  id?: number;
  locale: LanguagesEnum;
  name: string;
  about: string;
  cateringOptions: VenueCateringOptions;
  cateringDetails: string;
  realName: string;
  popularityIndex: number;
  phoneNumber: string;
  email: string;
  contactPersonName: string;
  directions: string;
  directionsToDisplay: string;
  created?: string;
  createdBy?: any;
  status?: PublishStatusEnum;
  contactNameForCustomers: string;
  contactPhoneForCustomers: string;
  contactEmailForCustomers: string;
  commissionPercentage?: number;

  pricingDetails?: PricingDetails;

  // venue details
  categoriesList?: VenueCategoryEnum[];
  categories?: VenueCategoryEnum[];
  eventsList?: EVENT_TYPE[];
  events?: EVENT_TYPE[];
  seatingOptionsList: VenueSeatingOptionEnum[];
  seatingDetails?: SeatingDetails[];

  amenitiesList?: AmenityItemEnum[];
  amenities?: number;
  seatingOptions?: number;
  roomSizeSqaureMeters?: number;
  openingTimesText?: string;
  extraServicesText?: string;
  isSmokingAllowed?: boolean;
  isExternalFoodAllowed?: boolean;
  isAccessible?: boolean;
  comments?: string;
  adminNotes?: string;
  externalTermsUrl?: string;

  // location
  lat: number;
  lon: number;
  distance?: number;
  fullAddress: string;
  country: string;
  countryen?: string
  countryFullName: string;
  countryFullNameEN?: string;
  street?: string;
  streetNumber?: string;
  streetEN?: string;
  zip?: string;
  city: string;
  cityEN?: string;
  neighborhoodEN?: string;
  neighborhood?: string
  countryRegionId: number;
  navigationLink: string;

  // photos
  photos?: Photo[];
  photoUrl?: string;
  mainPhotoUrl?: string;
  kosherCertificateUrl?: string;
  insuranceFileUrl?: string;

  // wordpress
  wordPressPageUrl?: string;
  importedWordPressHtml_EN?: string;
  importedWordPressHtml_HE?: string;

  activityTimes?: ActivityTime[];
  timeSlotsPricings?: TimeSlotPricing[];

  ratingAvg?: number;
  locationRatingAvg: number;
  meetingPurposeRatingAvg: number;
  serviceRatingAvg: number;
  reviews: Review[];
  venueAvgRating: VenueAvgRating;
  venueGroup?: VenueGroup;
  newVenueGroup?: string;
}

export interface VenueAvgRating {
  meetingPurposeAvgRating: number;
  serviceAvgRating: number;
  locationAvgRating: number;
}

export interface VenueGroup {
  id: number,
  groupName: string
}
