import { CurrencyEnum } from "../models/Booking";
import { User } from './User';

export interface ServiceProvider {
    name: string;
    position: number;
    weight: number;
    symbol: string;
    id: number;
    status: number;
    type: number;
}

export enum ServiceProviderStatusEnum {
    Draft = 1,
    Published = 2
}

export enum ServiceProviderTypeEnum {
    SelfProvider = 0,
    Regular = 1
}

export const ServiceProviderType = ['Self Provider', 'Regular'];

export enum CountryCodeEnum {
    IL = 'il',
    GB = 'gb',
    NL = 'nl',
    BE = 'be',
    DE = 'de',
    US = 'us',
    CN = 'cn',
}

// tslint:disable-next-line:no-unused-expression
export enum ServiceTypeEnum {
    Catering = 0,
    GiveAways = 1,
    Content = 2,
    Services = 3
}

export const ServiceType = ['Catering', 'Give Aways', 'Content', 'Services'];


// tslint:disable-next-line:no-unused-expression
export enum ServiceTypeSubEnum {
    Breakfast = 0,
    Lunch = 1,
    Snacks = 2,
    Gifts = 3,
    Lecture = 4,
    Workshop = 5,
    Drinks = 6,
    Amenities = 7,
    General = 8,
    Dinner = 9,
}

export const ServiceSubType = ['Breakfast', 'Lunch', 'Snacks', 'Gifts', 'Lecture', 'Workshop', 'Drinks', 'Amenities', 'General', 'Dinner'];

export enum PricingTypeEnum {
    PerPerson = 0,
    PerUnit = 1,
    PerHour = 2,
    Fixed = 3
}

export const PricingType = ['Per Person', 'Per Unit', 'Per Hour', 'Fixed'];


export enum ServiceInventoryFlags {
    Kosher = 1,
    Vegan = 2,
    Vegetarian = 4,
    NutFree = 8,
    DairyFree = 16,
    GlutenFree = 32
}

export enum ServiceInventoryPackageType {
    One2Five = 1,
    Five2Ten,
    Ten2Fifteen,
    Fifteen2Tweny,
    Tweny2TwenyFive,
    TwenyFive2Fifty
}

export const ServiceInventoryPackageTypeList = [
    {
        id: ServiceInventoryPackageType.One2Five,
        name: '1-5 Guests'
    },
    {
        id: ServiceInventoryPackageType.Five2Ten,
        name: '5-10 Guests'
    },
    {
        id: ServiceInventoryPackageType.Ten2Fifteen,
        name: '10-15 Guests'
    },
    {
        id: ServiceInventoryPackageType.Fifteen2Tweny,
        name: '15-20 Guests'
    },
    {
        id: ServiceInventoryPackageType.Tweny2TwenyFive,
        name: '20-25 Guests'
    },
    {
        id: ServiceInventoryPackageType.TwenyFive2Fifty,
        name: '25-50 Guests'
    }
]

export const InventoryFlagList = [
    {
        id: ServiceInventoryFlags.Kosher,
        Name: 'Kosher',
    },
    {
        id: ServiceInventoryFlags.Vegan,
        Name: 'Vegan',
    },
    {
        id: ServiceInventoryFlags.Vegetarian,
        Name: 'Vegetarian',
    },
    {
        id: ServiceInventoryFlags.NutFree,
        Name: 'Nut Free',
    },
    {
        id: ServiceInventoryFlags.DairyFree,
        Name: 'Dairy Free',
    },
    {
        id: ServiceInventoryFlags.GlutenFree,
        Name: 'Gluten Free',
    }
];

export enum InventoryStatusEnum {
    Draft = 1,
    Published = 2
}

export enum DeliveryOptionEnum {
    NoDelivery = 0,
    Delivery = 1,
    OverrideWithParent = 2,
}

export interface ServiceProviderInventory {
    serviceProviderId: number;
    relatedServiceProvider: ServiceProvider;
    serviceType: ServiceTypeEnum;
    serviceSubType?: ServiceTypeSubEnum;
    name: string;
    pricingType: PricingTypeEnum;
    price: number;
    currency: CurrencyEnum;
    status: PublishStatus;
    details?: string;
    minOrder?: number;
    minPeople?: number;
    maxPeople?: number;
    mainImageId?: number;

    mainImage: MediaAsset;
    flags: ServiceInventoryFlags;
    displayOrder: number;
    relatedCategoryId?: number;
    relatedCategory: ServiceProviderInventoryCategory;
    inventoryCateringPrice?: ServiceProviderInventoryCateringPrice[];
    inventoryPackageType?: ServiceInventoryPackageType;
}

export class ServiceProviderInventoryCategory
{
    relatedProviderId: number;
    relatedProvider: ServiceProvider;
    name: string;
}

export class ServiceProviderInventoryCateringPrice
{
    id: number;
    serviceProviderId: number;
    relatedServiceProvider: ServiceProvider;
    pricingType: PricingTypeEnum;
    price: number;
    currency: CurrencyEnum;
    status: PublishStatus;
    minOrder?: number;
    flags: ServiceInventoryFlags;
}

export interface MediaAsset {
    id: number;
    url: string;
    description: string;
    dateAdded: Date;
    isMain: boolean;
    publicId: string;
    venueId?: number;
    photoType: AssetType;
}

export class ServiceProviderRegion
{
    serviceProviderId: number;
    relatedServiceProvider: ServiceProvider;
    countryRegionId: number;
    relatedCountryRegion: CountryRegion;
    deliveryCost: number;
    currency: CurrencyEnum;
    isDeliveryAvailable: boolean;
    regionName: string;
}

export class CountryRegion
{
    id?: number;
    countryCode: string;
    regionName: string;
    parentRegion: CountryRegion;
    parentRegionId?: number;
    childRegions: CountryRegion[];
}

export enum AssetType {
    VenuePhoto = 1,
    KosherCertificateAsset,
    MenuAsset,
    Insurance,
    ServiceProviderAsset,
}

export enum PublishStatus {
    Draft = 1,
    Published = 2,
    Archived = 3
  }

  export class Payment
{
    payingUser: User;
    payingUserId: number;
    createdAt: Date;
    externalPaymentId: string;
    externalPaymentCreateResponse: string;
    externalPaymentExecuteResponse: string;
    externalPaymentStatus: string;
    status: PaymentStatus;
    amount: number;
    currency: CurrencyEnum;
    // relatedBooking: Booking;
    relateBookingId: number;
}

export enum PaymentStatus
    {
        New,
        UserApprovedAuthorization,
        Completed,
        UserCancelled,
        Error
    }