import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SeoService } from '../../../_services/SeoService.service';
import { MapsAPILoader } from '@agm/core';
import { LocationService, ParsedAddress } from '../../../_services/location.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-createseo',
  templateUrl: './createSeo.component.html',
  styleUrls: ['./createSeo.component.css']
})
export class CreateSeoComponent implements OnInit {
  popupVisible: boolean;
  toolbarButtonOptions: any = {
    text: 'Show markup',
    stylingMode: 'text',
    onClick: () => this.popupVisible = true
};
searchControl: FormControl;
@ViewChild('search', { static: false }) public searchElementRef: ElementRef;
neighberhood = '';
city = '';
country = '';

venueParams: any = {
  name: '',
  id: '',
  status: '',
  locale: '',
  lat: '',
  lon: '',
  distanceLimit: 5
};
editorValue: string;
editOrAdd: string;
seoId;
  constructor(private service: SeoService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private locationService: LocationService,
    private alertify: AlertifyService,
    private route: ActivatedRoute) {
      this.searchControl = new FormControl();
     }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return this.alertify.error('Error while fetching google place id');
          }
          /** keep venue data   */
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          this.venueParams.lat = lat;
          this.venueParams.lon = lng;
          const parsedAddress = this.locationService.parseGooglePlaceAddress(place.address_components);
          this.city = parsedAddress.city ? parsedAddress.city : '';
          this.country = parsedAddress.country ? parsedAddress.country : '';
          this.neighberhood = parsedAddress.neighborhood ? parsedAddress.neighborhood : '';
        });
      });
    });

    const getSeoData = localStorage.getItem('seo_data');
    this.route.paramMap.subscribe(params => {
    this.seoId  = params['params'].id ;
    });
    if (getSeoData && this.seoId !== undefined ) {
      this.editOrAdd = 'Edit';
      const data = JSON.parse(getSeoData);
      this.country = data.country ? data.country : '';
      this.city = data.city ? data.city : '';
      this.neighberhood = data.neighberhood ? data.neighberhood : '';
      this.editorValue = data.seoContent ? data.seoContent : '';
    } else {
      this.editOrAdd = 'Add';
      this.country = '';
      this.city = '';
      this.neighberhood = '';
      this.editorValue = 'Insert new Content';
    }
  }

  createNewSeo() {
    const seoDetails = {
      id: Number(this.seoId),
      country: this.country,
      city: this.city,
      neighberhood: this.neighberhood,
      seoContent: this.editorValue,
    };
    if (seoDetails.city !== '' && seoDetails.country !== '' ) {
      this.service.upsertSeoData(this.editOrAdd, seoDetails).subscribe(res => {
        return this.alertify.success('Seo data Saved !!');
      }, error => {
        this.alertify.error('Saving SEO data failed. Check if the location is not duplicated?');
      });
    }
    }
}
