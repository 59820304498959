import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../_services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../../../../../Model/_models/User';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
  providers: [UsersService]
})
export class EditUserComponent implements OnInit {
  user: User;
  changePasswordPopupVisible = false;
  passwordValue = '';
  checkChanges = false;
  userFullName = '';

  constructor(private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private alertify: AlertifyService
  ) { }

  ngOnInit() {
    this.userService.getUserByID(this.route.snapshot.params.id).subscribe((data: User) => {
      this.user = data;
      const firstname = this.user.firstName ? this.user.firstName : '';
      const lastname = this.user.lastName ? this.user.lastName : '';
      this.userFullName = firstname + ' ' + lastname;
    });
  }

  showInfo() {
    this.changePasswordPopupVisible = true;
  }

  UpdatePassword(userId: number) {
    if (this.passwordValue !== '') {
      this.userService.UpdateUserPassword(userId, this.passwordValue);
      this.changePasswordPopupVisible = false;
      this.alertify.success('password updated');
    }
  }

  async submit() {
    await this.userService.updateUser(this.user).toPromise();
    this.checkChanges = false;
    this.alertify.success('Changes saved ...');
  }

  ToBooking() {
    this.router.navigate(['bookings'], { queryParams: { name: this.userFullName } });
  }

  ToOrganization() {
    this.router.navigateByUrl('clients/edit/' + this.user.relatedOrg.id);
  }

  onFieldDataChanged(e: any) {
    if (e.component.isReady() && e.component.getEditor(e.dataField)) {
      this.checkChanges = true;
    }
  }

  public resentVerificationEmail() {
    this.userService.sendVeficationMail(this.user.email).subscribe({
      next: _ => {
        this.alertify.success('mail sent');
      },
      error: error => {
        this.alertify.error(`failed with error: ${error}`)
        console.error(error)
      }
    })
  }
}
