import { PublishStatusEnum } from '../../../../../../Model/_models/general.interface';
import { AlertifyService } from './../../../_services/alertify.service';
import { LocationService, ParsedAddress } from './../../../_services/location.service';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { VenueEditService } from './../venue-edit/venue-edit.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Venue } from '../../../../../../Model/_models/Venue';
import { VenueService } from '../../../_services/venue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTooltipModule, DxTemplateModule, DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-venues-list',
  templateUrl: './venues-list.component.html',
  styleUrls: ['./venues-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [VenueEditService]
})
export class VenuesListComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  amenetiesList = this.venueEditService.amenetiesList;
  categories = this.venueEditService.venueCategoriesList;
  seatingOptions = this.venueEditService.seatingOptions;
  searchControl: FormControl;

  venues: Venue[];

  defualtSearchValues: any = {
    name: '',
    id: '',
    status: '',
    locale: '',
    lat: '',
    lon: '',
    distanceLimit: 5
  };
  venueParams: any = {
    name: '',
    id: '',
    status: '',
    locale: '',
    lat: '',
    lon: '',
    distanceLimit: 5
  };
  distancesList = [
    { distanceValue: 5, distanceName: '5km' },
    { distanceValue: 10, distanceName: '10km' },
    { distanceValue: 25, distanceName: '25km' },
    { distanceValue: 50, distanceName: '50km' },
  ];
  currentPage = 1;
  pageLimit = 12;
  loading = false;
  totalItems: number;
  idSearchString = '';
  titleSearchString = '';

  constructor(
    private venueService: VenueService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private venueEditService: VenueEditService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private locationService: LocationService,
    private alertify: AlertifyService,
  ) {
    this.getAmenitiesTextForSearch = this.getAmenitiesTextForSearch.bind(this);
    this.getSeatingOptionsTextForSearch = this.getSeatingOptionsTextForSearch.bind(this);

    this.searchControl = new FormControl();

    
  }

  googleAddressChanged(address: ParsedAddress, { lat, lng }) {
    console.log('googleAddressChanged: ', address);
    let fullAddress = '';
    if (address.street) {
      fullAddress = address.street;
      if (address.streetNumber) {
        fullAddress += ' ' + address.streetNumber;
      }
    } else if (address.neighborhood) {
      fullAddress = address.neighborhood;
    }
    fullAddress += ', ' + address.city;

    this.storeSearchInfoInLocal();

    this.fetchVenues();
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      // this.agmMap.triggerResize();

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return this.alertify.error('Error while fetching google place id');
          }

          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          this.venueParams.lat = lat;
          this.venueParams.lon = lng;

          const parsedAddress = this.locationService.parseGooglePlaceAddress(place.address_components);
          this.googleAddressChanged(parsedAddress, { lat, lng });
        });
      });
    });

    // Get Query Params
    const queryParam = this.activatedRoute.snapshot.queryParams;

    let isParamSame = false;
    // Get Venue Search params from local storage
    const venueSearchParam = JSON.parse(localStorage.getItem('venueSearchParam'));
    // Check if Query Params and local storage params are same or different
    if (venueSearchParam != null && String(queryParam.currentPage) === String(venueSearchParam.currentPage) &&
      String(queryParam.id) === String(venueSearchParam.id) &&
      String(queryParam.name) === String(venueSearchParam.name) &&
      String(queryParam.status) === String(venueSearchParam.status) &&
      String(queryParam.lat) === String(venueSearchParam.lat) &&
      String(queryParam.lon) === String(venueSearchParam.lon) &&
      String(queryParam.distanceLimit) === String(venueSearchParam.distanceLimit) &&
      String(queryParam.locale) === String(venueSearchParam.locale)) {
      isParamSame = true;
    }

    // If params are same then set current page
    if (venueSearchParam !== undefined && isParamSame) {
      this.venueParams = venueSearchParam;
      this.currentPage = Number(localStorage.getItem('venuesCurrentPage'));
    }

    // If Query Params is blank then set current page is 1 and appent current page in Query Params
    if (Object.keys(queryParam).length === 0) {
      this.currentPage = 1;
      this.venueParams.currentPage = 1;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.venueParams
      });
      localStorage.setItem('venueSearchParam', JSON.stringify({}));
    }

    this.fetchVenues();
  }

  fetchVenues() {
    this.blockUI.start('Loading...'); // Start blocking
    this.loading = true;
    console.log('this.venueParams :', this.venueParams);
    // this.venueService.getVenues(this.currentPage, this.pageLimit, this.venueParams).subscribe((response) => {
    this.venueService.getVenues(this.venueParams).subscribe((response) => {
      this.venues = response.result;
      // console.log(this.venues);
      // console.log('this.amenetiesList :', this.amenetiesList);
      // this.totalItems = response.pagination.totalItems;
    }, () => {
      alert('Error while getting venues');
    }, () => {
      this.loading = false;
      this.blockUI.stop();
    });
  }

  pageChanged(pageNumber: number) {
    this.currentPage = pageNumber;
    this.storeSearchInfoInLocal();
    this.fetchVenues();
  }

  searchTerms() {
    this.storeSearchInfoInLocal();
    this.currentPage = 1;
    this.fetchVenues();
  }

  // Store Venue search param and current page info in local storage
  storeSearchInfoInLocal() {
    this.venueParams.currentPage = Number(this.currentPage);
    // Append venue search key in query param
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.venueParams
    });
    // Store query params in local storage
    localStorage.setItem('venueSearchParam', JSON.stringify(this.venueParams));
    localStorage.setItem('venuesCurrentPage', String(this.currentPage));
  }

  getStatusName(rowData) {
    return PublishStatusEnum[rowData.status];
  }

  formatNumberSeparators(rowData) {
    if (rowData.ratePerHour !== null)
      return rowData.ratePerHour.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return 'no price';
  }

  formatterFunc(value: any) {

    if (value !== null)
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return 'no price';
  }

  saveState() {
    const state = this.dataGrid.instance.state();
    // Saves the state in the local storage
    // @Thaer No need for stringfy ,Slow Loding
    localStorage.setItem('venueGridState', JSON.stringify(state));
  }

  loadState() {
    const state = JSON.parse(localStorage.getItem('venueGridState'));
    if (state)
      this.dataGrid.instance.state(state);
  }

  navigateToEditVenue(venueId: number) {
    this.saveState();
    this.router.navigate([`/venues/edit/${venueId}`]);
  }

  onGridInit() {
    this.loadState();

  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: this.resetState.bind(this)
      }
    });
  }

  resetState() {
    const state = {
      'searchText': '',
      'pageIndex': 0,
      'filterValue': null
    };
    localStorage.removeItem('venueGridState');
    localStorage.removeItem('venueSearchParam');
    localStorage.removeItem('venuesCurrentPage');
    this.dataGrid.instance.state(state);
    if (this.venueParams.lat) {
      this.venueParams.lat = this.defualtSearchValues.lat;
      this.venueParams.lon = this.defualtSearchValues.lon;
      this.venueParams.distanceLimit = this.defualtSearchValues.distanceLimit;
      this.searchElementRef.nativeElement.value = '';
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.venueParams
      });
      this.fetchVenues();
    }
  }

  getAmenityName(id: number): string {
    const name = this.amenetiesList.filter(x => x.id === id)[0].name;
    return name;
  }

  getSeatingOptionName(id: number): string {
    const seatingOption = this.seatingOptions.filter(x => x.id === id)[0];
    if (!seatingOption) {
      return '';
    }
    const name = seatingOption.name;
    return name;
  }

  getAmenitiesTextForSearch(rowData) {
    let result = '';
    rowData.amenitiesList.forEach(a => {
      result += this.getAmenityName(a) + ' ';
    });
    return result;
  }

  getSeatingOptionsTextForSearch(rowData) {
    let result = '';
    rowData.seatingOptionsList.forEach(a => {
      result += this.getSeatingOptionName(a) + '<br/>';
    });
    return result;
  }

  getLocaleAddress(rowData): string {
    let result = rowData.street ? rowData.street : '';
    if (rowData.streetNumber) {
      if (result.length > 0) {
        result += ' ';
      }
      result += rowData.streetNumber + ', ';
    } else {
      result += ', ';
    }
    if (rowData.city) {
      result += rowData.city + ', ';
    }
    if (rowData.countryFullName) {
      result += rowData.countryFullName;
    }

    return result;
  }

  onDistanceValueChanged(e) {
    this.venueParams.distanceLimit = e.value;
    this.storeSearchInfoInLocal();
    if (this.venueParams.lat) {
      this.fetchVenues();
    }
  }
}
