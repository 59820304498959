import { Injectable } from '@angular/core';
import { AlertifyService } from '../_services/alertify.service';

@Injectable()
export class Errorhelper {
  /**
   *
   */
  constructor(private alertify: AlertifyService) {
  }
  showServerErrors(err: any) {
    if (err) {
      if (err instanceof Array) {
        err.forEach(e => {
          if (e.value) {
            console.log('e.value :', e.value);
            this.alertify.error(e.value);
          } else {
            this.alertify.error(e);
          }
        });
      } else if (err.error) {
        this.alertify.error(err.error);
      } else {
        this.alertify.error(err);
      }
      
      // for (const key in err) {
      //   if (err.value) {
      //     console.log('err.value :', err.value);
      //     this.alertify.error(err.value);
      //   }
      //   if (err[key]) {
      //     console.log('err[key] :', err[key]);
      //     this.alertify.error(err[key]);
      //   }
      // }
    }
  }
}
