
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        console.log('Error from server: ', error);
        const applicationError = error.headers.get('Application-Error');
        if (applicationError) {
          return observableThrowError(applicationError);
        }

        const serverError = error.error;
        // return observableThrowError(serverError || 'Server Error');
        let modelStateErrors = '';
        if (serverError && typeof serverError === 'object') {
          for (const key in serverError) {
            if (serverError[key]) {
              modelStateErrors += serverError[key].value + '\n';
            }
          }
        }

        console.log('modelStateErrors: ' + modelStateErrors);
        console.log('serverError: ' + serverError);
        // return Observable.throw(modelStateErrors || serverError || 'Server Error');
        return observableThrowError(modelStateErrors || serverError || 'Server Error');
      }
    }));
  }
}


export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
};
