import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent, DxDataGridComponent } from 'devextreme-angular';
import { BookingService } from '../../../_services/booking.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { CurrencyEnum, PublishStatusEnum, OrgAuthRoles, OrgUserStatus } from '../../../../../../Model/_models/general.interface';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PoStatus } from '../../../../../../Model/_models/POCode';

@Component({
  selector: 'app-clients-edit',
  templateUrl: './clients-edit.component.html',
  styleUrls: ['./clients-edit.component.css']
})
export class ClientsEditComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) gridContainer: DxDataGridComponent;
  @ViewChild(DxFormComponent, { static: false }) myForm: DxFormComponent;
  @ViewChild('poForm', { static: false }) poCodeForm: DxFormComponent;

  displayCurrency = ['ILS', 'USD', 'GBP', 'EUR'];
  displayLocale = ['en-UK', 'he-IL'];
  roles = [{ name: 'Admin', value: 1, }, { name: 'Budget Owner', value: 2, }, { name: 'Event Planner', value: 4, }];
  clientData: any = {};
  poCodeData: any = {};
  currency = [];
  locale = [];
  editOrAdd: string;
  publishStatusEnum = PublishStatusEnum;
  defaultPayloadSettings;
  orgId: Number;
  orgUsers = [];
  inviteCoolDownDuration: number = (15 * 60000);
  orgAuthRoles = OrgAuthRoles;
  orgUserStatus = OrgUserStatus;
  approver = [];
  userStatusHeaderFilter = [];
  userRoleHeaderFilter = [];
  userApproverFilter = [];
  inviteNewUser: Boolean = false;
  newcode: Boolean = false;
  buttonOptions: any = {
    text: 'Send Email',
    type: 'default',
    useSubmitBehavior: true
  };

  buttonOptionspoCode: any = {
    text: 'Create poCode',
    type: 'default',
    useSubmitBehavior: true
  };

  inValidEmail: Boolean = false;
  logoFiles: File[] = [];
  poCodeFlag: boolean;
  fixedDiscountPercentage: number;
  newPOData: any;
  orgPOList = [];

  constructor(
    private bookingService: BookingService,
    private alert: AlertifyService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.poCodeStatus = this.poCodeStatus.bind(this);
    this.isDeletePOVisible = this.isDeletePOVisible.bind(this);
    this.isUnDeletePOVisible = this.isUnDeletePOVisible.bind(this);
    this.isInviteIconVisible = this.isInviteIconVisible.bind(this);
    this.isEditIconVisible = this.isEditIconVisible.bind(this);
    this.isEditPOVisible = this.isEditPOVisible.bind(this);
    this.isUnEditPOVisible = this.isUnEditPOVisible.bind(this);
    this.isDeleteIconVisible = this.isDeleteIconVisible.bind(this);
    this.isUnDeleteIconVisible = this.isUnDeleteIconVisible.bind(this);
    this.isUnEditIconVisible = this.isUnEditIconVisible.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.getApproverName = this.getApproverName.bind(this);
    this.accountBtnVisible = this.accountBtnVisible.bind(this);
    this.currency = [{ id: CurrencyEnum.ILS, name: 'ILS' }, { id: CurrencyEnum.USD, name: 'USD' },
    { id: CurrencyEnum.GBP, name: 'GBP' }, { id: CurrencyEnum.EUR, name: 'EUR' }];
    this.locale = [{ id: 1, name: 'en-UK' }, { id: 2, name: 'he-IL' }];
    this.defaultPayloadSettings = {
      currency: this.getCurrency(0),
      preferredLocale: this.getLocale(2),
      paymentAtEndOfBooking: false,
      bookingRequiresAuthorization: false,
      enablePOForBookings: false,
      fixedDiscountPercentage: 0
    };
    this.orgId = this.route.snapshot.params.id;
    this.userStatusHeaderFilter = [
      {
        text: 'Invited',
        value: ['status', '=', this.orgUserStatus.Invited]
      }, {
        text: 'Active',
        value: ['status', '=', this.orgUserStatus.Onboard]
      }, {
        text: 'Deleted',
        value: ['status', '=', this.orgUserStatus.Removed]
      }
    ];
    this.userRoleHeaderFilter = [
      {
        text: 'Admin',
        value: ['role', '=', this.orgAuthRoles.Admin]
      }, {
        text: 'Budget Owner',
        value: ['role', '=', this.orgAuthRoles.BudgetOwner]
      }, {
        text: 'Event Planner',
        value: ['role', '=', this.orgAuthRoles.EventPlanner]
      }
    ];

  }

  ngOnInit() {
    const getClientData = localStorage.getItem('client_data');
    if (getClientData || this.orgId) {
      this.editOrAdd = 'Edit';
      const data = JSON.parse(getClientData);
      this.editRecord(data);
    } else {
      this.editOrAdd = 'Add';
      this.clientData = {
        currency: this.getCurrency(0),
        preferredLocale: this.getLocale(2),
        paymentAtEndOfBooking: false,
        enablePOForBookings: false,
        bookingRequiresAuthorization: false,
        fixedDiscountPercentage: 0
      };
    }

    setTimeout(() => {
      this.setTimerIntervalForInvite();
    }, this.inviteCoolDownDuration);
  }

  editRecord(e) {
    const data: any = {};
    data.id = e.id;
    data.name = e.name;
    data.status = e.status;
    const currency =
      e.settings !== null && e.settings.mainCurrency >= 0 ? e.settings.mainCurrency : 1;
    data.currency = this.getCurrency(currency);
    const locale =
      e.settings !== null && e.settings.preferredLocale ? e.settings.preferredLocale : 2;
    data.preferredLocale = this.getLocale(locale);
    data.paymentAtEndOfBooking =
      e.settings !== null && e.settings.paymentAtEndOfBooking ? e.settings.paymentAtEndOfBooking : false;
    data.enablePOForBookings =
      e.settings !== null && e.settings.enablePOForBookings ? e.settings.enablePOForBookings : false;
      this.poCodeFlag = e.settings.enablePOForBookings;
    data.fixedDiscountPercentage =
      e.settings !== null && e.settings.fixedDiscountPercentage ? e.settings.fixedDiscountPercentage : 0;
      this.fixedDiscountPercentage = e.settings.fixedDiscountPercentage;
    data.bookingRequiresAuthorization =
      e.settings !== null && e.settings.bookingRequiresAuthorization ? e.settings.bookingRequiresAuthorization : false;
    data.logoImageUrl = e.logoImageUrl;
    this.clientData = data;
  }

  selectTab(e) {
    if (e.itemIndex === 1) {
      this.getAllUsers();
    } else if (e.itemIndex === 2) {
      this.getOrgPOList();
    }
  }

  getOrgPOList() {
    this.bookingService.getOrgPoList(this.orgId).subscribe((poList: Object[]) => {
      this.orgPOList = poList;
    });
  }


  getAllUsers() {
    this.bookingService.getAllUsers(this.clientData.id).subscribe((user: Object[]) => {
      let userData = [];
      const tempApprover: any = [{
        name: 'No Approver',
        value: null
      }];
      this.userApproverFilter.push({
        text: 'No Approver',
        value: null
      });
      userData = user.map((element: any, index) => {
        const data: any = {};
        data.uid = index;
        data.id = element.id;
        data.isMainAccount = element.isMainAccount;
        data.firstName = element.firstName;
        data.lastName = element.lastName;
        data.name = (data.firstName ? data.firstName : '') + ' ' + (data.lastName ? data.lastName : '');
        data.email = element.email;
        data.role = element.role;
        data.bookingAuthorizerId = element.bookingAuthorizerId;
        const firstName = data.firstName;
        const lastName = data.lastName;
        const fullName = (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
        const isExist = tempApprover.findIndex(appro => appro['value'] === data.id);

        if (isExist < 0) {
          if ((element.status === this.orgUserStatus.Onboard || element.status === this.orgUserStatus.Invited)) {
            tempApprover.push({
              name: fullName,
              value: data.id
            });
            this.userApproverFilter.push({
              text: fullName,
              value: data.id
            });
          }
        }
        data.status = element.status;
        if (data.status === this.orgUserStatus.Removed || data.status === this.orgUserStatus.Onboard) {
          data.disableInviteBtn = true;
        } else {
          data.disableInviteBtn = false;
        }
        return data;
      });
      this.approver = tempApprover;
      this.orgUsers = userData;
      this.orgUsers = this.orgUsers.map((element: any, index) => {
        if (element.bookingAuthorizerId) {
          const isExist = this.approver.findIndex(appro => appro['value'] === element.bookingAuthorizerId);
          if (isExist >= 0) {
            element.approverName = this.approver[isExist].value;
          } else {
            element.approverName = this.approver[0].value;
          }
        } else {
          element.approverName = this.approver[0].value;
        }
        return element;
      });
      this.setTimerIntervalForInvite();
    });
  }

  setTimerIntervalForInvite() {
    const getInviteArray = JSON.parse(localStorage.getItem('inviteArray'));
    if (getInviteArray !== null) {
      if (getInviteArray.length > 0) {
        getInviteArray.forEach((invite, inviteIndex) => {
          const index = this.orgUsers.findIndex((res) => res.id === invite.id);
          const timeStamp = (invite.timeStamp + this.inviteCoolDownDuration);
          const currentTime = moment.utc().valueOf();
          if (index >= 0) {
            if (currentTime <= timeStamp) {
              this.orgUsers[index].disableInviteBtn = true;
            } else {
              this.orgUsers[index].disableInviteBtn = false;
              getInviteArray.splice(inviteIndex, 1);
              localStorage.setItem('inviteArray', JSON.stringify(getInviteArray));
            }
          }
        });
      } else {
        localStorage.removeItem('inviteArray');
      }
    }
  }

  onFieldDataChanged(e: any) {
    if (e.dataField === 'email') {
      const validEmail = this.orgUsers.findIndex((res) => res.email === e.value);
      if (validEmail < 0) {
        this.inValidEmail = false;
      } else {
        this.inValidEmail = true;
      }
    }
  }

  onFormInviteSubmit(e) {
    const data = this.myForm.instance.option('formData');
    const body: any = {};
    body.firstName = data.firstName;
    body.lastName = data.lastName;
    body.email = data.email;
    body.role = data.role;
    body.bookingAuthorizerId = data.approver;
    if (!this.inValidEmail) {
      this.bookingService.inviteUser(this.clientData.id, body).subscribe((res) => {
        this.alert.success('invited successfully');
        this.inviteNewUser = false;
        this.myForm.instance.resetValues();
        this.orgUsers = [];
        this.getAllUsers();

      });
    }
  }

  onFormpoCodesubmit(e) {
    const body: any = {};
    const data = this.poCodeForm.instance.option('formData');
    body.POCode = data.POCode;
    body.Budget = data.Budget;
    if (this.newcode) {
      this.bookingService.newpoCode(this.clientData.id, body).subscribe((res) => {
        this.alert.success('Ref code created successfully');
        this.newcode = false;
        this.poCodeForm.instance.resetValues();
        this.getOrgPOList();
      }, (err) => {
        this.alert.error(err.error);
        this.newcode = false;
        this.poCodeForm.instance.resetValues();
      })
      ;
    }
  }

  inviteUser(userData) {
    const body: any = {};
    const inviteArray = [];
    const inviteObject: any = {};
    body.firstName = userData.data.firstName;
    body.lastName = userData.data.lastName;
    body.email = userData.data.email;
    body.role = userData.data.role;
    body.bookingAuthorizerId = userData.data.id;
    this.bookingService.inviteUser(this.clientData.id, body).subscribe((res) => {
      const index = this.orgUsers.findIndex((data) => data.id === userData.data.id);
      this.orgUsers[index].disableInviteBtn = true;
      inviteObject.id = userData.data.id;
      inviteObject.timeStamp = moment.utc().valueOf();
      inviteArray.push(inviteObject);
      localStorage.setItem('inviteArray', JSON.stringify(inviteArray));
      this.alert.success('successfully invited');
    });
  }

  onSubmitForm() {
    const valid = this.myForm.instance.validate();
    if (valid.isValid) {
      const data = this.myForm.instance.option('formData');
      this.createAndUpdateBusinessClient(data);
      this.poCodeFlag = data.enablePOForBookings;
    }
  }

  createAndUpdateBusinessClient(data) {
    const body: any = {};
    body.id = data.id !== undefined ? data.id : 0;
    body.name = data.name;
    body.settings = {
      bookingRequiresAuthorization: data.bookingRequiresAuthorization,
      paymentAtEndOfBooking: data.paymentAtEndOfBooking,
      fixedDiscountPercentage: data.fixedDiscountPercentage,
      enablePOForBookings: data.enablePOForBookings,
      mainCurrency: CurrencyEnum[data.currency],
      preferredLocale: data.preferredLocale === 'en-UK' ? 1 : 2
    };
    this.bookingService.createNewBusinessClient(body).subscribe((res: any) => {
      if (this.editOrAdd === 'Add') {
        this.alert.success('Added Successfully');
        localStorage.setItem('client_data', JSON.stringify(res));
        this.router.navigate([`clients/edit/${res.id}`]);
      } else {
        const clientData = JSON.parse(localStorage.getItem('client_data'));
        clientData.settings = res.settings;
        clientData.name = res.name;
        localStorage.setItem('client_data', JSON.stringify(clientData));
        this.alert.success('Record Updated');
      }
    });
  }

  dataGridEvents(eventName, e) {
    if (eventName === 'onPoCodeRowRemoving') {
      this.bookingService.removePOCode(this.clientData.id, e.data.id).subscribe((res) => {
        e.data.status = this.poCodeStatus(3);
        this.getOrgPOList();
        this.alert.success('POCode deleted');
      }, (err) => {
        this.alert.error('There was some error, please refresh the page');
      });
    }

    if (eventName === 'PORowUpdating') {
      const body: any = {};
      body.Id = e.oldData.id;
      body.Budget = e.newData.budget;
      this.bookingService.updatePOCode(this.clientData.id, body).subscribe((res) => {
        this.alert.success('POCode updated');
      }, (err) => {
        this.alert.error('There was some error, please refresh the page');
      });
    }


    if (eventName === 'onRowRemoving') {
      e.cancel = true;
      this.bookingService.removeOrgUser(this.clientData.id, e.data.id).subscribe((res) => {
        e.data.status = 3;
        const index = this.approver.findIndex((approver) => approver.value === e.data.id);
        this.approver.splice(index, 1);
        this.alert.success('user deleted');
      }, (err) => {
        this.alert.error('There was some error, please refresh the page');
      });
    }

    if (eventName === 'InitNewRow') {
      e.component.columnOption('Name', 'allowEditing', true);
      e.component.columnOption('Email', 'allowEditing', true);
    }

    if (eventName === 'RowUpdating') {
      let role: number;
      const body: any = {};
      if (e.newData.role) {
        role = e.newData.role;
      } else {
        role = e.key.role;
      }
      body.id = e.key.id;
      body.Role = role;
      if (e.newData.approverName || e.newData.approverName === null) {
        body.BookingAuthorizerId = e.newData.approverName;
      } else {
        this.approver.map((findApprover) => {
          if (findApprover.bookingAuthorizer === e.key.approverName) {
            body.BookingAuthorizerId = findApprover.value;
          }
        });
      }
      this.bookingService.updateUserData(this.clientData.id, e.key.id, body).subscribe((res) => {
        const index = this.orgUsers.findIndex(user => user.id === e.key.id);
        this.orgUsers[index].bookingAuthorizerId = body.BookingAuthorizerId;
        e.cancel = false;
        this.gridContainer.instance.repaint();
        this.alert.success('record updated');
      }, (err) => {
        this.alert.error('There was some error, please refresh the page');
        e.cancel = true;
      });
    }
    if (eventName === 'onEditorPreparing') {
      if (e.dataField === 'approverName') {
        e.editorName = 'dxSelectBox';
        e.editorOptions.dataSource = this.approver;
        e.editorOptions.valueExpr = 'value';
        e.editorOptions.displayExpr = 'name';
      }
      if (e.dataField === 'role') {
        e.editorName = 'dxSelectBox';
        e.editorOptions.dataSource = this.roles;
        e.editorOptions.valueExpr = 'value';
        e.editorOptions.displayExpr = 'name';
      }

    }

    if (eventName === 'onCellPrepared') {
      if (e.rowType === 'header' && e.column.command === 'edit') {
        e.cellElement.style = 'border:none;';
        e.cellElement.textContent = 'Actions';
        e.component.columnOption('command:edit', 'width', 160);
      }
    }

    if (eventName === 'onEditingStart') {
      e.component.columnOption('Name', 'allowEditing', false);
      e.component.columnOption('Email', 'allowEditing', false);
      const key = e.key;
      const index = this.orgUsers.findIndex((res) => res.uid === key.uid);
        if (this.orgUsers[index] && this.orgUsers[index].isMainAccount) {
          e.cancel = true;
          this.alert.error('admin is immutable');
        }
    }
  }

  uploadLogo() {
    if (this.logoFiles.length > 0) {
      this.bookingService.setOrganizationLogo(this.clientData.id, this.logoFiles[0])
        .subscribe(res => {

          // update the local storage client_data variable with new logo url
          const clientData = localStorage.getItem('client_data');
          const data = JSON.parse(clientData);
          data.logoImageUrl = res;

          localStorage.setItem('client_data', JSON.stringify(data));
          this.editRecord(data);
          this.alert.success('Logo Uploaded');
          this.logoFiles = [];
        });
    }
  }

  setMainAccount(e) {
    if (!e.data.isMainAccount) {
      this.bookingService.setMainAccount(this.clientData.id, e.data.id).subscribe((res) => {
        const index = this.orgUsers.findIndex((user) => user.isMainAccount === true);
        this.orgUsers[index].isMainAccount = false;
        const idx = this.orgUsers.findIndex((user) => user.id === e.data.id);
        this.orgUsers[idx].isMainAccount = true;
        this.alert.success('main account set');
        this.gridContainer.instance.refresh();
      });
    }
  }

  /**
  * Publish booking client
  */
  publishClient() {
    this.bookingService.publishClient(this.clientData.id).subscribe(() => {
      const clientData = JSON.parse(localStorage.getItem('client_data'));
      clientData.status = this.publishStatusEnum.Published;
      localStorage.setItem('client_data', JSON.stringify(clientData));
      this.clientData.status = this.publishStatusEnum.Published;
      this.alert.success('Published successfully');
    }, (err: Error | any) => {
      this.alert.error('Error while publishing');
    });
  }

  /**
   *  Unpublished booking client
   */
  unPublishClient() {
    this.bookingService.UnPublishClient(this.clientData.id).subscribe(() => {
      const clientData = JSON.parse(localStorage.getItem('client_data'));
      clientData.status = this.publishStatusEnum.Draft;
      localStorage.setItem('client_data', JSON.stringify(clientData));
      this.clientData.status = this.publishStatusEnum.Draft;
      this.alert.success('Un-published successfully');
    }, (error: Error | any) => {
      this.alert.error('Error while un-publishing');
    });
  }

  getLocale(localeId) {
    const found = this.locale.find(i => i.id === localeId);
    if (found) {
      return found.name;
    }
  }

  getCurrency(currencyId: CurrencyEnum) {
    const found = this.currency.find(i => i.id === currencyId);
    if (found) {
      return found.name;
    }
  }

  getRoles(e) {
    if (e.role === OrgAuthRoles.Admin) {
      return 'Admin';
    } else if (e.role === OrgAuthRoles.BudgetOwner) {
      return 'Budget Owner';
    } else {
      return 'Event Planner';
    }
  }

  poCodeStatus(rowdata) {
    return PoStatus[rowdata.status];
  }

  isInviteIconVisible(e) {
    return !e.row.isEditing;
  }

  isEditIconVisible(e) {
    let isStatusRemoved = true;
    if (e.row.data.status === this.orgUserStatus.Removed || e.row.data.isMainAccount) {
      isStatusRemoved = false;
    }
    return !e.row.isEditing && isStatusRemoved;
  }

  isUnEditIconVisible(e) {
    let isStatusRemoved = false;
    if (e.row.data.status === this.orgUserStatus.Removed || e.row.data.isMainAccount) {
      isStatusRemoved = true;
    }
    return !e.row.isEditing && isStatusRemoved;
  }

  isEditPOVisible(e) {
    let isStatusRemoved = true;
    if (e.row.data.budget === this.orgUserStatus.Removed || e.row.data.isMainAccount) {
      isStatusRemoved = false;
    }
    return !e.row.isEditing && isStatusRemoved;
  }

  isUnEditPOVisible(e) {
    let isStatusRemoved = false;
    if (e.row.data.status === this.orgUserStatus.Removed || e.row.data.isMainAccount) {
      isStatusRemoved = true;
    }
    return !e.row.isEditing && isStatusRemoved;
  }

  isDeleteIconVisible(e) {
    const index = this.orgUsers.findIndex(user => user.bookingAuthorizerId === e.row.data.id);
    const isDelete = (index === -1) ? true : false;
    let isStatusRemoved = true;
    if (e.row.data.status === this.orgUserStatus.Removed) {
      isStatusRemoved = false;
    }
    return !e.row.isEditing && !e.row.data.isMainAccount && isStatusRemoved && isDelete;
  }

  isDeletePOVisible(e) {
    const index = this.orgUsers.findIndex(user => user.bookingAuthorizerId === e.row.data.id);
    const isDelete = (index === -1) ? true : false;
    let isStatusRemoved = true;
    if (e.row.data.status === this.orgUserStatus.Removed) {
      isStatusRemoved = false;
    }
    return !e.row.isEditing && !e.row.data.isMainAccount && isStatusRemoved && isDelete;
  }

  isUnDeletePOVisible(e) {
    const index = this.orgUsers.findIndex(user => user.bookingAuthorizerId === e.row.data.id);
    const isDelete = (index !== -1) || e.row.data.status === this.orgUserStatus.Removed ? true : false;
    return (!e.row.isEditing && isDelete) || e.row.data.isMainAccount;
  }

  isUnDeleteIconVisible(e) {
    const index = this.orgUsers.findIndex(user => user.bookingAuthorizerId === e.row.data.id);
    const isDelete = (index !== -1) || e.row.data.status === this.orgUserStatus.Removed ? true : false;
    return (!e.row.isEditing && isDelete) || e.row.data.isMainAccount;
  }

  accountBtnVisible(e) {
    let valid = true;
    if (e.row.data.status === this.orgUserStatus.Removed) {
      valid = false;
    }
    return !e.row.isEditing && valid;
  }

  getApproverName(rowData) {
    const index = this.approver.findIndex((res) => res.value === rowData.approverName);
    if (index >= 0) {
      return this.approver[index].name;
    }
  }
}
