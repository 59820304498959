import { PublishStatusEnum, CurrencyEnum } from './general.interface';
import { ServiceProviderInventory, ServiceProviderRegion, Payment } from './ServiceProvider';
import { User } from './User';
import { VenueSeatingOptionEnum } from './Venue';

export interface Booking {
    id: number;
    start: string;
    end: string;
    guestNumber: number;
    finalPrice: number;
    currency: CurrencyEnum;
    status: PublishStatusEnum;
    createdAt: string;
    firstName: string;
    lastName: string;
    title: string;
    cateringPrice: number;
    bookedVenue: VenueSummary;
    bookingTimeSlots: BookingTimeSlot[];
    bookedVenue_RealName: string;
    bookedVenue_Id: number;
    relatedOrgBooking: any;
    bookingInventories: any[];
    bookingUser: User;
    invoiceUrl: string;
    email: string;
}

export interface BookingTimeSlot {
    id: number;
    start: string;
    end: string;
    price: number;
    priceType: BookingPriceTypeEnum;
    seatingOption: VenueSeatingOptionEnum;
    startTime?: number;
    endTime?: number;
    guestNumber?: number;
}

export interface BookingInventory {
    id: number;
    inventoryName: string;
    providerName: string;
    amount: number;
    deliveryTime: string;
    price: number;

    // not mapped
    relatedInventory?: any; // ServiceProviderInventory
    selectedServiceQty?: number;
    selectedQtyRegular?: number;
    selectedQtyVegan?: number;
    selectedQtyGlutenFree?: number;
    selectedQtyVegetarian?: number;
    selectedMenuQty?: number;
}

export interface BookingDelivery {
    id: number;
    providerName: string;
    deliveryTime: string;
    deliveryCost: number;
}

export enum BookingPriceTypeEnum {
    PerHour,
    HalfDay,
    FullDay,
    ExtraDay,
    PackagePerPerson,
    MorningSlot,
    AfterNoonSlot,
    EveningSlot,
    FullDaySlot
}

export enum BookingStatusEnum {
    PendingPayment, // 0
    PendingSupplierApproval, // 1
    SupplierConfirmed, // 2
    Finalized, // 3
    PaymentFailed, // 4
    UserCancelled, // 5
    AdminCancelled, // 6
    SupplierCancelled, // 7
    PendingOrgReview, // 8
    Declined, // 9
    Archived // 10
}

export enum OrgBookingStatusEnum {
    Pending = 1,
    Approved = 2,
    Declined = 3,
    Cancelled = 4,
    Archived = 5
}

export interface VenueSummary {
    id: number;
    name: string;
    phoneNumber: number;
    end: string;
    email: string;
    fullAddress: string;
    country: string;
    countryFullName: string;
    city: string;
    maxParticipants: number;
    contactPersonName: string;
    realName: string;
}

export interface IRefreshmentBooking {
    id: number;
    title?: string;
    address: string;
    floor: string;
    appartmentNumber: string;
    entranceNumber: string;
    userComments?: string;
    deliveryComments?: string;
    finalPrice: number;
    finalPriceInUnifiedCurrency: number;
    deliveryCost: number;

    // contact details
    Fullname: string;
    Email: string;
    Phone?: string;
    CompanyName?: string;

    // items ordered
    orderedInventoryItems: IRefreshmentItemBooked[];

    bookingUser: User;
    bookingUserId: number;
    createdBy: User;
    createdById: number;
    TimeZone: string;

    selectedDeliveryRegion: ServiceProviderRegion;
    selectedDeliveryRegionId: number;
    currency: CurrencyEnum;
    status: BookingStatusEnum;
    isArchived: boolean;
    relatedPayment?: Payment;
    relatedPaymentId?: number;


    relatedCouponId: number;
    relatedCoupon?: Coupon;
    couponDiscountPercent?: number;
    couponDiscountAmount?: number;
    relatedOrgBooking?: any;
    invoiceUrl?: string;

    createdAt: Date;
    supplierName: string;
    supplierId: number;
}

export interface IRefreshmentItemBooked {
    relatedBooking: IRefreshmentBooking;
    relatedBookingId: number;

    relatedInventory: ServiceProviderInventory;
    relatedInventoryId: number;

    selectedQtyRegular?: number;
    selectedQtyVegan?: number
    selectedQtyGlutenFree?: number;
    selectedQtyVegetarian?: number;
    selectedMenuQty?: number;
    totalPrice?: number;

    deliveryDateTime: Date;
}
export class Coupon
{
    code: string;
    notUsableBeforeTS?: Date;
    expirationTS?: Date;
    discountPercentage: number;
    createdById: number;
    createdBy: User;
    createdAt: Date;
    redeemers: CouponRedeem[];
}

export class CouponRedeem
{
    couponId: number;
    coupon: Coupon;
    redeemingUserId: number;
    redeemingUser: User;
    bookingId: number;
    relatedBooking: Booking;
    redeemTS: Date;
}