import { Component, OnInit , ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { User } from '../../../../../../Model/_models/User';
import {UsersService} from '../../../_services/users.service';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  users: User[];

  constructor(private userService: UsersService,
    private router: Router) { }

  ngOnInit() {
    this.fetchUsers();
  }

  fetchUsers() {
      this.userService.getAllUsers()
        .subscribe((data: User[]) => {
          this.users = data;
        });
  }

  navigateToEditUser(userId: number) {
    this.router.navigate([`/users/edit/${userId}`]);
  }

  getFullNameUserDetails(rowData: any): string {
      return `${rowData.firstName ? rowData.firstName : ' '} ${rowData.lastName ? rowData.lastName : ' '}`;
  }

  onGridInit() {
    this.loadState();
  }

  loadState() {
    const state = JSON.parse(localStorage.getItem('usersGridState'));
    if (state)
      this.dataGrid.instance.state(state);
  }

  saveState() {
    const state = this.dataGrid.instance.state();
    localStorage.setItem('usersGridState', JSON.stringify(state));
  }

  resetState() {
    const state = {
      'searchText': '',
      'pageIndex': 0,
      'filterValue': null
    };
    localStorage.removeItem('usersgGridState');
    this.dataGrid.instance.state(state);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: this.resetState.bind(this)
      }
    });
  }

}
