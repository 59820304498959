import { Errorhelper } from './_helpers/error-helper';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TabsModule } from 'ngx-bootstrap';
import { NgxGalleryModule } from 'ngx-gallery';
import { BlockUIModule } from 'ng-block-ui';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { VenuesListComponent } from './_components/venues/venues-list/venues-list.component';
import { NavComponent } from './_components/nav/nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule  } from '@angular/common/http';
import { JsonpModule } from '@angular/http';

import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { appRoutes } from './routes';
import { AlertifyService } from './_services/alertify.service';
import { AuthService } from './_services/auth.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ClientsListComponent } from './_components/business-clients/clients-list/clients-list.component';
import { BookingsListComponent } from './_components/bookings-list/bookings-list.component';
import { HomeComponent } from './_components/home/home.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { AuthGuard } from './_guards/auth.guard';
import { VenueService } from './_services/venue.service';
import { JwtHttpInterceptor } from './_services/JwtHttpInterceptor';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { VenueCardComponent } from './_components/venues/venue-card/venue-card.component';
import { VenueDetailComponent } from './_components/venues/venue-detail/venue-detail.component';
import { VenueDetailResolver } from './_resolvers/venue-detail.resolver';
import { VenueListResolver } from './_resolvers/venue-list.resolver';
import { VenueEditComponent } from './_components/venues/venue-edit/venue-edit.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploaderComponent } from './_components/file-uploader/file-uploader.component';
import { LocationService } from './_services/location.service';
import { ImageService } from './_services/image.service';
import { RegionComponent } from './_components/region/region.component';
import { TreeModule } from 'ng2-tree';
import { RegionService } from './_services/region.service';
import { EditBookingsComponent } from './_components/bookings-list/edit-bookings/edit-bookings.component';
import { ServiceProvidersListComponent } from './_components/service-providers/service-providers-listing/service-providers-list.component';
import { ServiceProviderService } from './_services/service-provider.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderModule } from 'ngx-order-pipe';
import { ServiceProviderEditComponent } from './_components/service-providers/service-provider-edit/service-provider-edit.component';
import { TypeaheadModule } from 'ngx-bootstrap';
import { TreeComponent } from './_components/service-providers/tree-component/tree.component';
import { InventoryEditComponent } from './_components/service-providers/inventory-edit/inventory-edit.component';
import { SeoComponent } from './_components/seo/seo.component';
import { TaxConfigComponent } from './_components/tax-config/tax-config.component';
import { CreateSeoComponent } from './_components/seo/createSeo/createSeo.component';
import { SeoService } from './_services/SeoService.service';
import { ClipboardModule } from 'ngx-clipboard';

export function getAccessToken(): string {
  return localStorage.getItem('token');
}
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  DxDataGridModule,
  DxTooltipModule,
  DxTemplateModule,
  DxTagBoxModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTabsModule,
  DxFormModule,
  DxNumberBoxModule,
  DxTextAreaModule,
  DxPopupModule,
  DxButtonModule,
  DxValidatorModule,
  DxTextBoxModule,
  DxSwitchModule,
  DxHtmlEditorModule,
  DxFileUploaderModule,
  DxButtonGroupModule,
  DxAutocompleteModule,
  DxDropDownButtonModule
} from 'devextreme-angular';
import { BookingService } from './_services/booking.service';
import { ClientsEditComponent } from './_components/business-clients/clients-edit/clients-edit.component';
import { PriceByPackageComponent } from './shared/price-by-package/price-by-package.component';
import { WindowRef } from './shared/price-by-package/window';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { UsersListComponent } from './_components/users/users-list/users-list.component';
import { UsersService } from './_services/users.service';
import { EditUserComponent } from './_components/users/edit-user/edit-user.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { CouponsComponent } from './_components/coupons/coupons.component';
import { RefreshmentBookingsListComponent } from './_components/refreshment-booking/refreshment-bookings-list/refreshment-bookings-list.component';
import { RefreshmentBookingsEditComponent } from './_components/refreshment-booking/refreshment-bookings-edit/refreshment-bookings-edit.component';
import { PaymentPagesComponent } from './_components/payment-pages/payment-pages.component';
import { CurrencyToStringPipe } from './_pipes/currency-to-string.pipe';
// import localeHE from '@angular/common/locales/he';
// import { registerLocaleData } from '@angular/common';

// registerLocaleData(localeHE);


export const jwtConfig = {
  tokenGetter: getAccessToken,
  whitelist: [
    'localhost',
    'localhost:5000',
    'http://localhost:5000',
    'localhost:4200',
    'devapi.meetinkz.com',
    'api.meetinkz.com'
  ]
};

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    VenuesListComponent,
    ClientsListComponent,
    BookingsListComponent,
    DashboardComponent,
    VenueCardComponent,
    VenueDetailComponent,
    VenueEditComponent,
    FileUploaderComponent,
    RegionComponent,
    ServiceProvidersListComponent,
    ServiceProviderEditComponent,
    TreeComponent,
    InventoryEditComponent,
    ClientsEditComponent,
    PriceByPackageComponent,
    UsersListComponent,
    EditUserComponent,
    EditBookingsComponent,
    SeoComponent,
    CreateSeoComponent,
    TaxConfigComponent,
    CouponsComponent,
    RefreshmentBookingsListComponent,
    RefreshmentBookingsEditComponent,
    PaymentPagesComponent,
    CurrencyToStringPipe

  ],
  imports: [
    NgbModule,
    NgxPaginationModule,
    NgxMyDatePickerModule.forRoot(),
    BlockUIModule.forRoot(),
    FileUploadModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      // language: window.localStorage && window.localStorage['gml'] || 'en',
      language: 'en',
      libraries: ['places']
    }),
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    JsonpModule,
    HttpClientJsonpModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    JwtModule.forRoot({
      config: jwtConfig
    }),
    BsDropdownModule.forRoot(),
    NgxDatatableModule,
    TabsModule.forRoot(),
    NgxGalleryModule,
    TreeModule,
    BrowserAnimationsModule,
    OrderModule,
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    DxDataGridModule,
    DxTooltipModule,
    DxTemplateModule,
    DxTagBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxFormModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxPopupModule,
    DxButtonModule,
    DxValidatorModule,
    DxTextBoxModule,
    DxSwitchModule,
    DxHtmlEditorModule,
    DxFileUploaderModule,
    DxButtonGroupModule,
    DxAutocompleteModule,
    NgxFilesizeModule,
    DxDropDownButtonModule,
    NgxTrimDirectiveModule,
    ClipboardModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
    // { provide: LOCALE_ID, useValue: 'he-IL' },
    AuthService,
    AlertifyService,
    AuthGuard,
    VenueService,
    BookingService,
    ErrorInterceptorProvider,
    VenueDetailResolver,
    VenueListResolver,
    LocationService,
    ImageService,
    Errorhelper,
    RegionService,
    ServiceProviderService,
    WindowRef,
    UsersService,
    SeoService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
