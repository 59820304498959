import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Errorhelper } from '../../../_helpers/error-helper';
import {
  ServiceProviderStatusEnum, CountryCodeEnum, DeliveryOptionEnum,
  InventoryStatusEnum, PricingTypeEnum, ServiceTypeSubEnum, ServiceInventoryPackageTypeList
} from '../../../../../../Model/_models/ServiceProvider';
import { ServiceProviderService } from './../../../_services/service-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify.service';
import { FileUploader } from 'ng2-file-upload';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FileItem } from 'ng2-file-upload';
import { OrderPipe } from 'ngx-order-pipe';
import { TreeModel } from 'ng2-tree';
import { CurrencyEnum } from '../../../../../../Model/_models/general.interface';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { zip } from 'rxjs/observable/zip';
import { FileUploaderComponent } from '../../file-uploader/file-uploader.component';
import { FileSizePipe } from 'ngx-filesize';

export interface TreeNode {
  name: string;
  showChildren: boolean;
  children: any[];
}

@Component({
  selector: 'app-service-provider-edit',
  templateUrl: './service-provider-edit.component.html',
  styleUrls: ['./service-provider-edit.component.css'],
  providers: [FileSizePipe]
})
export class ServiceProviderEditComponent implements OnInit {
  @ViewChild('fileUploader', { static: false }) fileUploader: FileUploaderComponent;
  photoType = AssetType;
  @BlockUI() blockUI: NgBlockUI;
  CountryCodeEnum = CountryCodeEnum;
  serviceProvider: any = {};
  venueList = [];
  serviceProviderService: any;
  selectedVenue: any;
  serviceProviderId: any;
  isAdd = true;
  logoUrl = '';
  serviceProviderStatusEnum = ServiceProviderStatusEnum;
  pricingTypeEnum = PricingTypeEnum;
  serviceSubTypeEnum = ServiceTypeSubEnum;
  inventoryStatusEnum = InventoryStatusEnum;
  selectedVenues = [];
  public uploader: FileUploader = new FileUploader({
    authTokenHeader: 'Authorization',
    authToken: 'Bearer ' + this.jwtHelper.tokenGetter(),
  });

  currentPage = 1;
  pageLimit = 1;
  totalItems: number;
  order = 'id';
  loading = false;
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  // sorting
  key: String = 'id'; // set default
  reverse: Boolean = true;
  sortedCollection: any[];

  tempArray: any[] = [];

  selectedNode: TreeModel = null;
  model: any = {
    country: 'IL',
    searchVal: ''
  };
  countryNodes: any = [];
  selectedRegion: any;
  selectedRegions: any = [];
  deliveryCost: any;
  isDeliveryAvil = false;
  serviceRegions: any = [];
  currency: any;
  CurrencyEnum = CurrencyEnum;
  inventoryCollection: any = [];
  openInventory = false;
  inventoryObject = {};
  deliveryOptionEnum = DeliveryOptionEnum;
  parentDeliveryCost = undefined;
  isParentDeliveryAvil = undefined;
  parentCurrency = undefined;
  configModal = {
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered'
  };
  @ViewChild('serviceProviderTabs', { static: false }) serviceProviderTabs: TabsetComponent;
  @ViewChild('confirmationTemplate', { static: false }) confirmationTemplate: ModalDirective;

  // file upload
  public kosherCertificateUploader: FileUploader = new FileUploader({
    isHTML5: true,
    maxFileSize: 10 * 1024 * 1024,
    queueLimit: 1,
  });
  selectedKosherCertificateFile: any;
  showUploadSpinnerForKosherCertificate = false;


  constructor(serviceProviderService: ServiceProviderService,
    private activeRoute: ActivatedRoute,
    private alertify: AlertifyService,
    private router: Router,
    private errorhelper: Errorhelper,
    private jwtHelper: JwtHelperService,
    private orderPipe: OrderPipe,
    private fileSize: FileSizePipe) {
    this.serviceProviderService = serviceProviderService;
    const routeParams = this.activeRoute.snapshot.params;
    // Check if routes has params. if route has id means it for update
    if (routeParams.id !== undefined) {
      this.serviceProviderId = routeParams.id;
      this.isAdd = false;
    } else {
      this.serviceProvider.countryCode = CountryCodeEnum.IL;
    }
    this.activeRoute.queryParams.subscribe((params) => {
      if (params) {
        this.serviceProvider.name = params.realName;
        this.serviceProvider.countryCode = params.countryCode;
        this.serviceProvider.contactPersonName = params.name;
        this.serviceProvider.phone = params.phone;
        this.serviceProvider.email = params.email;
      }
    });

  }

  ngOnInit() {
    if (!this.isAdd) {
      this.blockUI.start('');
      // Get service provider by id
      this.getServiceProviderById(this.serviceProviderId);
    } else {
      this.getCountryRegion(CountryCodeEnum.IL);
    }

    this.kosherCertificateUploader.onAfterAddingFile = fileItem => {
      this.selectedKosherCertificateFile = fileItem._file;
    };
  }

  /**
   *  Save/Update service provider form
   */
  onSubmitForm() {
    if (this.isAdd) {
      if (this.selectedVenue !== undefined && this.selectedVenue.value === this.serviceProvider.relatedVenueName) {
        this.serviceProvider.relatedVenueId = this.selectedVenue.item.id;
        delete this.serviceProvider.relatedVenueName;
      } else {
        this.serviceProvider.relatedVenueId = '';
      }
      // Save service provider
      this.serviceProviderService.saveServiceProvider(this.serviceProvider).subscribe(async (response) => {
        this.blockUI.start('');
        this.serviceProviderId = response.id;
        await this.uploadImages(this.uploader.queue);
        this.updateRelatedVenues(response);
      }, (error) => {
        this.blockUI.stop();
        this.alertify.error('Error while adding entity');
      });
    } else {
      // Update service provider
      this.serviceProviderService.updateServiceProvider(this.serviceProvider).subscribe((response) => {
        this.blockUI.start('');
        this.updateRelatedVenues(response);
      }, (error) => {
        this.alertify.error('Error while updating entity');
        this.blockUI.stop();
      });
    }

    // upload serviceProvider images
    if (this.fileUploader.uploader.queue.length > 0) {
      this.uploadServiceProviderImage(this.fileUploader.uploader.queue);
    }
  }

  private uploadServiceProviderImage(files: FileItem[]) {
    if (!files || !files.length) return;

    for (const file of files) {
      console.log('file :', file);
      if (file.file.size > 10000000) {
        this.alertify.error(`Image '${file.file.name.substr(0, 25)}' is too large - ${this.fileSize.transform(file.file.size)}`);
      }
      else {
        this.serviceProviderService.uploadPhoto(this.serviceProviderId, file).subscribe(
          result => {
            this.serviceProvider.mediaAssets.push(result);
            console.log(result);
          },
          error => {
            this.alertify.error(error);
            console.error(error);
          }
        );
      }
    }
  }

  photosToRemove: IMediaAsset[] = [];
  public updateRemoveList(event: any, item: IMediaAsset) {
    const idx = this.photosToRemove.findIndex(x => x.id == item.id);
    if (event.target.checked) {
      if (idx == -1)
        this.photosToRemove.push(item);
    }
    else {
      if (idx != -1)
        this.photosToRemove.splice(idx, 1);
    }
  }

  public removeImages() {
    if (this.photosToRemove.length > 0) {
      this.serviceProviderService.removeInventoryItemImages(this.serviceProviderId, this.photosToRemove).subscribe(
        result => console.log(result),
        error => {
          console.error(error);
        }
      )
    }
  }


  /**
   * HTTP call to get service provider by id
   * @param id
   */
  getServiceProviderById(id, isOnlyLogoUploaded = false) {
    this.serviceProviderService.getServiceProvider(id).subscribe((response) => {
      console.log(response);
      // If only logo is uploaded then not need to serviceProvider all service provider object
      if (!isOnlyLogoUploaded) {
        this.serviceProvider = response;
        this.serviceProvider.mediaAssets = this.serviceProvider.mediaAssets.filter(x => x.photoType == AssetType.ServiceProviderAsset);
        console.log('mediaAssets: ', this.serviceProvider.mediaAssets);
        AssetType.ServiceProviderAsset
        this.addDeliveryDetails(this.countryNodes, this.serviceProvider.deliveryRegions, {});
        this.getCountryRegion(this.serviceProvider.countryCode);
        this.inventoryCollection = this.orderPipe.transform(this.serviceProvider.inventory);
        console.log(this.inventoryCollection);
      }

      this.selectedVenues = [];
      if (response.relatedVenues.length > 0) {
        this.selectedVenues = response.relatedVenues;
        this.sortedCollection = this.orderPipe.transform(this.selectedVenues);
      }
      if (response.logoImage !== null) {
        this.logoUrl = response.logoImage.url;
      }
      this.blockUI.stop();
    }, (error) => {
      this.blockUI.stop();
    });
  }

  /**
   * Call function when value change in venue and get venue list which match with search term
   * @param searchTerm
   */
  VenueChanged(searchTerm) {
    console.log('searchTerm :', searchTerm);
    if (searchTerm.length > 2) {
      this.serviceProviderService.searchVenue(searchTerm, this.serviceProvider.countryCode).subscribe((res) => {
        this.venueList = [];
        res.map(venue => {
          venue.displayName = venue.id + ' | ' + venue.realName + ' | ' + venue.name;

          if (this.selectedVenues.length > 0) {
            if (this.selectedVenues.findIndex(item => item.id === venue.id) < 0) {
              console.log('venue.displayName 1:', venue.displayName);
              this.venueList.push(venue);
            }
          } else {
            console.log('venue.displayName 2:', venue.displayName);
            this.venueList.push(venue);
          }
          return venue;
        });
      });
    } else {
      this.venueList = [];
    }
  }

  onVenueSelect(venue) {
    this.selectedVenues.push(venue.item);
    this.selectedVenue = venue;
    this.serviceProvider.relatedVenueName = '';
    this.sortedCollection = this.orderPipe.transform(this.selectedVenues);
  }

  onCountryCodeChange(countryCode) {
    this.getCountryRegion(countryCode);
    this.serviceProvider.relatedVenueName = '';
    this.selectedRegion = undefined;
    this.setCurrency();
    this.deliveryCost = undefined;
    this.isDeliveryAvil = false;
  }

  inventoryPackageTypeList = ServiceInventoryPackageTypeList;
  getInventoryType(id: number) {
    const type = this.inventoryPackageTypeList.find(x => x.id == id);
    if (type) return type.name;
  }

  /**
   * Publish service provider
   */
  publishServiceProvider() {
    this.serviceProviderService.publishServiceProvider(this.serviceProvider.id).subscribe(() => {
      this.serviceProvider.status = this.serviceProviderStatusEnum.Published;
      this.alertify.success('Published successfully');
    }, (err: Error | any) => {
      this.errorhelper.showServerErrors(err);
    });
  }

  /**
   *  Unpublished service provider
   */
  unpublishServiceProvider() {
    this.serviceProviderService.unpublishServiceProvider(this.serviceProvider.id).subscribe(() => {
      this.serviceProvider.status = this.serviceProviderStatusEnum.Draft;
      this.alertify.success('Un-published successfully');
    }, (error: Error | any) => {
      this.alertify.error('Error while un-publishing');
    });
  }

  /**
   * Upload logo when user select
   * @param file
   */
  async onFileSelected(file) {
    if ((this.uploader.queue.length > 0) && (!this.isAdd)) {
      this.blockUI.start('Uploading...');
      await this.uploadImages(this.uploader.queue);
    }
  }

  /**
   * Upload service provider's logo
   * @param files
   */
  async uploadImages(files: FileItem[]) {
    if (!files || !files.length) return;
    const imageObservers = [];
    for (const file of files) {
      imageObservers.push(this.serviceProviderService.uploadServiceProviderImage(this.serviceProviderId, file));
    }
    const response = await zip(...imageObservers).toPromise();
    this.getServiceProviderById(this.serviceProviderId, true);
    this.uploader.clearQueue();
  }

  /**
   * Delete related venue from selected venues list
   * @param index
   * @param venue
   */
  deleteRelatedVenue(venue) {
    const deleteVenueId = this.selectedVenues.findIndex(item => item.id === venue.id);
    if (deleteVenueId >= 0) {
      this.selectedVenues.splice(deleteVenueId, 1);
      this.sortedCollection = this.orderPipe.transform(this.selectedVenues);
    }
  }

  async setRegionToServiceProvider(serviceProviderId) {
    this.serviceRegions = [];

    this.createRegionObject(this.countryNodes, serviceProviderId, {});
    if (!this.isAdd) {
      this.addDisabledRegion();
    }
    const response = this.serviceProviderService.setRegionToServiceProvider(this.serviceRegions, serviceProviderId);
    await response.toPromise().then(
      res => {
      },
      err => { // Error
        this.blockUI.stop();
        this.errorhelper.showServerErrors(err);
      }
    );
  }

  addDisabledRegion() {
    this.serviceProvider.deliveryRegions.map(region => {
      if (!region.isDeliveryAvailable) {
        const isfound = this.serviceRegions.findIndex(sRegion => sRegion.CountryRegionId === region.countryRegionId);
        if (isfound === -1)
          this.serviceRegions.push({
            'ServiceProviderId': Number(this.serviceProviderId),
            'CountryRegionId': Number(region.countryRegionId),
            'DeliveryCost': '',
            'Currency': region.currency,
            'isDeliveryAvailable': 0
          });
      }
    });
  }
  /**
   * serviceProvider service call to update selectedVenues list.
   * @param serviceProvider
   */
  async updateRelatedVenues(serviceProvider) {
    const selectedVenuesId = this.selectedVenues.map((res) => res.id);
    const reqPost = {
      ServiceProviderId: serviceProvider.id,
      RelatedVenuesIdList: selectedVenuesId
    };
    await this.setRegionToServiceProvider(serviceProvider.id);
    this.serviceProviderService.setVenueToServiceProvider(reqPost).subscribe((res) => {
      // Based on status display alert message
      if (this.isAdd) {
        this.alertify.success('Successfully added');
        this.router.navigate([`service-providers/edit/${serviceProvider.id}`]);
      } else {
        this.selectedRegion = undefined;
        this.alertify.success('Successfully updated');
      }
      this.getServiceProviderById(this.serviceProviderId);
    }, (error) => {
      this.blockUI.stop();
    });
  }

  /**
   *  Sort table
   * @param value
   */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  getCountryRegion(countryCode) {
    this.countryNodes = [];
    this.serviceProviderService.getCountryRegion(countryCode).subscribe((res) => {
      this.countryNodes.push({ 'root': true, 'regionName': countryCode.toUpperCase(), childRegions: res });
      this.addDeliveryDetails(this.countryNodes, this.serviceProvider.deliveryRegions, {});
      this.toggleTree(this.countryNodes, true);
    }, (error) => {
    });
  }

  setChildDeliveryCost(regions, parentDeliveryCost = '') {
    regions.forEach(n => {
      if (n.childRegions.length > 0) {
        const delCost = (n.deliveryCost) ? n.deliveryCost : '';
        this.setChildDeliveryCost(n.childRegions, delCost);
      }
    });
  }

  addDeliveryDetails(regions, deliveryRegions, parentRegion) {

    if (regions !== undefined && deliveryRegions !== undefined) {
      regions.forEach(n => {


        const regionIndex = deliveryRegions.findIndex(region => region.countryRegionId === n.id);
        if (parentRegion.isParentDeliveryAvil !== undefined) {
          n.parentDeliveryCost = parentRegion.parentDeliveryCost;
          n.parentCurrency = parentRegion.parentCurrency;
        }
        if (regionIndex >= 0) {
          n.isDeliveryAvil = deliveryRegions[regionIndex].isDeliveryAvailable;
          n.deliveryCost = deliveryRegions[regionIndex].deliveryCost;
          n.currency = deliveryRegions[regionIndex].currency;
          n.parentDeliveryCost = undefined;
          n.parentCurrency = undefined;
        } else {
          const parentRegionIndex = deliveryRegions.findIndex(region => region.countryRegionId === parentRegion.id);
          if (parentRegionIndex >= 0) {
            if (deliveryRegions[parentRegionIndex].deliveryCost !== null) {
              n.parentDeliveryCost = deliveryRegions[parentRegionIndex].deliveryCost;
              n.parentCurrency = deliveryRegions[parentRegionIndex].currency;
            } else {
              n.parentDeliveryCost = undefined;
              n.parentCurrency = undefined;
            }

          }
          n.isDeliveryAvil = false;
          n.isParentDeliveryAvil = true;
        }

        if (n.childRegions.length > 0) {
          this.addDeliveryDetails(n.childRegions, deliveryRegions, n);
        }
      });
    }
  }

  /**
   * Call function on region select. Assign selected region cost to delivery cost
   * @param node
   */
  onRegionSelected(node) {
    // console.log(node.currency);
    // if (node.currency === null) {
    //   this.setCurrency();
    //   node.currency = this.currency;
    // }

    if (node.deliveryCost !== undefined) {
      this.deliveryCost = node.deliveryCost;
      this.isDeliveryAvil = node.isDeliveryAvil;
      this.currency = node.currency;
      if (this.currency === null) {
        this.setCurrency();
        node.currency = this.currency;
      }
    } else {
      this.isDeliveryAvil = false;
      this.deliveryCost = undefined;
      this.setCurrency();
    }

    this.parentDeliveryCost = undefined;
    this.isParentDeliveryAvil = undefined;
    this.parentCurrency = undefined;

    if (node.parentDeliveryCost !== undefined) {
      this.parentDeliveryCost = node.parentDeliveryCost;
      this.isParentDeliveryAvil = true;
      this.parentCurrency = node.parentCurrency;
    }
    if (node.countryCode !== undefined) {
      this.selectedRegion = node;
      this.selectedRegion.currency = (this.selectedRegion.currency !== undefined) ? this.selectedRegion.currency : this.currency;
    } else {
      this.selectedRegion = undefined;
    }

    this.deSelectedRegion(this.countryNodes, node);
  }

  /**
   * Set currency based on country
   */
  setCurrency() {

    if (this.serviceProvider.countryCode === CountryCodeEnum.GB) {
      this.currency = this.CurrencyEnum.GBP;
    } else if (this.serviceProvider.countryCode === CountryCodeEnum.IL) {
      this.currency = this.CurrencyEnum.ILS;
    }
  }

  /**
   * Set Selected = true on which region user clicked else Set selected = false
   * @param childRegions
   * @param node
   */
  deSelectedRegion(childRegions, node) {
    childRegions.forEach(n => {
      if (n.childRegions.length > 0) {
        this.deSelectedRegion(n.childRegions, node);
      }
      n.selected = false;
      if (node.id === n.id && n.countryCode !== undefined) {
        n.selected = true;
      }
    });
  }

  /**
   * Toggle tree node. open and close tree node based on toggle param
   * @param tree
   * @param toggle
   */
  toggleTree(tree, toggle) {
    if (!toggle) {
      this.selectedRegion = undefined;
      this.onRegionSelected({});
    }
    tree.forEach(node => {
      if (node.childRegions.length > 0) {
        this.toggleTree(node.childRegions, toggle);
      }
      node.showChildren = toggle;
    });
  }

  onChangeDeliveryAvailable() {
    if (!this.isDeliveryAvil && this.selectedRegion.childRegions.length > 0) {
      this.confirmationTemplate.show();
    } else {
      this.deleteDeliverySub();
    }

    if (this.selectedRegion.isDeliveryAvil && !this.isAdd) {
      this.removeFromDeleted([this.selectedRegion]);
    }
  }

  deleteDeliverySub() {
    if (!this.isDeliveryAvil) {
      this.deleteDelivery([this.selectedRegion]);
    }
    if (this.isDeliveryAvil) {
      this.deliveryCost = 0;
      this.selectedRegion.isDeliveryAvil = true;
      this.selectedRegion.deliveryCost = 0;
      this.setCurrency();
    } else {
      this.deliveryCost = undefined;
      this.currency = undefined;
      this.selectedRegion.isDeliveryAvil = false;
    }
  }

  confirm() {
    this.confirmationTemplate.hide();
    this.deleteDeliverySub();
  }

  hideChildModal() {
    this.isDeliveryAvil = true;
    this.confirmationTemplate.hide();
  }

  deleteDelivery(regions) {

    regions.forEach(n => {
      n.isDeliveryAvil = false;
      n.parentDeliveryCost = undefined;
      n.isParentDeliveryAvil = undefined;
      n.isDeliveryAvil = false;
      n.cancelDelivery = true;
      if (n.childRegions !== undefined) {
        if (n.childRegions.length > 0) {
          this.deleteDelivery(n.childRegions);
        }
      }
    });
  }

  onDeliveryChange() {
    this.selectedRegion.deliveryCost = this.deliveryCost;
    if (this.selectedRegion.parentDeliveryCost) {
      this.selectedRegion.parentDeliveryCost = undefined;
      this.selectedRegion.isDeliveryAvil = true;
    }
    this.changeSubRegions([this.selectedRegion], this.selectedRegion);
  }


  changeSubRegions(regions, parent) {
    if (regions !== undefined) {
      regions.forEach(n => {
        if (n.childRegions.length > 0) {
          this.changeSubRegions(n.childRegions, n);
        }

        if (n.parentDeliveryCost !== undefined && (!n.isDeliveryAvil)) {
          n.parentDeliveryCost = (parent.parentDeliveryCost !== undefined) ? parent.parentDeliveryCost : parent.deliveryCost;
          n.parentCurrency = (parent.parentCurrency !== undefined) ? parent.parentCurrency : parent.currency;
          n.isParentDeliveryAvil = true;
        }
      });
    }
  }

  removeFromDeleted(regions) {
    if (regions !== undefined) {
      regions.forEach(n => {
        if (n.childRegions.length > 0) {
          this.removeFromDeleted(n.childRegions);
        }

        const indexOfRegion = this.serviceProvider.deliveryRegions.findIndex(delRegion => delRegion.countryRegionId === n.id);
        if (indexOfRegion >= 0) {
          this.serviceProvider.deliveryRegions.splice(indexOfRegion, 1);
        }

      });
    }
  }

  createRegionObject(regions, serviceProviderId, parentNode) {
    if (regions !== undefined) {
      regions.forEach(n => {
        //
        if (n.childRegions.length > 0 && (n.cancelDelivery !== true)) {
          this.createRegionObject(n.childRegions, serviceProviderId, n);
        }
        // If delivery available then push it in service regions
        if (n.isDeliveryAvil) {
          this.serviceRegions.push({
            'ServiceProviderId': serviceProviderId,
            'CountryRegionId': n.id,
            'DeliveryCost': n.deliveryCost,
            'Currency': n.currency,
            'isDeliveryAvailable': 1
          });

        } else if ((n.cancelDelivery === true) && parentNode.isDeliveryAvil === true) {
          if (!this.isAdd) {
            this.serviceRegions.push({
              'ServiceProviderId': serviceProviderId,
              'CountryRegionId': n.id,
              'DeliveryCost': '',
              'Currency': n.currency,
              'isDeliveryAvailable': 0
            });
          }
        }

      });
    }
  }

  /**
   * on currency change assign currency to selectedRegion currency
   */
  onChangeCurrency() {
    this.selectedRegion.currency = this.currency;

  }

  /**
   * Called when inventory edit clicked
   * @param row
   */
  editInventory(row) {
    this.inventoryObject = row;
    this.openInventory = true;
  }

  /**
   * Open service provider tab
   * @param event
   */
  openInventoryTab(event) {
    this.openInventory = false;
    // If event is not undefined means it's call after delete inventory so remove deleted inventory from inventory list
    if (event !== undefined) {
      // tslint:disable-next-line:max-line-length
      const inventoryIndex = this.serviceProvider.inventory.findIndex(inventory => inventory.id === event.id);
      if (inventoryIndex >= 0) {
        this.serviceProvider.inventory.splice(inventoryIndex, 1);
      }
      this.inventoryCollection = this.orderPipe.transform(this.serviceProvider.inventory);
    }

    if (event === undefined) {
      this.getServiceProviderById(this.serviceProviderId);
    }
    setTimeout(() => {
      this.serviceProviderTabs.tabs[3].active = true;
    }, 0);

  }

  /**
   * Call function on tab change to set order id base in sorting table
   * @param event
   */
  onSelectTab(event) {
    this.order = 'id';
  }

  onChangeParentDeliveryAvailable() {
    this.isDeliveryAvil = false;
    this.deliveryCost = undefined;
    this.currency = undefined;
    this.selectedRegion.isDeliveryAvil = false;
    this.selectedRegion.parentDeliveryCost = undefined;
    this.selectedRegion.isParentDeliveryAvil = undefined;
    this.selectedRegion.parentCurrency = undefined;
    this.isParentDeliveryAvil = false;
    this.selectedRegion.cancelDelivery = true;
    this.onChangeDeliveryAvailable();
    if (!this.isDeliveryAvil && this.selectedRegion.childRegions.length > 0) {
      this.confirmationTemplate.show();
    } else {
      this.deleteDeliverySub();
    }

  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  public removeKosherCertificate() {
    this.kosherCertificateUploader.queue = [];
    this.selectedKosherCertificateFile = undefined;
  }

  public uploadKosherCertificateFile() {
    if (this.kosherCertificateUploader.queue.length > 0) {
      this.showUploadSpinnerForKosherCertificate = true;
      this.serviceProviderService.uploadKosherCertificate(this.serviceProvider.id, this.kosherCertificateUploader.queue[0]).subscribe(
        response => {
          this.serviceProvider.kosherCertificateUrl = response.url;
          this.showUploadSpinnerForKosherCertificate = false;
        },
        error => {
          console.log('uploadInsuranceForVenue file upload error: ' + error);
          this.showUploadSpinnerForKosherCertificate = false;
          this.alertify.error(error);
        }
      );
    }
  }
}

enum AssetType {
  VenuePhoto = 1,
  KosherCertificateAsset,
  MenuAsset,
  Insurance,
  ServiceProviderAsset,
}

interface IMediaAsset {
  dateAdded: Date;
  description: string;
  id: number;
  isMain: boolean;
  photoType: number;
  url: string;
}
