import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeoService } from '../../_services/SeoService.service';
import { AlertifyService } from '../../_services/alertify.service';


@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']
})
export class SeoComponent implements OnInit {
  dataSource: any[];
  editorValue: string;


  constructor(private service: SeoService, private router: Router, private alertify: AlertifyService) { }
  ngOnInit() {
    this.fetchSeoData();
    console.log(this.dataSource);
  }

  fetchSeoData() {
    this.service.getSeoData()
      .subscribe((data: any[]) => {
        this.dataSource = data;
        console.log(this.dataSource);
      });
    }
    editSeoClient(e) {
        localStorage.setItem('seo_data', JSON.stringify(e.data));
        this.router.navigate([`seo/manageseo/${e.data.id}`]);
    }


    /** eventes listener  */
    dataGridEvents(eventName , e) {

      if (eventName === 'seoremove') {
        console.log('here')
        this.service.deleteSeoData(e.data.id).subscribe(res => {
          this.fetchSeoData();
          return this.alertify.success('Seo data Deleted !!');
        });
      }

    }


}
