import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CountryRegion } from '../../../../Model/_models/Region';

@Injectable()
export class RegionService {
    private baseUrl = environment.apiUrl;

    constructor(
        private authHttp: HttpClient
    ) { }

    getCountryRegions(countryCode: string): Observable<CountryRegion[]> {
        countryCode = countryCode.toLowerCase();
        return this.authHttp.get<CountryRegion[]>(this.baseUrl + 'regions/country/' + countryCode);
    }

    createNewRegion(data: any): Observable<CountryRegion> {
        return this.authHttp.post<CountryRegion>(this.baseUrl + 'regions', data);
    }

    deleteRegion(id: number | string): Observable<any> {
        return this.authHttp.delete(this.baseUrl + 'regions/' + id);
    }
}