import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from '../../../../Model/_models//User';
import { Observable } from 'rxjs';

@Injectable()
export class UsersService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllUsers() {
    return this.http.get<User[]>(this.baseUrl + 'users');
  }

  getUserByID(id: string) {
    return this.http.get(this.baseUrl + 'users/' + id);
  }

  UpdateUserPassword(userId, password) {
    return this.http.post(this.baseUrl + 'auth/UpdateUserPassword',
      {
        'userId': userId,
        'newPassword': password
      }).subscribe(
        data => {
          console.log('POST Request is successful ', data);
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  updateUser(data: User): Observable<User> {
    console.log('updateUser', data);
    return this.http.put<User>(this.baseUrl + '/auth/update', data);
  }

  public sendVeficationMail(email: string) {
    const url = `${this.baseUrl}Auth/ResendVerficiationMail?userEmail=${email}`
    return this.http
      .post(url, {});
  }

}
