
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { VenueService } from '../../_services/venue.service';
import { PublishStatusEnum, LanguagesEnum } from '../../../../../Model/_models/general.interface';
import { DxDataGridComponent, DxPopupComponent, DxHtmlEditorComponent, DxHtmlEditorModule } from 'devextreme-angular';
import { AlertifyService } from '../../_services/alertify.service';
import { Errorhelper } from '../../_helpers/error-helper';
import { confirm } from 'devextreme/ui/dialog';
import { WindowRef } from './window';

@Component({
  selector: 'app-price-by-package',
  templateUrl: './price-by-package.component.html',
  styleUrls: ['./price-by-package.component.css']
})

export class PriceByPackageComponent implements OnInit {
  @ViewChild('gridContainer', { static: false }) gridContainer: DxDataGridComponent;
  @ViewChild('packagePricePopup', { static: false }) packagePricePopup: DxPopupComponent;
  @Input() venueOrServiceProvider;
  @Input() id: Number;
  @Input() details: any = [];
  @Input() pricePackages = [];
  currentId;
  rowKey;
  startsAtDataSource = new Array;
  isStartsAtChecked;
  publishStatusEnum = PublishStatusEnum;
  addingOrDeletingTier: Boolean = false;
  maxParticipants: Number;
  window: WindowRef;
  disableAddTierBtn: Boolean = false;
  toParticipantMinValue = -1;
  menu_url = '';
  mainPhotoUrl = '';
  menuFileName = '';
  maxGuests;
  removefile = false;
  languageEnum = LanguagesEnum;
  validatorFlag = true;
  newTierIndex = [];
  confirmPopupClose = false;
  packageTypes = [{ 'key': '1', 'name': 'DDR Package' }, { 'key': '2', 'name': 'Eat&Meet Package' }];
  constructor(
    private venueService: VenueService,
    private alert: AlertifyService,
    private errorHelper: Errorhelper,
  ) {
    this.minChargeGuestsValidationCallback = this.minChargeGuestsValidationCallback.bind(this);
    this.extraDescritptionValidation = this.extraDescritptionValidation.bind(this);
    this.extraCostValidation = this.extraCostValidation.bind(this);
  }

  ngOnInit() {
    if (this.pricePackages) {
      this.pricePackages.map((res) => {
        if (res.tiers) {
          res.tiers.sort((a, b) => {
            return a.fromParticipants - b.fromParticipants;
          });
        }
        res.saveBtn = false;
        return res;
      });
    }
    this.maxGuests = this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants;
  }

  addNewTier(id) {
    this.addingOrDeletingTier = true;
    const index = this.pricePackages.findIndex(res => res.id === id);
    const idx = this.newTierIndex.findIndex((t) => t === id);
    if (idx < 0) {
      this.newTierIndex.push(this.pricePackages[index].id);
    }
    let pricePerParticipant = 0;
    let highestToParticipant = 0;
    let nextTierId = 0;
    if (this.pricePackages[index].tiers.length > 0) {
      highestToParticipant = Math.max.apply(Math, this.pricePackages[index].tiers.map((o) => o.toParticipants));
      nextTierId = Math.max.apply(Math, this.pricePackages[index].tiers.map((o) => o.id));
    }
    this.pricePackages[index].saveBtn = true;
    const max = this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants;
    if (highestToParticipant < max) {
      const tier = this.pricePackages[index].tiers;
      tier.map((response, i) => {
        if (i === tier.length - 1) {
          pricePerParticipant = response.pricePerParticipant;
        }
      });
      this.pricePackages[index].tiers.push({
        fromParticipants: highestToParticipant + 1,
        id: ++nextTierId,
        uid: 'newTier',
        pricePerParticipant: pricePerParticipant,
        relatedPackageId: this.pricePackages[index].id,
        toParticipants: this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants,
      });
    } else {
      const tier = this.pricePackages[index].tiers;
      tier.map((response, i) => {
        if (i === tier.length - 1) {
          response.toParticipants = (this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants) - 2;
          pricePerParticipant = response.pricePerParticipant;
        }
      });
      this.pricePackages[index].tiers.push({
        fromParticipants: (this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants) - 1,
        id: ++nextTierId,
        uid: 'newTier',
        pricePerParticipant: pricePerParticipant,
        relatedPackageId: this.pricePackages[index].id,
        toParticipants: (this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants),
      });
    }
  }

  deleteLastTier(id) {
    this.addingOrDeletingTier = true;
    const result = confirm('Are you sure, you want to delete the tier?', 'Confirm changes');
    result.then((res) => {
      if (res === true) {
        const index = this.pricePackages.findIndex(r => r.id === id);
        const idx = this.newTierIndex.findIndex((t) => t === id);
        if (idx < 0) {
          this.newTierIndex.push(this.pricePackages[index].id);
        }
        const tier = this.pricePackages[index].tiers;
        this.pricePackages[index].saveBtn = true;
        tier.pop();
        tier.map((response, i) => {
          if (i === tier.length - 1) {
            response.toParticipants = (this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants);
          }
        });
      }
    });
  }

  fromParticipantValueChanged(e, id, tierId) {
    if (e.previousValue > 2) {
      const index = this.pricePackages.findIndex(res => res.id === id);
      this.pricePackages.map((res) => {
        if (res.id === id) {
          let tier = [];
          tier = res.tiers;
          const tierIndex = tier.findIndex(t => t.id === tierId);
          this.toParticipantMinValue = this.pricePackages[index].tiers[0].toParticipants;
          if (tier[0].toParticipants >= 1) {
            if (tierIndex !== 0 && e.value > 1) {
              if (((tierIndex + 1) * 2) <= e.previousValue) {
                if (!this.addingOrDeletingTier) {
                  this.pricePackages[index].saveBtn = true;
                  const idx = this.newTierIndex.findIndex((t) => t === id);
                  if (idx < 0) {
                    this.newTierIndex.push(this.pricePackages[index].id);
                  }
                }
                tier[tierIndex - 1].toParticipants = e.value - 1;
              }
            }
          }
        }
      });
    }

  }

  toParticipantsValueChanged(e, id, tierId) {
    if (e.previousValue > 2) {
      const index = this.pricePackages.findIndex(res => res.id === id);
      this.pricePackages.map((res) => {
        if (res.id === id) {
          let tier = [];
          tier = res.tiers;
          const tierIndex = tier.findIndex(t => t.id === tierId);
          if (tier[0].toParticipants >= 1 && tierIndex !== tier.length - 1) {
            if (((tierIndex * 2) + 1) <= e.previousValue) {
              if (tier[tierIndex + 1].fromParticipants <= (tier[tierIndex + 1].toParticipants)) {
                if (!this.addingOrDeletingTier) {
                  this.pricePackages[index].saveBtn = true;
                  const idx = this.newTierIndex.findIndex((t) => t === id);
                  if (idx < 0) {
                    this.newTierIndex.push(this.pricePackages[index].id);
                  }
                }
                tier[tierIndex + 1].fromParticipants = e.value + 1;
              }
            }
          }
        }
      });
    }
  }

  maxValueToParticipants(pack, i, last) {
    if (last) {
      return (this.venueOrServiceProvider === 'serviceProvider' ? 1000 : this.details.maxParticipants);
    } else {
      return pack.data.tiers[i + 1].toParticipants - 1;
    }
  }

  pricePerParticipantValueChanged(e, dataField, id, tierId) {
    const index = this.pricePackages.findIndex(res => res.id === id);
    this.pricePackages.map((res) => {
      if (res.id === id) {
        let tier = [];
        tier = res.tiers;
        const tierIndex = tier.findIndex(t => t.id === tierId);
        const idx = this.newTierIndex.findIndex((t) => t === id);
        if (idx < 0) {
          this.newTierIndex.push(this.pricePackages[index].id);
        }
        this.pricePackages[index].saveBtn = true;
        tier[tierIndex][dataField] = e.value;
      }
    });
  }

  publishOrUnPublishPackage(data, status) {
    if (status === 'Unpublish') {
      this.venueService.unPublishPackage(data.data.id).subscribe(() => {
        const index = this.pricePackages.findIndex(findId => findId.id === data.data.id);
        this.pricePackages[index].status = this.publishStatusEnum.Draft;
        this.alert.success('unpublished successfully');
      });
    }
    if (status === 'Publish') {
      this.venueService.publishPackage(data.data.id).subscribe(() => {
        const index = this.pricePackages.findIndex(findId => findId.id === data.data.id);
        this.pricePackages[index].status = this.publishStatusEnum.Published;
        this.alert.success('published successfully');
      });
    }
  }

  saveAllTiers() {
    if (this.newTierIndex.length > 0) {
      this.newTierIndex.map((id, i) => {
        const index = this.pricePackages.findIndex(res => res.id === id);
        const tiers = this.pricePackages[index].tiers;
        tiers.map((tier) => {
          if (tier.uid) {
            tier.id = 0;
            delete tier.uid;
          }
          return tier;
        });
        this.venueService.addTiers(this.id, tiers).subscribe((res) => {
          this.pricePackages[index].tiers = res;
          this.addingOrDeletingTier = false;
          this.pricePackages[index].saveBtn = false;
          if (i === this.newTierIndex.length - 1) {
            this.alert.success('all tiers saved successfully');
            this.newTierIndex = [];
          }
        }, (err) => {
          this.errorHelper.showServerErrors(err);
        });
      });
    }
  }

  saveNewTier(id) {
    const index = this.pricePackages.findIndex(res => res.id === id);
    const body = this.pricePackages[index].tiers;
    body.map((tier) => {
      if (tier.uid) {
        tier.id = 0;
        delete tier.uid;
      }
      return tier;
    });
    this.venueService.addTiers(this.id, body).subscribe((res) => {
      this.pricePackages[index].tiers = res;
      this.addingOrDeletingTier = false;
      this.pricePackages[index].saveBtn = false;
      this.alert.success('Tier saved successfully.');
    }, (err) => {
      this.errorHelper.showServerErrors(err);
    });
  }

  getMenuFileName(): string {
    if (this.menu_url) {
      const menuArr = this.menu_url.split('/');
      return menuArr[menuArr.length - 1];
    }

    return 'No menu yet';
  }


  dataGridTierEvents(e, eventName) {
    if (eventName === 'onEditingStart') {
      console.log(e.key);
      this.currentId = e.key.id;
      this.rowKey = e.key;
      this.menu_url = e.key.fullMenuUrl ? e.key.fullMenuUrl : '';
      this.mainPhotoUrl = e.key.mainPhotoUrl ? e.key.mainPhotoUrl : '';
      if (e.key.discountPriceStartsAt && e.key.discountPricePerPerson) {
        this.isStartsAtChecked = true;
      } else {
        this.isStartsAtChecked = false;
      }
      // e.key.type === 1 || e.key.data === 0 ? e.key.type = 'DDR Package' : e.key.type = 'Eat&Meet Package';
      // e.key.type === 1 ? e.key.type = 'DDR Package' : e.key.type = 'Eat&Meet Package';
      if (e.key.type === 1) {
        e.key.type = 'DDR Package'
      } else if (e.key.type === 2) {
        e.key.type = 'Eat&Meet Package'
      }
    }
    if (eventName === 'onRowUpdating') {
      const body: any = {};
      setTimeout(() => {
        body.id = e.key.id;
        this.currentId = e.key.id;
        body.relatedVenueId = this.venueOrServiceProvider === 'venue' ? this.id : null;
        body.relatedServiceProviderId = this.venueOrServiceProvider === 'serviceProvider' ? this.id : null;
        body.name = e.key.name;
        body.description = e.key.description === undefined ? null : e.key.description;
        body.extraServicesCost = e.key.extraServicesCost;
        body.extraServicesDescription = e.key.extraServicesDescription;
        body.minCharge = e.key.minCharge;
        e.key.type === 'Eat&Meet Package' ? body.type = 2 : body.type = 1;
        body.minParticipants = e.key.minParticipants;
        body.fullMenuUrl = this.menu_url;
        body.durationHours = e.key.durationHours;
        body.pricesIncludeVenueRent = e.key.pricesIncludeVenueRent === undefined ? false : e.key.pricesIncludeVenueRent;
        body.pricePerPerson = e.key.pricePerPerson;
        body.discountPricePerPerson = e.key.discountPricePerPerson;
        body.discountPriceStartsAt = e.key.discountPriceStartsAt;
        body.shortDescription = e.key.shortDescription;
        if (body.minCharge && body.minParticipants) {
          this.validatorFlag = false;
          this.confirmPopupClose = false;
          this.alert.error('Only one field can have a value');
        } else if ((body.extraServicesDescription && !body.extraServicesCost) ||
          (!body.extraServicesDescription && body.extraServicesCost)) {
          this.validatorFlag = false;
          this.alert.error('extra charges field must have a value');
          this.confirmPopupClose = false;
        } else {
        }
        if (this.validatorFlag) {
          this.venueService.addPricePackageForVenue(this.id, body).subscribe(() => {
            this.alert.success('Package updated successfully');
            this.menu_url = '';
            this.menuFileName = '';
            this.confirmPopupClose = true;
          }, (err) => {
            console.log('err :', err);
            this.errorHelper.showServerErrors(err);
            this.gridContainer.instance.cancelEditData();
            // this.alert.error('some error occurred while updating new package, try after some time.');
          });
        }
      }, 100);
    }

    if (eventName === 'onInitNewRow') {
      e.data.pricesIncludeVenueRent = true;
      this.currentId = null;
      this.menu_url = '';
      this.menuFileName = '';
    }

    if (eventName === 'onRowInserting') {
      if (e.data.name && e.data.durationHours) {
        e.cancel = true;
        const body: any = {};
        body.id = null;
        body.relatedVenueId = this.venueOrServiceProvider === 'venue' ? this.id : null;
        body.relatedServiceProviderId = this.venueOrServiceProvider === 'serviceProvider' ? this.id : null;
        body.name = e.data.name;
        e.data.type === 'Eat&Meet Package' ? body.type = 2 : body.type = 1;
        body.extraServicesCost = e.data.extraServicesCost;
        body.extraServicesDescription = e.data.extraServicesDescription;
        body.minCharge = e.data.minCharge;
        body.description = e.data.description === undefined ? null : e.data.description;
        body.minParticipants = e.data.minParticipants === undefined ? null : e.data.minParticipants;
        body.durationHours = e.data.durationHours;
        body.pricesIncludeVenueRent = e.data.pricesIncludeVenueRent === undefined ? false : e.data.pricesIncludeVenueRent;
        body.pricePerPerson = e.data.pricePerPerson;
        body.fullMenuUrl = this.menu_url;
        body.discountPricePerPerson = e.data.discountPricePerPerson;
        body.discountPriceStartsAt = e.data.discountPriceStartsAt;
        body.shortDescription = e.data.shortDescription;
        this.venueService.addPricePackageForVenue(this.id, body).subscribe((res) => {
          this.gridContainer.instance.cancelEditData();
          this.pricePackages.push(res);
          this.gridContainer.instance.refresh();
          this.gridContainer.instance.repaint();
          this.menu_url = '';
          this.menuFileName = '';
          this.alert.success('Package added successfully');
        }, () => {
          e.cancel = true;
          this.alert.error('some error occurred while adding new package, try after some time.');
        });
      } else {
        this.alert.error('please input all required fields');
      }
    }
    if (eventName === 'onEditorPreparing') {
      if (e.parentType === 'dataRow' && e.dataField === 'description') {
        e.editorName = 'dxHtmlEditor';
        e.editorOptions.height = 200;
        // if (this.details.locale === this.languageEnum.HE_IL) {
        //   e.editorOptions.rtlEnabled = true;
        // } else {
        //   e.editorOptions.rtlEnabled = false;
        // }
      }
      if (e.parentType === 'dataRow' && e.dataField === 'type') {
        e.editorOptions.searchEnabled = false;
      }
    }
  }

  minPriceOrGuests(e): string {
    if (e.minCharge)
      return Number(e.minCharge).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    else if (e.minParticipants)
      return e.minParticipants + ' Guests';
  }

  menufileEvent(e) {
    const fileSelected: File = e.target.files[0];
    this.venueService.uploadMeunFile(fileSelected, this.currentId)
      .subscribe((response) => {
        console.log('uploaded');
        this.menu_url = response['fullMenuUrl'];
        const menuArr = this.menu_url.split('/');
        this.menuFileName = menuArr[menuArr.length - 1];
        this.rowKey.fullMenuUrl = this.menu_url;
        this.alert.success('Menu upload success');
        e.target.value = null;
      },
        () => {
          console.log('upload error');
          this.alert.error('Menu upload error');
        });
  }

  onMainPhotoChange(e) {
    const fileSelected: File = e.target.files[0];
    this.venueService.uploadPricePackagePhoto(fileSelected, this.currentId)
      .subscribe((response) => {
        console.log(response);
        this.mainPhotoUrl = response['fullMenuUrl'];
        // const menuArr = this.menu_url.split('/');
        // this.menuFileName = menuArr[menuArr.length - 1];
        // this.rowKey.fullMenuUrl = this.menu_url;
        this.alert.success('Menu upload success');
        e.target.value = null;
      },
        () => {
          console.log('upload error');
          this.alert.error('Menu upload error');
        });
  }


  downloadFile() {
    if (this.menu_url !== '') {
      const fileType = this.menu_url.split(/\.(?=[^\.]+$)/);
      this.venueService.downloadFile(this.menu_url, 'package_menu_' + this.currentId + '.' + fileType[1]);
    }
  }

  removeFile() {
    const answer = window.confirm('package menu file will be deleted , Continue?');
    if (answer) {
      this.venueService.removeFile(this.currentId).subscribe(() => {
        console.log('file deleted ');
        this.menu_url = '';
        this.menuFileName = '';
        this.alert.warning('Menu removed');
      }
      );
    }
  }

  removeMainPhoto() {
    const answer = window.confirm('package main photo file will be deleted , Continue?');
    if (answer) {
      this.venueService.removePricePackagePhoto(this.currentId).subscribe(() => {
        console.log('file deleted ');
        this.mainPhotoUrl = '';
        this.alert.warning('Main Photo removed');
      }
      );
    }
  }

  onStartsAtValueChanged(e) {
    if (!e.value) {
      this.rowKey.discountPriceStartsAt = null;
      this.rowKey.discountPricePerPerson = null;
    }
  }

  minChargeGuestsValidationCallback(e): boolean {
    if (e.data.minCharge && e.data.minParticipants) {
      e.rule.isValid = false;
      e.rule.message = 'invalid input, set Min Charge OR Min Guests - not both.';
      return false;
    } else {
      e.rule.isValid = true;
      return true;
    }
  }

  extraCostValidation(e) {
    if (e.data.extraServicesCost && e.data.extraServicesCost.length > 0
      && e.data.extraServicesDescription && e.data.extraServicesDescription.length > 0) {
      e.rule.isValid = true;
      return true;
    } else {
      e.rule.isValid = false;
      e.rule.message = 'extra services description Error ';
      return false;
    }
  }

  extraDescritptionValidation(e) {
    if (e.data.extraServicesCost && e.data.extraServicesCost.length > 0
      && e.data.extraServicesDescription && e.data.extraServicesDescription.length > 0) {
      e.rule.isValid = true;
      return true;
    } else {
      e.rule.isValid = false;
      e.rule.message = 'extra services cost Error ';
      return false;
    }
  }
}



