import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../../../_services/booking.service';
import { BookingStatusEnum, IRefreshmentBooking } from '../../../../../../Model/_models/Booking';
import { CurrencyEnum } from '../../../../../../Model/models/Booking';
import { User } from '../../../../../../Model/_models/User';
import { AlertifyService } from '../../../_services/alertify.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-refreshment-bookings-edit',
  templateUrl: './refreshment-bookings-edit.component.html',
  styleUrls: ['./refreshment-bookings-edit.component.css']
})
export class RefreshmentBookingsEditComponent implements OnInit {

  constructor(private bookingServices: BookingService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,) { }

  booking?: IRefreshmentBooking;
  bookingUser?: User;
  bookingStatus: string;
  bookingStatusEnum = BookingStatusEnum;
  isPageLoading = true;

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        const id = params['id'];
        if (id) {
          this.getBookingById(+id);
        }
      }
    )
  }

  public currencyEnumToName(currency: number): string {
    return CurrencyEnum[currency];
  }

  public onConfirmClick() {
    const result =
      confirm(`Are you sure you want to CONFIRM booking id 
        ${this.booking.id} by ${this.booking.bookingUser.firstName} ${this.booking.bookingUser.lastName ? this.booking.bookingUser.lastName : ''}?`,
        'Confirm Booking');
    result.then((dialogResult: any) => {
      if (dialogResult)
        this.confirmBooking();
    });
  }

  public onResendClick() {
    this.confirmBooking();
  }

  public onArchiveClick() {

  }




  private confirmBooking() {
    this.bookingServices.confirmRefreshmentBooking(this.booking.id, this.booking.supplierId).subscribe(
      result => {
        this.booking.status = result.status;
        this.bookingStatus = BookingStatusEnum[result.status];
        this.alertify.success('Booking has been confirmed.');
      },
      error => {
        console.error(error);
        this.alertify.error(error);
      }
    )
  }

  private getBookingById(id: number) {
    this.bookingServices.getRefreshmentBookingById(id).subscribe(
      result => {
        this.booking = result;
        this.bookingStatus = BookingStatusEnum[this.booking.status];
        this.bookingUser = this.booking.bookingUser;
        this.isPageLoading = false;
        console.log(this.booking);
      },
      error => {
        console.error(error);
        this.isPageLoading = false;
        this.alertify.error(error);
      }
    )

  }


}
