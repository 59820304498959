import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyEnum } from '../../../../Model/models/Booking';

@Pipe({
  name: 'currencyToString'
})
export class CurrencyToStringPipe implements PipeTransform {

  currencies = CurrencyEnum

  transform(value: any, ...args: any[]): any {
    return this.currencies[value].toString();
  }

}
