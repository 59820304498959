import { Router, ActivatedRoute } from '@angular/router';
import { BookingService } from './../../_services/booking.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import {  CurrencyEnum } from '../../../../../Model/_models/general.interface';
import { Booking, BookingStatusEnum, BookingTimeSlot, OrgBookingStatusEnum } from '../../../../../Model/_models/Booking';
import { DxDataGridComponent } from 'devextreme-angular';
import { Component, OnInit, ViewChild} from '@angular/core';
import { AlertifyService } from '../../_services/alertify.service';
import { confirm } from 'devextreme/ui/dialog';
import { startCase } from 'lodash';
import { EditUserComponent } from '../users/edit-user/edit-user.component';
import { UsersService } from '../../_services/users.service';
import * as moment from 'moment';

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.css'],
  providers: [UsersService]
})
export class BookingsListComponent implements OnInit {

  @ViewChild(EditUserComponent, { static: false }) edituser;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  bookings: Booking[];
  message: String;
  loading = false;
  filterNameValue: any;

  startDateForBooking: Date;
  endDateForBooking?: Date;
  bookingSummaryResult?: any;


  constructor(
    private bookingService: BookingService,
    private router: Router,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
  ) {
    this.bookingInventoryPriceFormatter = this.bookingInventoryPriceFormatter.bind(this);
    this.getBookingPriceString = this.getBookingPriceString.bind(this);
    this.getBookingUSDPriceString = this.getBookingUSDPriceString.bind(this);
    this.prepareContextMenu = this.prepareContextMenu.bind(this);
  }

  ngOnInit() {
    this.fetchBookings();
  }

  public getBookingSummary() {
    this.bookingSummaryResult = undefined;
    const endDate: Date = this.endDateForBooking ? new Date(this.endDateForBooking) : undefined;

    this.bookingService.getBookingByDate(new Date(this.startDateForBooking), endDate).subscribe(
      result => {
        console.log(result);
        this.bookingSummaryResult = result;
      },
      error => {
        console.log(error);
      }
    )
  }

  filterByBookingUserName(name: string) {
    if (this.dataGrid.dataSource) {
      this.dataGrid.instance.filter([
        ['firstName', 'contains', name]
      ]);
    }
  }

  fetchBookings() {
    this.blockUI.start('Loading...'); // Start blocking
    this.loading = true;
    this.bookingService.getBookings(0, 10000).subscribe((response) => {
      this.bookings = response.result;
      console.log(this.bookings);
    }, () => {
      alert('Error while getting data');
    }, () => {
      this.loading = false;
      this.blockUI.stop();
    });
  }

  navigateToEditBooking(bookId: number) {
    this.saveState();
    this.router.navigate([`/bookings/edit/${bookId}`]);
  }

  onGridInit() {
    this.loadState();
  }

  onContentReady(e: any) {
    this.route.queryParams.subscribe((params) => {
      if (params.name) {
          console.log('name filter: ' + params.name);
          this.filterByBookingUserName(params.name);
      }
    });
  }

  loadState() {
    const state = JSON.parse(localStorage.getItem('bookingGridState'));
    if (state)
      this.dataGrid.instance.state(state);
  }

  resetState() {
    const state = {
      'searchText': '',
      'pageIndex': 0,
      'filterValue': null
    };
    localStorage.removeItem('bookingGridState');
    this.dataGrid.instance.state(state);
  }

  saveState() {
    const state = this.dataGrid.instance.state();
    // Saves the state in the local storage
    localStorage.setItem('bookingGridState', JSON.stringify(state));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: this.resetState.bind(this)
      }
    });
  }

  formatterFunc(value: any) {

    if (value !== null)
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return 'no price';
  }

  getBookingStatusEnumName(rowData) {
    return startCase(BookingStatusEnum[rowData.status]);
  }

  getOrgBookingStatusEnumName(statusCode: number): string {
    return OrgBookingStatusEnum[statusCode];
  }

  hoursNumberToString(date: string): string {
    return moment(date).format('HH:mm');
  }

  currencyEnumToName(currency: number): string {
    return CurrencyEnum[currency];
  }

  getBookingPriceString(rowData: any): string {
    return `${this.formatterFunc(rowData.finalPrice)} ${this.currencyEnumToName(rowData.currency)}`;
  }

  getBookingUSDPriceString(rowData: any): string {
    return `${this.formatterFunc(rowData.finalPriceInUnifiedCurrency)}`;
  }

  bookingStartDateFormatter(rowData: any): string {
    return moment.utc(new Date(rowData.start)).format('ddd, Do MMM, YYYY');
  }

  bookingStartTimeFormatter(rowData: any): string {
    const timeWithoutTimezone = rowData.start.slice(0, -9);
    // return moment.utc(new Date(startTSWithoutTimezone).valueOf()).format('HH:mm');
    return timeWithoutTimezone.split('T')[1];
  }

  bookingEndTimeFormatter(rowData: any): string {
    const timeWithoutTimezone = rowData.end.slice(0, -9);
    return timeWithoutTimezone.split('T')[1];
    // return moment.utc(new Date(rowData.end).getTime()).format('HH:mm');
  }

  bookingInventoryPriceFormatter(rowData: any): string {
    return `${rowData.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${this.currencyEnumToName(rowData.currency)}`;
  }

  bookingDeliveryTimeFormatter(rowData: any): string {
    return moment(rowData.deliveryTime, 'YYYY-M-D H:mm').format('DD/MM HH:mm');
  }

  getBookingGuestNumber(rowData: Booking): string {
    let result = '';
    rowData.bookingTimeSlots.forEach(bts => {
      result += bts.guestNumber + '<br/>';
    });
    return result;
  }

  getBookingDatesString(rowData: Booking): string {
    let result = '';
    rowData.bookingTimeSlots.forEach(a => {
      result += moment(a.start).format('DD/MM/YY') + '<br/>';
    });
    return result;
  }

  navigateToEditVenue(venueId: number) {
    this.saveState();
    this.router.navigate([`/venues/edit/${venueId}`]);
  }

  getBookingUserDetails(rowData: any): string {
    return `${rowData.bookingUser.firstName} ${rowData.bookingUser.lastName ? rowData.bookingUser.lastName : ''}`;
  }

  getBookingLocation(rowData: any): string {
    return `${rowData.bookedVenue.city}, ${rowData.bookedVenue.country}`;
  }

  getBookingCreatedAt(rowData: any): Date {
    return moment.utc(rowData.createdAt).local().toDate();
  }

  getBookingAge(rowData: any): string {
    return moment.utc(rowData.createdAt).local().fromNow(true);
  }

  getBookingOrgDetails(rowData: any): string {
    if (rowData.relatedOrgBooking)
      return rowData.relatedOrgBooking.relatedOrgUser.relatedOrg.name;
    else if (rowData.companyName)
      return rowData.companyName;
    else
      return '-';
  }

  getBookingPOCode(rowData: any): string {
    if (rowData.relatedOrgBooking) {
      return rowData.relatedOrgBooking.poCode ? rowData.relatedOrgBooking.poCode : '-';
    } else {
      return '-';
    }
  }

  prepareContextMenu(e) {
    if (e.row.rowType === 'data') {
      e.items = [{
        text: 'View',
        onItemClick: function () {
          this.alertify.success('view booking clicked');
        }.bind(this)
      }];
      if (e.row.data.status === BookingStatusEnum.PendingSupplierApproval ||
        e.row.data.status === BookingStatusEnum.SupplierConfirmed) {
        let menuText = 'Confirm Booking';
        if (e.row.data.status === BookingStatusEnum.SupplierConfirmed) {
          menuText = 'Resend Confirmation Email';
        }
        e.items.push({
          text: menuText,
          onItemClick: function () {
            const result =
              confirm(`Are you sure you want to CONFIRM booking id ${e.row.data.id} by ${this.getBookingUserDetails(e.row.data)}?`,
                'Confirm Booking');
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.confirmBooking(e.row.data);
              }
            });
          }.bind(this)
        });
      }
      if (e.row.data.status === BookingStatusEnum.PendingPayment ||
          e.row.data.status === BookingStatusEnum.UserCancelled ||
          e.row.data.status === BookingStatusEnum.AdminCancelled) {
        const menuText = 'Archive Booking';
        e.items.push({
          text: menuText,
          onItemClick: function () {
            const result =
              confirm(`Are you sure you want to ARCHIVE booking id ${e.row.data.id} by ${this.getBookingUserDetails(e.row.data)}?`,
                'Archive Booking');
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.archiveBooking(e.row.data);
              }
            });
          }.bind(this)
        });
      }
    }
  }

  confirmBooking(bookingData) {
    this.bookingService.confirmBooking(bookingData.id).subscribe((response) => {
      // update booking in current booking list
      const confirmedBooking = this.bookings.find(b => b.id === bookingData.id);
      confirmedBooking.status = response.status;
      this.alertify.success('Booking has been confirmed.');
    }, () => {
      this.alertify.error('Booking confirmation has failed!');
    }, () => {
      this.loading = false;
      this.blockUI.stop();
    });
  }

  archiveBooking(bookingData) {
    this.bookingService.archiveBooking(bookingData.id).subscribe((response) => {
      // update booking in current booking list
      // const archivededBooking = this.bookings.find(b => b.id === bookingData.id);
      this.bookings.splice(this.bookings.findIndex(b => b.id === bookingData.id), 1);
      // archivededBooking.status = response.status;
      this.alertify.success('Booking has been archived.');
    }, () => {
      this.alertify.error('Booking archive has failed!');
    }, () => {
      this.loading = false;
      this.blockUI.stop();
    });
  }
}
